import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import storage from "../../services/storage";
import { Link } from "react-router-dom";
import { validaEmail } from "../../services/validaEmail";
import { toast } from "react-toastify";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function EsqueceuSenhaConfirmacao() {
  const { load, setLoad } = useLoad();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  async function fnEnviar() {
    var objEnvio = {
      email: email,
      userName: storage.get("USUARIO").cpf,
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "usuario/senha/resetar/email",
        objEnvio
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        navigate("/esqueci-minha-senha/confirmacao/sucesso");
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link onClick={() => window.history.back()}>
                <img className="imgVoltar" src={voltar} alt="" />
              </Link>
              <div className="esqueciConfirmacaoTexto1">Confirmação</div>
              <div className="esqueciConfirmacaoTexto2">
                Vamos enviar a recuperação de senha para seu e-mail, tudo bem?
              </div>
              <br />
              <div className="esqueciConfirmacaoTexto2">
                Confirme abaixo o e-mail cadastrado.
                <br />
                <b>{storage.get("USUARIO")?.email}</b>
              </div>
              <form autoComplete="off">
                <label>E-mail</label>
                <input
                  autoComplete="off"
                  className="inputPrimary"
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <div
                  className="labelLoginCpf m-0"
                  style={{ color: "#FF3E0E", fontWeight: 600 }}
                >
                  {validaEmail(email)
                    ? " "
                    : email?.length > 5
                    ? "E-mail invalido"
                    : " "}
                </div>
              </form>
            </div>
            <button
              onClick={() => {
                // navigate("/login");
                fnEnviar();
              }}
              className="btn btn-primary w-100"
              disabled={!validaEmail(email)}
              style={{ margin: "20px 0" }}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
