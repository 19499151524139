// ###########################################
// # variaveis de configuração do sistema    #
// ###########################################

var globals = {
  REACT_APP_APIBASE: process.env.REACT_APP_APIBASE,
  REACT_APP_APIENDERECO: process.env.REACT_APP_APIENDERECO,
  REACT_APP_APINOTIFICACAO: process.env.REACT_APP_APINOTIFICACAO,
  REACT_APP_RECAPTCHA: process.env.REACT_APP_RECAPTCHA,
  
};
export default globals;
