import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import "./index.css";
import { slide as MenuBurger } from "react-burger-menu";
import storage from "../../services/storage";
import Mask from "../../services/mask";
import logoBgLogin from "../../assets/images/png/logoBgLogin.png";
import iconMenu from "../../assets/images/svg/iconMenu.svg";
import Avatar from "../Avatar";
import papel from "../../assets/images/svg/papelNotificacao.svg";
import sino from "../../assets/images/svg/sinoNotificacao.svg";
import exclamacao from "../../assets/images/svg/exclamacao.svg";
import { toast } from "react-toastify";

export default function Menu() {
  const vendedor =
    storage.get("USUARIO")?.profile?.role?.roleId ==
    "BD599436-282A-4B76-B419-4D767551DBD6";

  const gerente =
    storage.get("USUARIO")?.profile?.role?.roleId ==
    "89B97424-23F8-455C-AD38-A9A2D9B1784B";

  return (
    <div style={{ position: "absolute", zIndex: 1000, top: 16, right: 16 }}>
      <MenuBurger
        right
        className="bm-burger-bars-Deslogado"
        customBurgerIcon={<img src={iconMenu} alt="" />}
      >
        <div className="d-flex flex-column h-100 justify-content-between">
          <div>
            <div className="d-flex justify-content-center mb-3">
              <img width={200} src={logoBgLogin} alt="" />
            </div>

            <Link to="/home">
              <div className="botaoMenu">
                <span>Pagina inicial</span>
              </div>
            </Link>
            <Link to="/regulamento">
              <div className="botaoMenu">
                <span>Termo de uso</span>
              </div>
            </Link>

            <Link to="/fale-conosco">
              <div className="botaoMenu">
                <span>Fale conosco</span>
              </div>
            </Link>

            {vendedor && (
              <Link to="/missoes">
                <div className="botaoMenu">
                  <span>Missões</span>
                </div>
              </Link>
            )}
            {vendedor && (
              <Link to="/quiz">
                <div className="botaoMenu">
                  <span>Quiz</span>
                </div>
              </Link>
            )}
            {vendedor && (
              <Link to="/meta-roleta">
                <div className="botaoMenu">
                  <span>Roleta da sorte</span>
                </div>
              </Link>
            )}
            {gerente && (
              <Link to="/ranking-geral">
                <div className="botaoMenu">
                  <span>Ranking</span>
                </div>
              </Link>
            )}
            {/* <Link to="/enquete">
              <div className="botaoMenu">
                <span>Enquete</span>
              </div>
            </Link> */}
            {/* <Link to="/historico-da-roleta-da-sorte">
              <div className="botaoMenu">
                <span>Roleta da sorte</span>
              </div>
            </Link>
            <Link to="/mecanica">
              <div className="botaoMenu">
                <span>Mecânica</span>
              </div>
            </Link> */}
            {/* <Link to="/historico-do-numero-da-sorte">
              <div className="botaoMenu">
                <span>Números da sorte</span>
              </div>
            </Link> */}
            <Link to="/historico-de-recompensas">
              <div className="botaoMenu">
                <span>Recompensas</span>
              </div>
            </Link>

            <Link to="/faq">
              <div className="botaoMenu">
                <span>FAQ</span>
              </div>
            </Link>

            <Link to="/meu-cadastro">
              <div className="botaoMenu">
                <span>Meu cadastro</span>
              </div>
            </Link>

            <Link to="/historico-de-vendas">
              <div className="botaoMenu">
                <span>Histórico de vendas</span>
              </div>
            </Link>

            {vendedor && (
              <Link to="/vendas">
                <div
                  className="botaoMenu"
                  style={{ borderBottom: 0, marginBottom: 20 }}
                >
                  <span>Cadastro de vendas</span>
                </div>
              </Link>
            )}
          </div>
          <div style={{ paddingBottom: 80 }}>
            <Link
              to="/login"
              onClick={() => {
                storage.del("USUARIO");
              }}
            >
              <button
                className="btn btn-secondary w-100 mb-3"
                style={{
                  padding: 0,
                  minHeight: 40,
                  fontSize: 13,
                  fontWeight: 700,
                }}
              >
                Sair
              </button>
            </Link>
          </div>
        </div>
      </MenuBurger>
    </div>
  );
}
