import React, { useEffect, useState } from "react";
import Avatar from "../../Components/Avatar";
import estrelaCheia from "../../assets/images/png/estrelaCheia.png";
import estrelaVazia from "../../assets/images/png/estrelaVazia.png";
import roleta from "../../assets/images/png/roleta.png";
import numeroSorte from "../../assets/images/png/numeroSorte.png";
import presenteHome from "../../assets/images/png/presenteHome.png";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import Menu from "../Menu";
import AvatarMenor from "../AvatarMenor";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import storage from "../../services/storage";
export default function HeaderAvatarMenu({
  titulo,
  imagem,
  subTitulo,
  mb = 100,
  acessoAvatar = false,
}) {
  const [eu, setEu] = useState([]);
  const [missao, setMissao] = useState([]);
  const location = useLocation().pathname;
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const vendedor =
    storage.get("USUARIO")?.profile?.role?.roleId ==
    "BD599436-282A-4B76-B419-4D767551DBD6";

  const gerente =
    storage.get("USUARIO")?.profile?.role?.roleId ==
    "89B97424-23F8-455C-AD38-A9A2D9B1784B";

  async function fnUsuario() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("eu");

      response.data.estrela = 0;

      setEu(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnMissao() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("missao/meu-resumo");
      setMissao(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnGrupo() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("grupo/meu-resumo");
      setMissao(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnUsuario();
    vendedor && fnMissao();
    gerente && fnGrupo();
  }, []);

  return (
    <>
      <Menu />
      <div className="pt-3">
        <div
          className="bgAzulAvatarMenu d-flex"
          style={{ width: 160, height: 56, paddingLeft: 50 }}
        >
          <div
            onClick={() => {
              if (location == "/meu-cadastro") {
                if (acessoAvatar === true) {
                  navigate("/meu-cadastro/avatar");
                } else {
                  toast.warn("Para alterar o avatar complete seu cadastro");
                }
              } else {
                navigate("/meu-cadastro");
              }
            }}
          >
            <div className="divAvatarMenuNome " style={{ scale: 0.5 }}>
              <AvatarMenor nome={eu.nome} rota={"/meu-cadastro"} font={15} />
            </div>
            <div style={{ width: "100%", marginTop: 3 }}>
              <div style={{ marginBottom: 5 }}>
                {missao.qtdEstrelas >= 1 ? (
                  <img width={15} src={estrelaCheia} alt="" />
                ) : (
                  <img width={15} src={estrelaVazia} alt="" />
                )}
                {missao.qtdEstrelas >= 2 ? (
                  <img width={15} src={estrelaCheia} alt="" />
                ) : (
                  <img width={15} src={estrelaVazia} alt="" />
                )}
                {missao.qtdEstrelas >= 3 ? (
                  <img width={15} src={estrelaCheia} alt="" />
                ) : (
                  <img width={15} src={estrelaVazia} alt="" />
                )}
              </div>
              <div className="nomeCardPerfil tresPontinhos">{eu.nome}</div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ minHeight: mb }}
        >
          <div className="tituloAvatarMenu tresPontinhos">{titulo}</div>
          {imagem && (
            <img className="imgAvatarMenu" width={45} src={imagem} alt="" />
          )}
        </div>

        <div className="subTituloAvatarMenu tresPontinhos">{subTitulo}</div>
      </div>
    </>
  );
}
