import React from "react";
import "./index.css";
import seta from "../../assets/images/png/setaMetaRoleta.png";
export default function BarraProgressoRoleta({ progresso = 0 }) {
  //calculo em 9 + 19 + 20 = 48
  //etapa 9 = 34.5

  if (progresso >= 2 && progresso <= 4) {
    var etapa9 = 8.65;
    var etapa9Fracao = etapa9 * progresso;
  }

  if (progresso >= 4 && progresso <= 9) {
    var etapa19 = 5.2;
    var etapa19Fracao = 34.5 + etapa19 * (progresso - 3);
  }

  if (progresso >= 9) {
    var etapa20 = 4.4;
    var etapa20Fracao = 65.5 + etapa20 * (progresso - 9);
  }

  var etapaResultante = etapa9Fracao || etapa19Fracao || etapa20Fracao;

  return (
    <div className="barExterno">
      {etapaResultante > 0 && (
        <div
          className="barInterno "
          style={{
            width: `${Math.max(etapaResultante, 1)}%`,
          }}
        >
          <img className="imgSetaRoleta" src={seta} alt="" />
        </div>
      )}

      <div className="cortes">
        <div className="corte1"></div>
        <div className="corte2"></div>
      </div>
    </div>
  );
}
