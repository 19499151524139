import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanha from "../../assets/images/png/campanha.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import moment from "moment";
import smileUser from "../../assets/images/svg/smileUser.svg";
import smileFeliz from "../../assets/images/svg/smileFeliz.svg";
import CloseIcon from "@mui/icons-material/Close";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from "react-toastify";
import Avatar from "../../Components/Avatar";
import HeaderHomeVendedor from "../Home/HeaderHomeVendedor";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import storage from "../../services/storage";
export default function Vendas() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [chassi, setChassi] = useState("");
  const [mostrarResultado, setMostrarResultado] = useState(false);

  const [listaPontuacao, setListaPontuacao] = useState([]);

  async function fnResultadoChassi() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("vendas/" + chassi);

      if (response.statusCode === 400) {
        toast.error(response.message);
      } else {
        setListaPontuacao(response.data);
        setMostrarResultado(true);
        response.data === null && setChassi("");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnResultadoChassiPost() {
    var objEnvio = {
      chassi: chassi,
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPost("vendas", objEnvio);
      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success(response.data);
        fnResultadoChassi(true);
        navigate("/vendas/finallizada");
        setMostrarResultado(false);
      } else {
        toast.error(data.message);
        setChassi("");
        setMostrarResultado();
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          <div className=" d-flex flex-column justify-content-between h-100 ">
            <HeaderAvatarMenu titulo={"Cadastrar venda"} />
            <div className="h-100 ">
              <label>Informe o chassi que deseja cadastrar</label>

              <form autoComplete="off" style={{ position: "relative" }}>
                <input
                  autoComplete="off"
                  disabled={listaPontuacao?.chassi && mostrarResultado}
                  className="inputPrimary"
                  type="text"
                  maxLength={17}
                  value={chassi}
                  onChange={(e) => {
                    setChassi(e.target.value);
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 12,
                    right: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(0);
                  }}
                >
                  <CloseIcon />
                </div>
              </form>

              {mostrarResultado &&
                (listaPontuacao?.chassi ? (
                  <div>
                    <label className="labelChassi">Resultado</label>
                    <div className=" divResultadoVendas">
                      <div className=" d-flex flex-column me-4 ">
                        <div className="my-1">Chassi</div>
                        <div className="my-1">Data Varejo</div>
                        <div className="my-1">Cliente</div>
                        <div className="my-1">Produto</div>
                        <div className="my-1">CCY</div>
                      </div>
                      <div className=" d-flex flex-column ">
                        <div className="my-1">
                          {listaPontuacao?.chassi || "-"}
                        </div>
                        <div className="my-1">
                          {listaPontuacao?.dataVenda
                            ? moment(listaPontuacao?.dataVenda).format(
                                "DD/MM/YYYY - HH:mm:ss "
                              )
                            : "-"}
                        </div>
                        <div className="my-1">
                          {listaPontuacao?.cliente
                            ? listaPontuacao?.cliente
                            : "-"}
                        </div>
                        <div className="my-1">
                          {listaPontuacao?.produto
                            ? listaPontuacao?.produto
                            : "-"}
                        </div>
                        <div className="my-1">
                          {listaPontuacao?.ccy ? listaPontuacao?.ccy : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center"
                    style={{ margin: " 100px 0" }}
                  >
                    <h3 style={{ maxWidth: 200, textAlign: "center" }}>
                      Chassi não encontrado
                    </h3>
                  </div>
                ))}
            </div>
            <div className="mt-3" style={{ paddingBottom: 80 }}>
              <button
                className={
                  listaPontuacao?.chassi && mostrarResultado
                    ? "confirmarVenda"
                    : "btn btn-primary w-100"
                }
                disabled={!chassi}
                onClick={() => {
                  listaPontuacao?.chassi && mostrarResultado
                    ? fnResultadoChassiPost()
                    : fnResultadoChassi();
                }}
              >
                {listaPontuacao?.chassi && mostrarResultado
                  ? "Confirmar cadastro"
                  : "Pesquisar"}
              </button>
              <Link to="/historico-de-vendas">
                <button className="btn btn-secondary w-100 mt-3 ">
                  Histórico
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
