import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate, useParams } from "react-router";
import ApiServices from "../../services/apiServices";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import triste from "../../assets/images/svg/triste.svg";
import presenteHome from "../../assets/images/png/presenteHome.png";
import { toast } from "react-toastify";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import storage from "../../services/storage";
export default function SuaEscolha() {
  const { load, setLoad } = useLoad();
  const [nomePremio, setNomePremio] = useState("");
  const [maisOpcoes, setMaisOpcoes] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const [trava, setTrava] = useState(false);
  const location = useLocation();
  const recompensa = location.state;
  const idHistorico = location.state.idHistorico;

  // function fnNomePremio() {
  //   if (recompensa === 0) {
  //     setNomePremio("Não foi dessa vez");
  //   }
  //   if (recompensa === 1000) {
  //     setNomePremio("Cinemark: 30% de desconto");
  //   }
  //   if (recompensa === 2000) {
  //     setNomePremio("Riachuelo: R$ 12,00 para usar no site");
  //   }
  //   if (recompensa === 3000) {
  //     setNomePremio(
  //       "CONECTCAR: plano por R$6,50/mês + R$30,00 reais de crédito"
  //     );
  //   }
  //   if (recompensa === 4000) {
  //     setNomePremio("POPEYES = CUPOM R$10 OFF em pedidos acima de R$30,00");
  //   }
  //   if (recompensa === 5000) {
  //     setNomePremio(
  //       "RAPPI: CUPOM PARA 99% OFF no Plano Rappi Prime Basic + 10 reais para usar na loja Turbo"
  //     );
  //   }
  //   if (recompensa === 10000) {
  //     setNomePremio("Cinemark: 30% de desconto");
  //   }
  //   if (recompensa === 11000) {
  //     setNomePremio("CONECTCAR: plano por R$6,50/mês + 30 reais de crédito");
  //   }
  //   if (recompensa === 12000) {
  //     setNomePremio(
  //       "RAPPI: CUPOM PARA 99% OFF no Plano Rappi Prime Basic + 10 reais para usar na loja Turbo"
  //     );
  //   }
  // }

  async function fnBtConfirmar() {
    setTrava(true);
    try {
      setLoad(true);
      const response = await ApiServices.apiGet(
        "roleta/buscar-premio-id?codigo=" + recompensa.recompensa
      );
      console.log(response.data);
      fnBtTrocarPremio(response.data?.roletaJogadaId);
      setTrava(true);
    } catch (err) {
      console.error("err", err);
    } finally {
      setTrava(false);
      setLoad(false);
    }
  }
  async function fnBtTrocarPremio(roletaJogadaId) {
    setTrava(true);
    var objEnvio = {
      codigoTroca: recompensa.codigo,
      roletaJogadaId: idHistorico || roletaJogadaId,
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        "roleta/trocar-premio",
        objEnvio
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        navigate("/recompensa/sua-escolha/resgate-confirmado", {
          state: data.data,
        });
        setTrava(false);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setTrava(false);
      setLoad(false);
    }
  }

  useEffect(() => {
    // fnNomePremio();
  }, []);
  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className=" d-flex flex-column justify-content-between h-100 ">
            <HeaderAvatarMenu mb={25} />
            <div className=" d-flex flex-column align-items-center justify-content-between h-100 ">
              <div className="d-flex flex-column align-items-center w-100">
                <h4>
                  <b
                    className="mb-4"
                    style={{ maxWidth: 300, textAlign: "center" }}
                  >
                    SUA ESCOLHA
                  </b>
                </h4>

                <img
                  className="my-5"
                  width={"140px"}
                  src={recompensa.urlPremio}
                  alt=""
                />

                <b className="mb-4" style={{ maxWidth: 400, minWidth: 400 }}>
                  {recompensa.nome}
                </b>
                <div className="mb-4" style={{ maxWidth: 400, minWidth: 400 }}>
                  {recompensa.descricao}
                </div>
                <div className="mb-4" style={{ maxWidth: 400, minWidth: 400 }}>
                  <b>Confira as Regras</b>
                  <br />
                  <br />
                  {recompensa.regras.split("•").map((e, i) => (
                    <div key={i}>{e}</div>
                  ))}
                </div>
                <div className="mb-4" style={{ maxWidth: 400, minWidth: 400 }}>
                  <b>Veja como Aproveitar</b>
                  <br />
                  <br />

                  <div>{recompensa.comoAproveitar}</div>
                </div>
              </div>
              <div className="w-100 mt-3" style={{ paddingBottom: 80 }}>
                <button
                  className="btn btn-success w-100"
                  disabled={trava}
                  onClick={() => {
                    idHistorico ? fnBtTrocarPremio() : fnBtConfirmar();
                    setTrava(true);
                  }}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
