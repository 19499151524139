import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import ok from "../../assets/images/png/ok.png";
import errado from "../../assets/images/png/errado.png";
import atual from "../../assets/images/png/atual.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from "react-toastify";
import Avatar from "../../Components/Avatar";
import HeaderHomeVendedor from "../Home/HeaderHomeVendedor";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
export default function HistoricoDoQuiz() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [quiz, setQuiz] = useState([
    {
      quizId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      pergunta: "Qual é a capital do Brasil?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "ok",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afa6",
          texto: "Rio de Janeiro",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afa7",
          texto: "São Paulo",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afa8",
          texto: "Brasília",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afa9",
          texto: "Belo Horizonte",
        },
      ],
    },
    {
      quizId: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
      pergunta: "Quem escreveu 'Dom Quixote'?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "errado",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb0",
          texto: "Miguel de Cervantes",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb1",
          texto: "William Shakespeare",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb2",
          texto: "Friedrich Nietzsche",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb3",
          texto: "Leo Tolstoy",
        },
      ],
    },
    {
      quizId: "3fa85f64-5717-4562-b3fc-2c963f66afa8",
      pergunta: "Qual é o maior planeta do sistema solar?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "ok",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb4",
          texto: "Terra",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb5",
          texto: "Vênus",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb6",
          texto: "Júpiter",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb7",
          texto: "Saturno",
        },
      ],
    },
    {
      quizId: "3fa85f64-5717-4562-b3fc-2c963f66afa9",
      pergunta: "Quem pintou a Mona Lisa?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "atual",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb8",
          texto: "Pablo Picasso",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb9",
          texto: "Leonardo da Vinci",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afba",
          texto: "Vincent van Gogh",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afbb",
          texto: "Michelangelo",
        },
      ],
    },
    {
      quizId: "3fa85f64-5717-4562-b3fc-2c963f66afaa",
      pergunta:
        "Quem foi o primeiro homem a pisar na lua?d d dd dd dd dd d ddd ddddd dddd ddd dd ddd ddddd dddd dd ddddddd d",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "bloqueado",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afbc",
          texto: "Buzz Aldrin",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afbd",
          texto: "Neil Armstrong",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afbe",
          texto: "Yuri Gagarin",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afbf",
          texto: "Alan Shepard",
        },
      ],
    },
  ]);

  return (
    <div className="imgBackground">
      <div
        className="imgBackgroundInterno px-3 h-100"
        style={{ overflow: "auto" }}
      >
        <div className=" d-flex flex-column h-100 ">
          <HeaderAvatarMenu
            titulo={"Histórico do quiz"}
            // subTitulo={"Testando seu conhecimento"}
          />

          <div style={{ paddingBottom: 80 }}>
            {quiz.map((e, i) => (
              <div
                key={i}
                className={e.status !== "bloqueado" ? "pointer" : ""}
                onClick={() => {
                  // e.status !== "bloqueado" && setQuizSelecionado([e]);
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className="dataHistoricoRecompensas">
                    Pergunta {i + 1}
                  </div>

                  {e.status == "ok" && (
                    <div>
                      <img width={20} src={ok} alt="" />
                    </div>
                  )}
                  {e.status == "errado" && (
                    <div>
                      <img width={20} src={errado} alt="" />
                    </div>
                  )}
                  {e.status == "atual" && (
                    <div>
                      <img width={20} src={atual} alt="" />
                    </div>
                  )}
                </div>
                <div
                  className="nomeHistoricoRecompensas me-4"
                  style={{
                    filter: e.status == "bloqueado" ? "blur(5px)" : "blur(0px)",
                  }}
                >
                  {e.status == "bloqueado" &&
                    "****** ******* *** ********** ******** ********** ***** ************** **** ********* **** ******* *** *******"}
                  {e.status !== "bloqueado" && e.pergunta}
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
