import React, { useEffect, useState } from "react";
import Avatar from "../../Components/Avatar";
import estrelaCheia from "../../assets/images/png/estrelaCheia.png";
import estrelaVazia from "../../assets/images/png/estrelaVazia.png";
import roleta from "../../assets/images/png/roleta.png";
import numeroSorte from "../../assets/images/png/numeroSorte.png";
import presenteHome from "../../assets/images/png/presenteHome.png";

import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import { Link } from "react-router-dom";
export default function HeaderHomeGerente() {
  const [missoes, setMissoes] = useState({});
  const [grupo, setGrupo] = useState({});
  const [eu, setEu] = useState([]);
  const { load, setLoad } = useLoad();

  async function fnUsuario() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("eu");

      response.data.estrela = 0;
      setEu(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnGrupo() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("grupo/meu-resumo");
      setGrupo(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnMissao() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("missao/meu-resumo");
      setMissoes(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnGrupo();
    fnMissao();
    fnUsuario();
  }, []);

  return (
    <div
      className="pt-3 "
      style={{ position: "absolute", zIndex: 100, top: 0 }}
    >
      <Link to={"/meu-cadastro"}>
        {/* NOME */}
        <div
          className="bgAzul d-flex"
          style={{ width: 160, height: 70, paddingLeft: 30 }}
        >
          <div className="divAvatarNome ">
            <Avatar nome={eu.nome} font={25} />
          </div>
          <div style={{ width: "90%" }}>
            {grupo.qtdEstrelas >= 1 ? (
              <img width={15} src={estrelaCheia} alt="" />
            ) : (
              <img width={15} src={estrelaVazia} alt="" />
            )}
            {grupo.qtdEstrelas >= 2 ? (
              <img width={15} src={estrelaCheia} alt="" />
            ) : (
              <img width={15} src={estrelaVazia} alt="" />
            )}
            {grupo.qtdEstrelas >= 3 ? (
              <img width={15} src={estrelaCheia} alt="" />
            ) : (
              <img width={15} src={estrelaVazia} alt="" />
            )}
            <div className="nomeCardPerfil tresPontinhos">{eu.nome}</div>
            <div className="filialCardPerfil tresPontinhos">Concessionária</div>
            {eu?.dealer?.nomeFantasia && (
              <div className="filialCardPerfil tresPontinhos ">
                Filial {eu.dealer.nomeFantasia}
              </div>
            )}
          </div>
        </div>
      </Link>
      {/* ROLETA */}

      {/* <Link
        className="bgAzul d-flex align-items-center"
        to="/historico-da-roleta-da-sorte"
        style={{
          width: 125,
          height: 20,
          marginTop: 20,
          padding: "20px 0px 16px 30px",
        }}
      >
        <div className="divHomeRoleta">
          <img width={40} src={roleta} alt="" />
        </div>
        <div style={{ width: "90%" }}>
          <div className="filialCardPerfil mb-1">Roleta da sorte</div>
          <div className="nomeCardPerfil tresPontinhos">0/3</div>
        </div>
      </Link> */}

      {/* NUMERO DA SORTE */}

      <Link
        to="/historico-do-numero-da-sorte"
        className="bgAzul d-flex align-items-center"
        style={{
          width: 125,
          height: 20,
          marginTop: 20,
          padding: "20px 0px 16px 30px",
        }}
      >
        <div className="divHomeNumeroSorte">
          <img width={50} src={numeroSorte} alt="" />
        </div>
        <div style={{ width: "90%" }}>
          <div className="filialCardPerfil mb-1">Número da sorte</div>
          <div className="nomeCardPerfil tresPontinhos">
            {grupo?.qtdNumeroSorteDisponivel}
          </div>
        </div>
      </Link>
      {/* RECOMPENSAS */}

      {/* <Link
        to="/historico-de-recompensas"
        className="bgAzul d-flex align-items-center"
        style={{
          width: 125,
          height: 20,
          marginTop: 20,
          padding: "20px 0px 16px 30px",
        }}
      >
        <div className="divHomeRecompensas">
          <img width={45} src={presenteHome} alt="" />
        </div>
        <div style={{ width: "90%" }}>
          <div className="filialCardPerfil mb-1">Recompensas</div>
          <div className="nomeCardPerfil tresPontinhos">0</div>
        </div>
      </Link> */}
    </div>
  );
}
