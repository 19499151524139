import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import smileUser from "../../assets/images/svg/smileUser.svg";
import smileFeliz from "../../assets/images/svg/smileFeliz.svg";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
export default function RankingGeral() {
  const { load, setLoad } = useLoad();
  const [verMais, setVerMais] = useState(10);
  const [verModal, setVerModal] = useState({});
  const [listaRanking, setListaRanking] = useState([]);
  const [botaoGrupos, setBotaoGrupos] = useState([]);
  const [botaoSelecionado, setBotaoSelecionado] = useState(0);
  const [participanteGrupoId, setParticipanteGrupoId] = useState("");
  const [mesSelecionado, setMesSelecionado] = useState([]);
  const [listarMeses, setListarMeses] = useState([]);
  // AJUSTAR
  // AJUSTAR
  // AJUSTAR
  // AJUSTAR
  async function fnListaRanking() {
    const parametros = {
      page: 1,
      pageSize: verMais,
      mesId: mesSelecionado,
      ano: 2024,
    };
    try {
      const queryString = new URLSearchParams(parametros).toString();
      const response = await ApiServices.apiGet("ranking/meu?" + queryString);

      setListaRanking(response.data.items);
    } catch (err) {
      console.error("err", err);
      setListaRanking([]);
    } finally {
      setLoad(false);
    }
  }

  async function fnListarMeses() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("ranking/mes?ano=2024");
      setListarMeses(response.data);
      setVerMais(10);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnRankingResumo() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("ranking/meu-resumo");

      setBotaoGrupos(response.data);
      if (participanteGrupoId == "") {
        setParticipanteGrupoId(response?.data[0]?.participanteGrupoId);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnPerformanceUltimaPosicaoVista(id) {
    var objEnvio = { participanteGrupoId: id };

    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        "ranking/meu/marcar-como-visualizado",
        objEnvio
      );
      fnRankingResumo();
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarMeses();
  }, []);

  useEffect(() => {
    fnListaRanking();
  }, [mesSelecionado, verMais]);

  // useEffect(() => {
  //   fnRankingResumo();
  //   fnPerformance();
  // }, []);

  // useEffect(() => {
  //   if (participanteGrupoId) {
  //     fnListaRankingPorGrupo();
  //   }
  // }, [participanteGrupoId, verMais]);
  // useEffect(() => {
  //   botaoGrupos.map((e, i) => {
  //     if (
  //       e.participanteGrupoId === participanteGrupoId &&
  //       e.posicao !== e.posicaoUltimaVisita
  //     ) {
  //       setVerModal(e);
  //     }
  //   });
  // }, [participanteGrupoId]);

  return (
    <div className="imgBackground">
      <div className="imgBackgroundInterno px-3 " style={{ overflow: "auto" }}>
        <div className=" d-flex flex-column justify-content-between h-100 ">
          <HeaderAvatarMenu titulo={"Ranking geral"} />
          {/* BOTAO GRUPO A | GRUPO B */}
          <div className=" d-flex flex-column justify-content-between h-100 ">
            <div style={{ marginTop: -40, paddingBottom: 80 }}>
              <div className="mb-3">
                <label>Mês</label>
                <select
                  className="w-100"
                  value={mesSelecionado || ""}
                  onChange={(e) => {
                    setMesSelecionado(e.target.value);
                  }}
                  style={{ width: 150 }}
                >
                  <option value={""}>Selecione</option>
                  {listarMeses?.map((mes, i) => (
                    <option key={i} value={mes.mesId}>
                      {mes.descricao}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className="d-flex flex-column justify-content-start w-100"
                  style={{ maxWidth: 500 }}
                >
                  <div
                    className="d-flex justify-content-end"
                    style={{ borderBottom: "solid" }}
                  >
                    {/* GRUPO */}
                    <div className="headerRanking me-5">Grupo</div>
                    {/* CADASTRO */}
                    <div className="headerRanking">Cadastro</div>
                    {/* VAREJO */}
                    <div className="headerRanking">Varejo</div>
                    {/* NPS */}
                    <div className="headerRanking">NPS</div>
                    {/* LEADS */}
                    <div className="headerRanking">Leads</div>
                    {/* PERCENTUAL */}
                    <div className="headerRanking">%</div>
                  </div>
                  {(listaRanking || []).map(
                    (e, i) =>
                      i < verMais && (
                        <div
                          key={i}
                          className={
                            "linhaRanking " +
                            (e.souEu ? "linhaRankingSelecionado" : "")
                          }
                        >
                          {/* POSICAO  */}
                          <div className="posicaoRankingCompleto">
                            {e.posicao}°
                          </div>
                          {/* IMAGEM  */}
                          <div>
                            {e.participanteUrlAvatar ? (
                              <img
                                style={{ borderRadius: "50%" }}
                                width={30}
                                src={e.participanteUrlAvatar}
                                alt=""
                              />
                            ) : e.souEu ? (
                              <img
                                className="imagemAvatarRankingSelecionado"
                                width={30}
                                src={smileFeliz}
                                alt=""
                              />
                            ) : (
                              <img
                                className="imagemAvatarRanking"
                                width={30}
                                src={smileUser}
                                alt=""
                              />
                            )}{" "}
                          </div>
                          {/* GRUPO */}
                          <div className="nomeRankingCompleto">
                            {e.grupoDescricao}{" "}
                          </div>
                          {/* CADASTRO */}
                          <div className="porcentagemRankingCompleto">
                            {e.porcentagemCadastro}
                          </div>
                          {/* VAREJO */}
                          <div className="porcentagemRankingCompleto">
                            {e.porcentagemVarejo}
                          </div>
                          {/* NPS */}
                          <div className="porcentagemRankingCompleto">
                            {e.porcentagemNps}
                          </div>
                          {/* LEADS */}
                          <div className="porcentagemRankingCompleto">
                            {e.porcentagemLeads}
                          </div>
                          {/* PERCENTUAL */}
                          <div className="porcentagemRankingCompleto">
                            {e.porcentagemMedia}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
              {(listaRanking || []).length >= verMais && (
                <div className="d-flex justify-content-center mt-4">
                  <button
                    style={{ minWidth: 200 }}
                    className="btn btn-secondary w-100"
                    onClick={() => {
                      setVerMais(verMais + 10);
                    }}
                  >
                    Ver mais
                  </button>
                </div>
              )}
              {listaRanking.length === 0 && (
                <div className="text-center mt-5">
                  Sem dados para ser mostrados
                </div>
              )}
            </div>
          </div>
          {verModal.participanteGrupoId && (
            <div className="modalExterno">
              <div className="modalInterno">
                <div className="tituloModal">Parabéns!</div>
                <div className="textoModal">
                  Você 
                  {verModal.posicao - verModal.posicaoUltimaVisita > 0
                    ? "subiu " +
                      (verModal.posicao - verModal.posicaoUltimaVisita)
                    : "desceu " +
                      (verModal.posicao - verModal.posicaoUltimaVisita)}
                   posições desde sua última visita!
                  <br />
                  <br />
                  Você está a <b>{verModal.pontosFaltamPraProximaPosicao} </b>
                  pontos da próxima posição. Boas vendas!
                  <br />
                  <br />
                  Boas vendas!
                  <br />
                  <br />
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      fnPerformanceUltimaPosicaoVista(
                        verModal.participanteGrupoId
                      );
                      setVerModal({});
                    }}
                  >
                    Ver ranking
                  </button>
                  <br />
                </div>
              </div>
            </div>
          )}{" "}
        </div>
      </div>
    </div>
  );
}
