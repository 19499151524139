import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import vibe from "../../assets/images/png/vibe.png";
import ios from "../../assets/images/png/ios.png";
import android from "../../assets/images/png/android.png";
import { isAndroid, isIOS } from "react-device-detect";
import { Link } from "react-router-dom";
export default function ParabensSaibaMais() {
  const { load, setLoad } = useLoad();

  const navigate = useNavigate();

  async function fnCarregarBannerRotativo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner?ativo=true");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          {" "}
          <div className=" d-flex flex-column justify-content-between h-100 ">
            <HeaderAvatarMenu mb={25} />
            <div className=" d-flex flex-column align-items-center justify-content-between h-100 ">
              <div className="d-flex flex-column align-items-center">
                <h3>Baixe agora mesmo!</h3>

                <img className="mb-3 mt-5" width={"100px"} src={vibe} alt="" />

                <b>Lorem ipsum dolor sit amet</b>

                <div style={{ maxWidth: 400, textAlign: "center" }}>
                  Pellentesque laoreet, ex vel tempus venenatis, ipsum metus
                  molestie nulla, ac lacinia tellus ligula id erat. Interdum et
                  malesuada fames ac ante ipsum primis in faucibus. Ut ante
                  metus, varius sed semper ut, tempus et tellus. Etiam aliquam
                  nulla lectus, at mollis leo tristique et.
                  <br />
                  <br />
                  Pellentesque laoreet, ex vel tempus venenatis, ipsum metus
                  molestie nulla, ac lacinia tellus ligula id erat.
                </div>
              </div>

              <div className="w-100 mt-3" style={{ paddingBottom: 80 }}>
                <div className="d-flex justify-content-around mb-4">
                  <Link
                    to="https://www.apple.com/"
                    target="_blank"
                    style={{ width: "40%" }}
                  >
                    <img onClick={() => {}} width={"100%"} src={ios} alt="" />
                  </Link>
                  <Link
                    to="https://play.google.com/"
                    target="_blank"
                    style={{ width: "40%" }}
                  >
                    <img
                      onClick={() => {}}
                      width={"100%"}
                      src={android}
                      alt=""
                    />
                  </Link>
                </div>
                <Link
                  to={
                    !(
                      (isAndroid && "https://play.google.com/") ||
                      (isIOS && "https://www.apple.com/")
                    ) && "http://www.SITE-DA-VIBE.com.br"
                  }
                  target="_blank"
                >
                  <button className="btn btn-success w-100">
                    Baixar App Vibe {isAndroid && " para Android"}
                    {isIOS && "para Iphone"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
