import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import lupa from "../../assets/images/png/lupa.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
// import Accordion from "react-bootstrap/Accordion";
export default function Faq() {
  const { load, setLoad } = useLoad();
  const [pesquisa, setPesquisa] = useState("");
  const [listaFaq, setListaFaq] = useState([]);
  const navigate = useNavigate();

  async function fnListaFaq() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("faq/de-participante");
      setListaFaq(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  const filteredData = listaFaq?.filter((item) => {
    const pesquisaLowerCase = pesquisa.toLowerCase();
    return (
      item.titulo.toLowerCase().includes(pesquisaLowerCase) ||
      item.texto.toLowerCase().includes(pesquisaLowerCase)
    );
  });
  useEffect(() => {
    fnListaFaq();
  }, []);
  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          <HeaderAvatarMenu titulo={"Faq"} />

          <div className="d-flex justify-content-center flex-column align-items-center w-100">
            <div
              className="d-flex flex-column align-items-center w-100"
              style={{ maxWidth: 500 }}
            >
              <div className="w-100">
                <form autoComplete="off">
                  <input
                    style={{ paddingRight: 50 }}
                    autoComplete="off"
                    placeholder="Pesquisar no FAQ"
                    className="inputPrimary"
                    type="text"
                    value={pesquisa}
                    onChange={(e) => {
                      setPesquisa(e.target.value);
                    }}
                  />
                  <img
                    className="imgLupaCatalogoPerguntasFrequentes"
                    width={20}
                    src={lupa}
                    alt=""
                  />
                </form>
                <div
                  className="accordion"
                  id="accordionExample"
                  style={{ paddingBottom: 80 }}
                >
                  {filteredData?.map((item, i) => (
                    <div className="accordion-item" key={i}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse" + i}
                        aria-expanded={false}
                        aria-controls={"collapse" + i}
                      >
                        {item.titulo}
                      </button>
                      <div
                        id={"collapse" + i}
                        className="accordion-collapse collapse"
                        aria-labelledby={"heading" + i}
                      >
                        <div dangerouslySetInnerHTML={{ __html: item.texto }} />
                      </div>
                      {filteredData?.length !== i + 1 && <hr className="m-0" />}
                    </div>
                  ))}
                </div>
                {filteredData?.length == 0 && (
                  <div className="text-center ">
                    Não foi possivel encontrar resultado para a pesquisa
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
