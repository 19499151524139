import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanha from "../../assets/images/png/campanha.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
export default function Campanhas() {
  const { load, setLoad } = useLoad();
  const [campanha, setCampanha] = useState([]);
  const [meses, setMeses] = useState(99);
  const [anos, setAnos] = useState(true);

  const navigate = useNavigate();

  async function fncampanha(meses) {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("campanha?status=ativo");

      var novaLista = [];

      if (meses != 99) {
        response.data.map((e, i) => {
          if (moment(e.dataInicio).format("M") == meses) {
            novaLista.push(e);
          }
        });
        response.data = novaLista;
      }
      const compararPorDataInicio = (a, b) => {
        const dataInicioA = new Date(a.dataInicio);
        const dataInicioB = new Date(b.dataInicio);

        return dataInicioB - dataInicioA;
      };

      response.data.sort(compararPorDataInicio);

      setCampanha(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fncampanha(meses);
  }, [meses]);
  return (
    <div className="w-100 ">
      <div className="imgBackground"> </div>

      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link to={"/home"}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">Campanhas para você</div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="d-flex justify-content-center mt-5">
            <button className="botaoMesesAtivado">2024</button>
          </div>

          <div
            className="  py-5 "
            style={{
              width: "100%",
              minWidth: 366,
              maxWidth: 468,
              overflow: "hidden",
            }}
          >
            <Swiper slidesPerView={5} className="mySwiper">
              <SwiperSlide>
                <button
                  className={
                    meses == 1 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(1);
                  }}
                >
                  JAN
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 2 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(2);
                  }}
                >
                  FEV
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 3 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(3);
                  }}
                >
                  MAR
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 4 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(4);
                  }}
                >
                  ABR
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 5 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(5);
                  }}
                >
                  MAI
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 6 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(6);
                  }}
                >
                  JUN
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 7 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(7);
                  }}
                >
                  JUL
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 8 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(8);
                  }}
                >
                  AGO
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 9 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(9);
                  }}
                >
                  SET
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 10 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(10);
                  }}
                >
                  OUT
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 11 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(11);
                  }}
                >
                  NOV
                </button>
              </SwiperSlide>
              <SwiperSlide>
                <button
                  className={
                    meses == 12 ? "botaoMesesAtivado" : " botaoMesesDesativado"
                  }
                  onClick={() => {
                    setMeses(12);
                  }}
                >
                  DEZ
                </button>
              </SwiperSlide>
            </Swiper>
          </div>
          {campanha.map((e, i) => (
            <div className="px-3 w-100 " key={i}>
              <div className="d-flex w-100  ">
                <img width={135} height={135} src={e.urlImagem} alt="" />
                <div className="d-flex flex-column ms-5 w-100 ">
                  <div className="campanhaListaTexto3">{e.nome}</div>
                  <div className="campanhaListaTexto4">{e.tipo}</div>
                  <button
                    className=" btn-intitucional w-100 "
                    onClick={() => {
                      navigate("/campanha-detalhe/" + e.campanhaId);
                    }}
                  >
                    Ver mais
                  </button>
                </div>
              </div>
              {campanha.length != i + 1 && <hr />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
