import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import { validaCpf } from "../../services/validaCpf";
import { toast } from "react-toastify";
import storage from "../../services/storage";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function CpfCadastro() {
  const { load, setLoad } = useLoad();
  const [cpf, setCpf] = useState("");
  const navigate = useNavigate();
  const [trava, setTrava] = useState(false);

  async function fnCadastrarCpf() {
    setTrava(true);
    try {
      setLoad(true);
      var obj = {
        userName: cpf,
      };
      await ApiServices.apiPost("usuario/existe", obj)
        .then((res) => res.json())
        .then((res) => {
          if (res.statusCode === 200) {
            navigate("/email-cadastro");
            storage.set("USUARIO", { email: res.data.email, userName: cpf });
          } else {
            toast.error(res.message);
          }
        });
      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  function fnSalvaChave(valor) {
    storage.set("captcha", valor); 
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link to={"/login"}>
                <img className="imgVoltar" src={voltar} alt="" />
              </Link>
              <div className="cadastroTexto1">Vamos lá</div>
              <div className="cadastroTexto2">Comece informando seu CPF.</div>
              <label>CPF</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  name="labelCadastroCpf"
                  className="inputPrimary"
                  placeholder="000.000.000-00"
                  type="text"
                  maxLength={14}
                  inputMode="numeric"
                  value={Mask.cpf(cpf)}
                  onChange={(e) => {
                    setCpf(Mask.unMask(e.target.value));
                  }}
                />
                <div
                  className="labelLoginCpf m-0"
                  style={{ color: "#FF3E0E", fontWeight: 600 }}
                >
                  {validaCpf(cpf) || cpf.length < 11 ? " " : "CPF invalido"}
                </div>
              </form>
            </div>
            <div style={{ padding: " 20px 0" }}>
              <button
                onClick={fnCadastrarCpf}
                className="btn btn-primary w-100 "
                disabled={!validaCpf(cpf) || cpf.length != 11 || trava}
              >
                Próximo passo
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
