import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import botaoFechar from "../../assets/images/png/botaoFechar.png";

import roletaEstrela1 from "../../assets/images/png/roletaEstrela1.png";
import roletaEstrela2 from "../../assets/images/png/roletaEstrela2.png";
import roletaEstrela3 from "../../assets/images/png/roletaEstrela3.png";
import btnGirarRoleta from "../../assets/images/png/btnGirarRoleta.png";
import BarraProgressoRoleta from "../../Components/BarraProgressoRoleta";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import storage from "../../services/storage";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
export default function MetaRoleta() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [quantidade, setQuantidade] = useState({});
  const [missaoAtual, setMissaoAtual] = useState(0);

  // async function fnUsuario() {
  //   try {
  //     setLoad(true);
  //     const response = await ApiServices.apiGet("eu");

  //   } catch (err) {
  //     console.error("err", err);
  //   } finally {
  //     setLoad(false);
  //   }
  // }

  async function fnEstrelaVendedor() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("missao/meu-resumo");

      var atual = null;
      var encontrouSequencia = false;

      for (let i = response.data.missoes.length - 1; i >= 0; i--) {
        if (response.data.missoes[i].atual) {
          if (!encontrouSequencia) {
            // Inicia uma nova sequência de 'true'
            atual = response.data.missoes[i];
            encontrouSequencia = true;
          }
        } else {
          if (encontrouSequencia) {
            // Se encontrou uma sequência de 'true' seguida de 'false', pare
            break;
          }
        }
      }

      setMissaoAtual(atual);
      console.log("eeeeeee", atual.numero);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnLinkTipoRoleta() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("roleta/minhas-jogadas");

      response.data.map((e, i) => {
        if (e.atual) {
          setQuantidade(e);
        }
      });
      response.data.every((e) => !e.atual) &&
        toast.error("Conclua a missão para liberar a roleta");
    } catch (err) {
      console.error("Erro ao buscar jogadas:", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnVerificaUsuarioPodeJogar() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGetRoleta(
        quantidade.baseAddress +
          "/participante/autenticarParticipante/" +
          storage.get(`USUARIO`).profile.userId
      );
      const data = await response.json();
      storage.del("ROLETALINK");
      storage.del("ROLETATOKEN");
      storage.set("ROLETALINK", quantidade.baseAddress);
      storage.set("ROLETATOKEN", data.token);

      quantidade.qtdEstrelas == 1 && navigate("/girar-roleta-uma-estrela");
      quantidade.qtdEstrelas == 2 && navigate("/girar-roleta-duas-estrela");
      quantidade.qtdEstrelas == 3 && navigate("/girar-roleta-tres-estrela");
    } catch (err) {
      console.error("err", err);
      toast.error("Sem acesso a roleta");
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    if (quantidade?.qtdEstrelas > 0) {
      fnVerificaUsuarioPodeJogar();
    }
  }, [quantidade]);
  useEffect(() => {
    fnEstrelaVendedor();
  }, []);
  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          {/* ///////////////////// BOTOES   /////////////////////////////// */}
          {/* <div className="d-flex justify-content-center">
          <div className=" d-flex justify-content-around mt-5 position-absolute">
            <button
              className="btn btn-danger m-5"
              onClick={() => {
                setMissaoAtual((prev) => ({
                  ...prev,
                  numero: missaoAtual.numero - 1,
                }));
              }}
            >
              -
            </button>
            <button
              className="btn btn-danger m-5"
              onClick={() => {
                setMissaoAtual((prev) => ({
                  ...prev,
                  numero: missaoAtual.numero + 1,
                }));
              }}
            >
              +
            </button>
          </div>
        </div> */}
          {/* ///////////////////// BOTOES   /////////////////////////////// */}
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 70 }}
          >
            <Link to="/home">
              <img width={50} src={botaoFechar} alt="" />
            </Link>
          </div>
          <h1 className="text-center" style={{ marginTop: 70 }}>
            Você está na missão {missaoAtual?.numero}
          </h1>
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: 30 }}
          >
            <div
              className="text-center"
              style={{ maxWidth: 300, opacity: "0.8" }}
            >
              Conclua as missões para elevar o nível de sua roleta
            </div>
          </div>

          <div className="d-flex justify-content-evenly mt-4 w-100">
            <img
              width={50}
              src={roletaEstrela1}
              style={{
                filter:
                  missaoAtual?.numero > 1 ? "grayscale(0)" : "grayscale(1)",
              }}
              alt=""
            />
            <img
              width={50}
              src={roletaEstrela2}
              style={{
                filter:
                  missaoAtual?.numero >= 4 ? "grayscale(0)" : "grayscale(1)",
              }}
              alt=""
            />
            <img
              width={50}
              src={roletaEstrela3}
              style={{
                filter:
                  missaoAtual?.numero >= 9 ? "grayscale(0)" : "grayscale(1)",
              }}
              alt=""
            />
          </div>
          <div className="mt-4 mb-2">
            <BarraProgressoRoleta progresso={missaoAtual?.numero} />
          </div>
          <div className="d-flex justify-content-evenly w-100">
            <div className="d-flex flex-column align-items-center ms-5">
              <div className="">4ª </div>
              <div className="">Missão</div>
            </div>
            <div className="d-flex flex-column align-items-center me-5">
              <div className="ms-2">9ª </div>
              <div className="">Missão</div>
            </div>
          </div>

          <div
            className="d-flex justify-content-center"
            style={{ paddingBottom: 80 }}
          >
            <img
              onClick={() => {
                if (missaoAtual?.podeCompletar) {
                  fnLinkTipoRoleta();
                } else {
                  toast.error("Conclua a missão para liberar a roleta");
                }
              }}
              className="mt-5 btRoleta"
              width={"35%"}
              src={btnGirarRoleta}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}
