import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import checkOk from "../../assets/images/png/checkOk.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";

export default function ResgateVoucher() {
  const { load, setLoad } = useLoad();
  const [cpf, setCpf] = useState("");
  const [tempo, setTempo] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();
  const voucher = location.state;

  return (
    <>
      <div className="imgBackground">
        <div className="d-flex justify-content-center">
          <div
            className="imgBackgroundInterno px-3 "
            style={{ overflow: "auto" }}
          >
            <div className="d-flex flex-column  justify-content-between align-items-center h-100">
              <div></div>
              <div className="d-flex justify-content-center flex-column align-items-center">
                <img width={"100%"} src={checkOk} style={{ maxWidth: 185 }} />
                <br />
                <h1>Resgate </h1>
                <h1>Confirmado! </h1>
                <br />
                <h4 style={{ opacity: 0.8 }}>O número do seu cupom é:</h4>
                <h4 className="text-center" style={{ width: "80%" }}>{voucher}</h4>
                <br />
                <b className="mb-1">Como utilizar:</b>
                <div>Utilize esse código no campo de cupom de desconto.</div>
              </div>
              <div className="w-100" style={{ paddingBottom: 80 }}>
                <button
                  className="btn btn-secondary w-100"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  Voltar para a tela inicial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
