import React from "react";

import roletaTresEstrela from "../../assets/images/png/roleta/roletaTresEstrela.png";
import Seta from "../../assets/images/png/roleta/seta.png";
import Botao from "../../assets/images/png/roleta/botao.png";
import "./index.css";
import { useState } from "react";
import btnRoletaFrente from "../../assets/images/png/roleta/btnRoletaFrente.gif";
import btnRoletaFundo from "../../assets/images/png/roleta/btnRoletaFundo.gif";
import Ganhou from "../../assets/images/png/roleta/ganhou.png";
import Perdeu from "../../assets/images/png/roleta/naoGanhou.png";
import somRoleta from "../../assets/mp3/roleta.mp3";
import Realistic from "./Realistic";
import Fireworks from "./Fireworks";
import ApiServices from "../../services/apiServices";
import botaoFechar from "../../assets/images/png/botaoFechar.png";
import riachuelo from "../../assets/images/png/riachuelo.png";
import naoFoi from "../../assets/images/png/naoFoi.png";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import CloseIcon from "@mui/icons-material/Close";
import storage from "../../services/storage";

export default function GirarRoletaTresEstrela() {
  const [resultado, setResultado] = useState("");
  const [open, setOpen] = useState(false);
  const [campanhaAtiva, setCampanhaAtiva] = useState(true);
  const [tipoPremio, setTipoPremio] = useState(0);
  const [balao, setBalao] = useState(0);
  const [jogar, setJogar] = useState(99);
  const [msgSemJogadas, setMsgSemJogadas] = useState(false);
  const [tempo, setTempo] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tempo > 0) {
        setTempo(tempo - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    if (tempo === 0) {
      navigate("/recompensas", { state: { key: tipoPremio } });
    }

    return () => {
      clearInterval(interval);
    };
  }, [tempo]);

  const navigate = useNavigate();
  const audio = new Audio(somRoleta);
  audio.preload = "auto";
  const modalOpen = () => {
    setOpen(true);
  };
  const modalClose = () => {
    setOpen(false);
    setResultado("");
    setJogar(99);
    var roleta = document.querySelector(".roleta");
    roleta.classList.remove("girar");
    roleta.style.transform = `rotate(${0}deg)`;

    var seta = document.querySelector(".seta");
    seta.classList.remove("setaAnimada");
  };
  ///participante/cupom/resgatar
  async function fnResgatar() {
    await ApiServices.apiRoletaPostToken(
      storage.get("ROLETALINK") + "/participante/cupom/resgatar"
    ).then((res) => {
      fnGrausRolera(res);
      fnConfirmar(res);
      setTipoPremio(res.valor);
    });
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // var res = {
    //   balaoId: 0,
    //   cupomId: 0,
    //   cupomECommerceid: 0,
    //   cupomCode: 5,
    //   valor: 0,
    //   viaSoft: false,
    //   numeroDaSorteId: 0,
    //   ganhador: balao % 2 === 0,
    // };
    // setTipoPremio(res.cupomCode);
    // fnGrausRolera(res);
    // fnConfirmar(res);
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }
  ///participante/cupom/confirmar
  async function fnConfirmar(verificaGanhador) {
    await ApiServices.apiRoletaPostToken(
      storage.get("ROLETALINK") + "/participante/cupom/confirmar"
    ).then((res) => {
      setTimeout(() => {
        if (verificaGanhador.ganhador == false) {
          setResultado("perdeu");
          modalOpen();
        }
        if (verificaGanhador.ganhador == true) {
          setResultado("ganhou");
          modalOpen();
        }
        fnResumo();
        setJogar(2);
        setTempo(5);
      }, 6000);
    });
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // setTimeout(() => {
    //   if (verificaGanhador.ganhador == false) {
    //     setResultado("perdeu");
    //     setTimeout(() => {
    //       navigate("/nao-foi-dessa-vez");
    //     }, 6000);
    //     modalOpen();
    //   }
    //   if (verificaGanhador.ganhador == true) {
    //     setResultado("ganhou");
    //     setTimeout(() => {
    //       navigate("/parabens");
    //     }, 6000);
    //     modalOpen();
    //   }
    //   setJogar(2);
    //   setTempo(5);
    // }, 6000);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }

  // // Testa os graus da roleta de acordo com os premios
  // useEffect(() => {
  //   const roletaElement = document.querySelector(".roleta");
  //   if (roletaElement) {
  //     roletaElement.style.transform = "rotate(-30deg)";
  //   }
  // }, []);

  // 0 = naoFoiDessaVez
  // 30 = iphone
  // -30 = ipad
  // 60 = macbook Air
  // -60 = Connect Car
  // 90 = NAO FOI DESSA VEZ
  // -90 = RAPPI
  // 120 = Connerc Car
  // -120 = CINEMARK
  // 150 = CINEMARK
  // -150 = RAPPI
  // 180 = naoFoiDessaVez
  // 1180 = naoFoiDessaVez

  function fnGrausRolera(res) {
    var g = 0;
    if (res.ganhador) {
      // 30 = iphone
      if (res.valor == 13000) {
        g = 30;
      }
      // -30 = ipad
      if (res.valor == 14000) {
        g = -30;
      }
      // 60 = macbook
      if (res.valor == 15000) {
        g = 60;
      }
      // -60 = CONNECTCAR
      if (res.valor == 30000) {
        g = -60;
      }
      // -90 = RAPPI
      if (res.valor == 31000) {
        g = -90;
      }
      // -120 = CINEMARK
      if (res.valor == 32000) {
        g = -120;
      }
    } else {
      g = 0;
    }
    audio.volume = 0.5;
    audio.play();

    var roleta = document.querySelector(".roleta");
    roleta.classList.add("girar");
    roleta.style.transform = `rotate(${g + 3600}deg)`;

    var seta = document.querySelector(".seta");
    seta.classList.add("setaAnimada");
    // var bts = document.querySelectorAll(".btn");
    // for (let bt of bts) {
    //   bt.disabled = true;
    // }
  }

  ///participante/campanhaAtiva
  useEffect(() => {
    ApiServices.apiGetRoleta(
      storage.get("ROLETALINK") + "/participante/campanhaAtiva"
    ).then((res) => {
      setCampanhaAtiva(res);
    });
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // setCampanhaAtiva(true);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }, []);

  ///participante/resumo
  async function fnResumo() {
    try {
      const response = await ApiServices.apiGetRoleta(
        storage.get("ROLETALINK") + "/participante/resumo"
      );
      const data = await response.json();

      if (data.balao < 1) {
        setJogar(0);
      }
      setBalao(data.balao);
    } catch (error) {
      console.error("Erro ao buscar resumo:", error);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // var res = {
    //   balao: 10,
    //   saldo: 20,
    //   acessou: false,
    //   participante: {
    //     Participanteid: 36401,
    //     cpf: "21853019852",
    //     nome: "Ricardo Zuqueto",
    //     celular: "99999999999",
    //     email: "ricardozuqueto@gmail.com",
    //     aceiteRegulamento: true,
    //     idCliente: "00306cd7-7260-45f0-86d2-8cf239350165",
    //     ABCDOG: false,
    //     estadoCliente: "",
    //     Acessou: false,
    //     dataCriacao: "2022-11-04T13:33:06.643",
    //   },
    // };

    // if (res.balao < 1) {
    //   setJogar(0);
    // }
    // setBalao(res.balao);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }

  useEffect(() => {
    fnResumo();
  }, []);

  return (
    <div className="imgBackground">
      <div
        className="imgBackgroundInterno px-3 h-100"
        style={{ overflow: "hidden", height: "100%" }}
      >
        <div
          className=" d-flex flex-column justify-content-between h-100"
          style={{ marginTop: -10 }}
        >
          <HeaderAvatarMenu mb={10} />

          <div className="divTodaRoleta">
            <div className="w-100">
              <div className="divTextoRoleta">
                <Link to="/home" className="mb-4">
                  <img width={50} src={botaoFechar} alt="" />
                </Link>
                <div className="d-flex flex-column justify-content-center align-items-center mb-3 ">
                  <h1 className="text-center">Gire e ganhe</h1>
                  {balao < 1 ? (
                    <div className="legendaRoleta">
                      Você não possui jogadas disponíveis na roleta de 3
                      estrelas
                    </div>
                  ) : msgSemJogadas ? (
                    <h3 className="legendaRoleta"> </h3>
                  ) : (
                    <div className="legendaRoleta">
                      Você ainda tem {balao < 1 ? "0" : balao} chance(s) para
                      ganhar na roleta de 3 estrelas
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="divTodosItensRoleta">
              {/* MODAL */}
              {!campanhaAtiva && (
                <div className="modalExterno">
                  <div className="modalInterno">
                    <p className="m-5 p-5 text-center">
                      ESTA CAMPANHA NÃO ESTA MAIS ATIVA !
                    </p>
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => navigate("/home")}
                    >
                      VOLTAR
                    </button>
                  </div>
                </div>
              )}
              {/* {open && (
                <div className="modalExterno">
                  <div className="modalInterno m-3">
                    <div
                      className="d-flex justify-content-end pointer"
                      onClick={() => {
                        modalClose();
                      }}
                    >
                      <CloseIcon />
                    </div>
                    {resultado == "ganhou" && (
                      <>
                        <h3>Parabéns!</h3>
                        <hr />
                        <div>Você acaba de ganhar um voucher da Riachuelo</div>
                        <div className="d-flex justify-content-center">
                          <img width={"70%"} src={riachuelo} alt="" />
                        </div>
                      </>
                    )}
                    {resultado == "perdeu" && (
                      <>
                        <h3>Não foi dessa vez</h3>

                        <hr />
                        <div>Tente novamente ...</div>
                        <div className="d-flex justify-content-center">
                          <img width={"100%"} src={naoFoi} alt="" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )} */}
              <div style={{ position: "absolute", zIndex: 500595959 }}>
                {resultado == "ganhou" && <Realistic />}
                {resultado == "ganhou" && <Fireworks />}
              </div>

              {/* ROLETA */}
              {/* <img src={roletaTresEstrela} alt="" className="roleta" /> */}

              {/* IMGAGEM DA ROLETA */}
              <img src={roletaTresEstrela} alt="" className="roleta" />
              {/* IMAGEM SETA */}
              <img src={Seta} alt="" className="seta" />
              {/* BOTAO DA ROLETA */}
              <div className="divBtJogar">
                {
                  jogar == 99 && balao >= 1 ? (
                    <img
                      onClick={() => {
                        setBalao(balao - 1);
                        fnResgatar();
                        setJogar(2);
                      }}
                      src={btnRoletaFrente}
                      alt=""
                      className="botoaJogarAnimado"
                      height={"100%"}
                    />
                  ) : (
                    <div
                      className="position-relative d-flex align-items-center justify-content-center"
                      style={{ height: "100%" }}
                    >
                      <h1
                        className="position-absolute mt-3 fw-bold d-flex justify-content-center align-items-center"
                        style={{
                          color: "#0202a3",
                        }}
                      >
                        {tempo >= 0 && tempo}
                      </h1>
                      <img src={btnRoletaFundo} height={"100%"} />
                    </div>
                  )
                  // !msgSemJogadas ? (
                  // ) : (
                  //   <div className="botaoJogarBloqueio">
                  //     Você já foi contemplado com um brinde.
                  //     <br></br>
                  //     Giros desativados.
                  //   </div>
                  // )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
