import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";

export default function EsqueceuSenhaConfirmacaoSucesso() {
  const { load, setLoad } = useLoad();
  const [cpf, setCpf] = useState("");
  const navigate = useNavigate();

  async function fnCarregarBannerRotativo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner?ativo=true");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground">
        <div className="imgBackgroundInterno px-3 " style={{ overflow: "auto" }}>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link
                onClick={() => window.history.back()}
                className="invisible "
              >
                <img className="imgVoltar" src={voltar} alt="" />
              </Link>
              <div className="esqueciConfirmacaoTexto1">
                E-mail enviado com sucesso!
              </div>
              <div className="esqueciConfirmacaoTexto2">
                Enviamos as instruções para o e-mail cadastrado.
              </div>
            </div>

            <button
              onClick={() => {
                navigate("/login");
              }}
              className="btn btn-secondary w-100"
              style={{ margin: "20px 0" }}
            >
              Voltar para a tela inicial
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
