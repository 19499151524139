import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import ApiServices from "../../services/apiServices";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Menu from "../../Components/Menu";
import HomeMapa11 from "../../assets/images/png/HomeMapa11.png";
import HomeMapa12 from "../../assets/images/png/HomeMapa12.png";
import HomeMapa13 from "../../assets/images/png/HomeMapa13.png";
import HomeMapa14 from "../../assets/images/png/HomeMapa14.png";
import HomeMapa15 from "../../assets/images/png/HomeMapa15.png";

import HomeMapa21 from "../../assets/images/png/HomeMapa21.png";
import HomeMapa22 from "../../assets/images/png/HomeMapa22.png";
import HomeMapa23 from "../../assets/images/png/HomeMapa23.png";
import HomeMapa24 from "../../assets/images/png/HomeMapa24.png";
import HomeMapa25 from "../../assets/images/png/HomeMapa25.png";
import HomeMapa26 from "../../assets/images/png/HomeMapa26.png";
import HomeMapa31 from "../../assets/images/png/HomeMapa31.png";
import HomeMapa32 from "../../assets/images/png/HomeMapa32.png";
import HomeMapa33 from "../../assets/images/png/HomeMapa33.png";
import HomeMapa34 from "../../assets/images/png/HomeMapa34.png";
import HomeMapa35 from "../../assets/images/png/HomeMapa35.png";
import HomeMapa41 from "../../assets/images/png/HomeMapa41.png";
import HomeMapa42 from "../../assets/images/png/HomeMapa42.png";
import HomeMapa43 from "../../assets/images/png/HomeMapa43.png";
import HomeMapa44 from "../../assets/images/png/HomeMapa44.png";
import HomeMapa45 from "../../assets/images/png/HomeMapa45.png";
import HeaderHomeVendedor from "./HeaderHomeVendedor";
import FooterHomeVendedor from "./FooterHomeVendedor";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import storage from "../../services/storage";
export default function HomeVendedor() {
  const { load, setLoad } = useLoad();
  const [ordemMapas, setOrdemMapas] = useState([]);
  const [posicaoMissao, setPosicaoMissao] = useState(0);
  const [infoHeader, setInfoHeader] = useState({});
  const [missao, setMissao] = useState({});
  const [usuario, setUsuario] = useState([]);
  const navigate = useNavigate();
  function fnPosicaoMissao() {
    if (posicaoMissao === 0) {
      setOrdemMapas([HomeMapa11]);
    }
    if (posicaoMissao === 1) {
      setOrdemMapas([HomeMapa12]);
    }
    if (posicaoMissao === 2) {
      setOrdemMapas([HomeMapa13]);
    }
    if (posicaoMissao === 3) {
      setOrdemMapas([HomeMapa14]);
    }
    if (posicaoMissao === 4) {
      setOrdemMapas([HomeMapa21, HomeMapa15]);
    }
    if (posicaoMissao === 5) {
      setOrdemMapas([HomeMapa22, HomeMapa15]);
    }
    if (posicaoMissao === 6) {
      setOrdemMapas([HomeMapa23, HomeMapa15]);
    }
    if (posicaoMissao === 7) {
      setOrdemMapas([HomeMapa24, HomeMapa15]);
    }
    if (posicaoMissao === 8) {
      setOrdemMapas([HomeMapa25, HomeMapa15]);
    }
    if (posicaoMissao === 9) {
      setOrdemMapas([HomeMapa31, HomeMapa26, HomeMapa15]);
    }
    if (posicaoMissao === 10) {
      setOrdemMapas([HomeMapa32, HomeMapa26, HomeMapa15]);
    }
    if (posicaoMissao === 11) {
      setOrdemMapas([HomeMapa33, HomeMapa26, HomeMapa15]);
    }
    if (posicaoMissao === 12) {
      setOrdemMapas([HomeMapa34, HomeMapa26, HomeMapa15]);
    }
    if (posicaoMissao === 13) {
      setOrdemMapas([HomeMapa41, HomeMapa35, HomeMapa26, HomeMapa15]);
    }
    if (posicaoMissao === 14) {
      setOrdemMapas([HomeMapa42, HomeMapa35, HomeMapa26, HomeMapa15]);
    }
    if (posicaoMissao === 15) {
      setOrdemMapas([HomeMapa43, HomeMapa35, HomeMapa26, HomeMapa15]);
    }
    if (posicaoMissao === 16) {
      setOrdemMapas([HomeMapa44, HomeMapa35, HomeMapa26, HomeMapa15]);
    }
    if (posicaoMissao === 17) {
      setOrdemMapas([HomeMapa45, HomeMapa35, HomeMapa26, HomeMapa15]);
    }
  }
  async function fnMissoes() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("missao/meu-resumo");
      setInfoHeader(response.data);
      var atual = null;
      var encontrouSequencia = false;

      for (let i = response.data.missoes.length - 1; i >= 0; i--) {
        if (response.data.missoes[i].atual) {
          if (!encontrouSequencia) {
            // Inicia uma nova sequência de 'true'
            atual = response.data.missoes[i];
            encontrouSequencia = true;
          }
        } else {
          if (encontrouSequencia) {
            // Se encontrou uma sequência de 'true' seguida de 'false', pare
            break;
          }
        }
      }
      setMissao(atual);
      setPosicaoMissao(
        atual.numero === 17 && atual.completou ? 17 : atual.numero - 1
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnProximaMissao() {
    try {
      setLoad(true);
      const response = await ApiServices.apiPost("missao/completar");

      const data = await response.json();
      if (data.statusCode === 200) {
        if( missao.numero == 17 ) {
          toast.success("Você completou todas as missões, continue cadastrando as vendas.");
        } else {
          toast.success("Bem-vindo a nova missão");
        }
        window.location.reload();
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnUsuario() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("eu");

      setUsuario(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnMissoes();
    fnUsuario();
  }, []);
  useEffect(() => {
    fnPosicaoMissao();
  }, [posicaoMissao]);
  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        {/* GIRO ROLETA */}
        {/* <div
          style={{
            position: "absolute",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 0 } });
            }}
          >
            0
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 1000 } });
            }}
          >
            1000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 2000 } });
            }}
          >
            2000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 3000 } });
            }}
          >
            3000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 4000 } });
            }}
          >
            4000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 5000 } });
            }}
          >
            5000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 6000 } });
            }}
          >
            6000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 7000 } });
            }}
          >
            7000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 8000 } });
            }}
          >
            8000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 10000 } });
            }}
          >
            10000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 11000 } });
            }}
          >
            11000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 12000 } });
            }}
          >
            12000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 13000 } });
            }}
          >
            13000
          </button>
          <button
            onClick={() => {
              navigate("/recompensas", { state: { key: 14000 } });
            }}
          >
            14000
          </button>
        </div> */}
        {/* PASSAR OS MAPAS */}
        {/* 
        <div style={{ position: "absolute", zIndex: 1000, display: "flex" }}>
          <button
            className="btn btn-primary btn-sm m-3"
            onClick={() => {
              setPosicaoMissao(
                posicaoMissao > 0 ? posicaoMissao - 1 : posicaoMissao
              );
            }}
          >
            -
          </button>

          <button
            className="btn btn-primary btn-sm m-3"
            style={{ background: "#000" }}
          >
            {posicaoMissao}
          </button>

          <button
            className="btn btn-primary btn-sm m-3"
            onClick={() => {
              setPosicaoMissao(
                posicaoMissao < 17 ? posicaoMissao + 1 : posicaoMissao
              );
            }}
          >
            +
          </button>
        </div> */}
        <div className="imgBackgroundInterno" style={{ overflow: "hidden" }}>
          <Swiper
            style={{ height: "100%", width: "100%" }}
            direction={"vertical"}
            className="mySwiper"
          >
            {ordemMapas.map((e, i) => (
              <SwiperSlide key={i}>
                <div
                  className="imagemMapa"
                  style={{ backgroundImage: `url(${e})` }}
                ></div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="d-flex justify-content-between">
            <Menu />
            <HeaderHomeVendedor usuario={usuario} />
          </div>

          <FooterHomeVendedor
            missao={missao}
            infoHeader={infoHeader}
            fnProximaMissao={fnProximaMissao}
          />
        </div>
      </div>
    </>
  );
}
