import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import storage from "../../services/storage";

export default function EmailRecadastroLogin() {
  const { load, setLoad } = useLoad();
  const [login, setLogin] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const [trava, setTrava] = useState(false);
  async function fnEnviarEmail() {
    setTrava(true);
    var obj = {
      codigoLogin: login,
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPost("usuario/codigo/login", obj);
      const data = await response.json();

      if (data.statusCode === 200) {
        const atualizado = Object.assign(storage?.get("USUARIO") || {}, {
          login: login,
        });
        storage.set("USUARIO", atualizado);
        navigate("/email-recadastro-email");
      } else {
        setLogin("");
        toast.error(data.message);
      }
      setTrava(false);
    } catch (err) {
      setLogin("");
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground">
        <div className="d-flex justify-content-center">
          <div
            className="imgBackgroundInterno px-3 "
            style={{ overflow: "auto" }}
          >
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <Link to={"/email-cadastro"}>
                  <img className="imgVoltar" src={voltar} alt="" />
                </Link>
                <div className="emailRecadastroLoginTexto1">Verificação</div>
                <div className="emailRecadastroLoginTexto2">
                  <div style={{ marginBottom: 10 }}>
                    Digite abaixo o seu Login no Portal do Centro de Treinamento
                    Hyundai.
                  </div>
                </div>
                <label>Informe seu login</label>
                <form autoComplete="off">
                  <input
                    autoComplete="off"
                    className="inputPrimary"
                    type="text"
                    value={login}
                    onChange={(e) => {
                      setLogin(e.target.value);
                    }}
                  />
                </form>
              </div>

              <button
                onClick={fnEnviarEmail}
                className="btn btn-primary w-100 mt-5"
                disabled={!login || trava}
                style={{ margin: "20px 0" }}
              >
                Validar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
