import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import storage from "../../services/storage";
import { validaDataNascimento } from "../../services/validaDataNascimento";

export default function Sobre() {
  const { load, setLoad } = useLoad();
  const [listaCargo, setListaCargo] = useState([]);
  const [listaGrupo, setListaGrupo] = useState([]);
  const [sobre, setSobre] = useState({
    nome: storage.get("USUARIO")?.nome,
    data: "",
    roleId: storage.get("USUARIO")?.cargoId,
    grupoId: "1",
    cargoNome: storage.get("USUARIO")?.cargoNome,
    grupoNome: storage.get("USUARIO")?.grupo,
  });
  const navigate = useNavigate();

  async function fnListaCargo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("role/deslogado/participante");
      setListaCargo(response?.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListaGrupo() {
    setListaGrupo([
      {
        roleId: "89B97424-23F8-455C-AD38-A9A2D9B1784B",
        displayName: "AG MOTO PATOS",
      },
      {
        roleId: "BD599436-282A-4B76-B419-4D767551DBD6",
        displayName: "ALVORADA MOTOS",
      },
      {
        roleId: "BD599436-282A-4B76-B419-4D767551DBD6",
        displayName: "ARI MOTOS",
      },
      {
        roleId: "BD599436-282A-4B76-B419-4D767551DBD6",
        displayName: "BBMC",
      },
    ]);

    try {
      setLoad(true);
      let response = await ApiServices.apiGet("role/deslogado/participante");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListaCargo();
    fnListaGrupo();
  }, []);
  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link to={"/celular-cadastro"}>
                <img className="imgVoltar" src={voltar} alt="" />
              </Link>
              <div className="sobreTexto1">Muito obrigado!</div>
              <div className="sobreTexto2">
                <div style={{ marginBottom: 10 }}>
                  Agora precisamos saber um pouco mais sobre você!
                </div>
              </div>
              <label>Nome completo</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  disabled
                  className="inputPrimary"
                  type="text"
                  value={sobre?.nome}
                  onChange={(e) => {
                    setSobre((prev) => ({
                      ...prev,
                      nome: e.target.value,
                    }));
                  }}
                />
              </form>
              <label>Cargo</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  disabled
                  className="inputPrimary"
                  type="text"
                  value={sobre?.cargoNome}
                  onChange={(e) => {}}
                />
              </form>
              <label>Grupo</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  disabled
                  className="inputPrimary"
                  type="text"
                  value={sobre?.grupoNome || ""}
                  onChange={(e) => {}}
                />
              </form>
              
              <label>Data nascimento</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  className="inputPrimary"
                  placeholder="00/00/0000"
                  maxLength={10}
                  inputMode="numeric"
                  type="text"
                  value={Mask.date(sobre?.data)}
                  onChange={(e) => {
                    setSobre((prev) => ({
                      ...prev,
                      data: Mask.unMask(e.target.value),
                    }));
                  }}
                />
                <div className="avisoCampo">
                  {!validaDataNascimento(sobre?.data) && sobre?.data.length == 8
                    ? "Data de nascimento inválida"
                    : " "}
                </div>
              </form>
            </div>
            <button
              disabled={
                sobre?.nome?.length < 3 ||
                sobre?.data?.length != 8 ||
                sobre?.roleId == "" ||
                !validaDataNascimento(sobre?.data) ||
                sobre?.data.length < 8
              }
              onClick={() => {
                const atualizado = Object.assign(storage.get("USUARIO"), sobre);
                storage.set("USUARIO", atualizado);
                navigate("/senha");
              }}
              className="btn btn-primary w-100 "
              style={{ margin: "20px 0" }}
            >
              Próximo passo
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
