import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import moment from "moment";
import storage from "../../services/storage";
import Menu from "../../Components/Menu";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

import { Accordion } from "../../Components/Accordion";

import HeaderHomeGerente from "./HeaderHomeGerente";
import BarraProgresso from "../../Components/BarraProgresso";

export default function HomeGerente() {
  const { load, setLoad } = useLoad();
  const [meta, setMeta] = useState({});
  const [mesSelecionado, setMesSelecionado] = useState({});
  const [numeroDaSorte, setNumeroDaSorte] = useState([]);

  async function fnMetaGerente() {
    setLoad(true);
    function toCamelCase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    try {
      let response = await ApiServices.apiGet("grupo/meu-resumo");

      response.data.meses.forEach(
        (e, i) => (e.mes = toCamelCase(moment(e.mes).format("MMMM")))
      );

      setMeta(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnMetaGerente();
  }, []);
  return (
    <>
      <div className="imgBackground">
        <div className="imgBackgroundInterno" style={{ overflow: "auto" }}>
          <div className="d-flex flex-column justify-content-between h-100 px-3">
            <div className="d-flex justify-content-between">
              <div style={{ height: 160 }}>
                <Menu />
                <HeaderHomeGerente meta={meta} />
              </div>
            </div>

            <div className="h-100">
              <h5 className="d-flex align-items-end justify-content-between">
                Cadastro vendedores
                <div className="d-flex flex-column align-items-end">
                  <div>
                    {meta.qtdCadastroFeito} / {meta.qtdCadastroMeta}
                  </div>
                  <div>100%</div>
                </div>
              </h5>
              <div className="mb-5">
                <BarraProgresso
                  progresso={
                    (meta.qtdCadastroFeito / meta.qtdCadastroMeta) * 100 >= 100
                      ? 100
                      : (meta.qtdCadastroFeito / meta.qtdCadastroMeta) * 100
                  }
                />
              </div>
              <div className="d-flex flex-column mt-3 mb-5">
                <label>Mês</label>
                <select
                  className="w-100"
                  value={mesSelecionado?.mes || ""}
                  onChange={(event) => {
                    setMesSelecionado("");
                    meta?.meses?.forEach((mes, i) => {
                      if (mes.mes === event.target.value) {
                        setMesSelecionado(mes);
                      }
                    });
                  }}
                  style={{ width: 150 }}
                >
                  <option value={""}>Selecione</option>
                  {meta?.meses?.map((mes, i) => (
                    <option key={i} value={mes.mes}>
                      {mes.mes}
                    </option>
                  ))}
                </select>
              </div>

              <h5 className="d-flex align-items-end justify-content-between">
                XML Importado
                <div className="d-flex flex-column align-items-end">
                  <div>
                    {(mesSelecionado?.varejoRealizado || 0) +
                      " / " +
                      (mesSelecionado.vendasImportadas || 0)}
                  </div>
                </div>
              </h5>

              <div className="mb-4">
                <BarraProgresso
                  progresso={
                    ((mesSelecionado?.varejoRealizado || 0) /
                      (mesSelecionado?.vendasImportadas || 0) || 0) * 100
                  }
                />
              </div>

              <h5 className="d-flex align-items-end justify-content-between">
                Total de vendas
                <div className="d-flex flex-column align-items-end">
                  <div>
                    {(mesSelecionado?.varejoRealizado || 0) +
                      " / " +
                      (mesSelecionado.varejoMeta || 0)}
                  </div>
                </div>
              </h5>
              <div className="mb-4">
                <BarraProgresso
                  progresso={
                    ((mesSelecionado?.varejoRealizado || 0) /
                      (mesSelecionado?.varejoMeta || 0) || 0) * 100
                  }
                />

                <Accordion>
                  {mesSelecionado?.vendedoresVendas?.map(item => {
                    return (
                      <div className="mt-3 d-flex align-items-end justify-content-between">
                        <div>{item?.nome}</div>
                        <div>{item?.vendas} vendas</div>
                      </div>
                    )
                  })}


                </Accordion>
              </div>


              <h5 className="d-flex align-items-end justify-content-between">
                Meta NPS
                <div className="d-flex flex-column align-items-end">
                  <div>{mesSelecionado?.npsMeta || "" + "%"}</div>
                </div>
              </h5>
              <div className="mb-4">
                <BarraProgresso
                  progresso={
                    (mesSelecionado?.npsRealizado / mesSelecionado?.npsMeta) *
                    100
                  }
                />
              </div>
              <h5 className="d-flex align-items-end justify-content-between">
                Meta Leads
                <div className="d-flex flex-column align-items-end">
                  <div>{mesSelecionado?.leadsMeta || "" + "%"}</div>
                </div>
              </h5>
              <div style={{ paddingBottom: 100 }}>
                <BarraProgresso
                  progresso={
                    (mesSelecionado?.leadsRealizado /
                      mesSelecionado?.leadsMeta) *
                    100
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
