import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import bg from "../../assets/images/png/bg.png";
import bgLogin from "../../assets/images/png/bgLogin.png";
import logo from "../../assets/images/png/logo.png";
import logoBgLogin from "../../assets/images/png/logoBgLogin.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import storage from "../../services/storage";
import { validaCpf } from "../../services/validaCpf";
import { toast } from "react-toastify";
import ForcaSenha from "../../Components/ForcaSenha";
import { Visibility } from "@mui/icons-material";
import Logo2 from "../../assets/images/png/dev2.png";
import Linkedin from "../../assets/images/svg/linkedin.svg";
import Instagram from "../../assets/images/svg/instagram.svg";
import Facebook from "../../assets/images/svg/facebook.svg";
import Youtube from "../../assets/images/svg/youtube.svg";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { isAndroid, isIOS } from "react-device-detect";
import QRCode from "react-qr-code";
import WhatsAppLink from "../../services/whatsAppLink";

import { WhatsApp } from "@mui/icons-material";

export default function Login() {
  const { load, setLoad } = useLoad();
  const [modal, setModal] = useState(isAndroid || isIOS ? false : true);
  const [trava, setTrava] = useState(false);
  const [validacao, setValidacao] = useState(false);
  const [login, setLogin] = useState({
    cpf: "",
    senha: "",
  });
  const navigate = useNavigate();

  async function fnLogin() {
    setTrava(true);
    try {
      storage.del("USUARIO");
      setLoad(true);
      var obj = {
        userName: login.cpf,
        password: login.senha,
      };

      await ApiServices.apiPost("login/portal", obj)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 200) {
            storage.set("USUARIO", data.data);
            fnLoginInformar();
          } else {
            toast.error(data.message);
          }
        });
      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnLoginInformar() {
    try {
      setLoad(true);

      await ApiServices.apiGet("missao/informar-cadastro-completo");

      navigate("/home");

      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (
        validaCpf(login.cpf) &&
        login.cpf.length === 11 &&
        !trava &&
        validacao &&
        event.key === "Enter"
      ) {
        fnLogin();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [login, trava, validacao, fnLogin]);

  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />

      <div className="imgBackground ">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div style={{ display: "none" }}>
            <ForcaSenha
              senha={login.senha}
              confirmarSenha={login.senha}
              setValidacao={setValidacao}
            />
          </div>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <div className="d-flex justify-content-center py-3">
                <img width={"100%"} src={logoBgLogin} alt="" />
              </div>
              <div className="loginTexto1 ">Que bom ver você por aqui!</div>
              <div className="loginTexto2 ">Insira seu login de acesso </div>

              {window.location.hostname === "localhost" && (
                <div className="d-flex justify-content-around">
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setLogin({
                        cpf: "02474648992",
                        senha: "12qw!@QW",
                      });
                    }}
                  >
                    VENDEDOR
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={() => {
                      setLogin({
                        cpf: "11249138825",
                        senha: "12qw!@QW",
                      });
                    }}
                  >
                    GERENTE
                  </button>
                </div>
              )}

              <label>CPF</label>

              <input
                autoComplete="off"
                className="inputPrimary"
                placeholder="000.000.000-00"
                inputMode="numeric"
                maxLength={14}
                type="text"
                value={Mask.cpf(login.cpf)}
                onChange={(e) => {
                  setLogin((prev) => ({
                    ...prev,
                    cpf: Mask.unMask(e.target.value),
                  }));
                }}
              />
              <div
                className="labelLoginSenha m-0"
                style={{ color: "#FF3E0E", fontWeight: 600 }}
              >
                {validaCpf(login.cpf) || login.cpf.length < 11
                  ? " "
                  : "CPF invalido"}
              </div>

              <label>Senha</label>

              <input
                type="password"
                style={{ display: "none" }}
                autoComplete="off"
              />
              <input
                autoComplete="off"
                className="inputPrimary"
                type="password"
                value={login.senha}
                onChange={(e) => {
                  setLogin((prev) => ({ ...prev, senha: e.target.value }));
                }}
              />

              <div className="esqueciSenha pointer d-flex justify-content-end">
                <Link to={"/esqueci-minha-senha"}>Esqueci minha senha</Link>
              </div>

              <Link to={"/fale-conosco-deslogado"}>
                <button className="btn btn-secondary w-100 my-3">
                  Fale conosco
                </button>
              </Link>

              <button className="btn btn-secondary w-100" onClick={() => {  
                window.open("https://wa.me/551155935138?text=Ol%C3%A1!%20Eu%20gostaria%20de%20receber%20ajuda%20com%20a%20campanha%20Yamaha%20Rota%20Premiada")
              }}>
                Atendimento via Whatsapp <WhatsApp />
              </button>

              {/* <div className="d-flex justify-content-center w-100 mt-5 text-center">
                <div style={{ maxWidth: 300 }}>
                  Em caso de dúvidas, entre em contato com nosso SAC através do
                  e-mail: sac@rotapremiadayamaha.com.br
                </div>
              </div> */}
            </div>

            <div>
              <button
                className="btn btn-primary w-100 mt-4"
                onClick={fnLogin}
                disabled={
                  !validaCpf(login.cpf) ||
                  login.cpf.length != 11 ||
                  trava ||
                  !validacao
                }
              >
                Entrar
              </button>
              <Link to={"/cpf-cadastro"}>
                <button className="btn btn-secondary w-100 my-3">
                  Primeira vez aqui? <b>Cadastre-se</b>
                </button>
              </Link>
            </div>

            {modal && (
              <div className="modalExterno">
                <div className="modalInterno d-flex align-items-center justify-content-center flex-column">
                  <div>
                    <h4 className="text-center mb-4">
                      Potencialize sua experiência: escaneie aqui para acessar
                      pelo celular.
                    </h4>
                    <div className="d-flex justify-content-center align-items-center mb-5">
                      <div style={{ width: "80%" }}>
                        <QRCode
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={window.location.href}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => setModal(false)}
                  >
                    Fechar
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
