import React, { useEffect, useState } from "react";
import "./index.css";
import { styled } from "@material-ui/core";

import { ExpandCircleDown } from "@mui/icons-material"

export const Accordion = ({ children }) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={`accordion-container ${open ? "open" : ""}`}>

            <div className="accordion-header">
                <ExpandCircleDown 
                    onClick={() => setOpen(!open)} />
            </div>

            <div className="accordion-body">
                {children}
            </div>

        </div>
    )
}