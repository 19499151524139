import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";

import roleta from "../../assets/images/png/roleta.png";
import ok from "../../assets/images/png/ok.png";
import atual from "../../assets/images/png/atual.png";
import bloqueado from "../../assets/images/png/bloqueado.png";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import estrelaCheia from "../../assets/images/png/estrelaCheia.png";
import estrelaVazia from "../../assets/images/png/estrelaVazia.png";
export default function HistoricoDaRoletaDaSorte() {
  const { load, setLoad } = useLoad();
  const [historicoRoleta, setHistoricoRoleta] = useState([
    {
      data: "2024-04-02",
      estrelaRolera: "Roleta de 1 estrela",
      estrela: 1,
    },
    {
      data: "2024-06-15",
      estrelaRolera: "Roleta de 2 estrela",
      estrela: 2,
    },
    {
      data: "2024-10-28",
      estrelaRolera: "Roleta de 3 estrela",
      estrela: 3,
    },
    {
      data: "2024-08-09",
      estrelaRolera: "Roleta de 2 estrela",
      estrela: 2,
    },
    {
      data: "2024-12-03",
      estrelaRolera: "Roleta de 3 estrela",
      estrela: 3,
    },
    {
      data: "2024-05-20",
      estrelaRolera: "Roleta de 1 estrela",
      estrela: 1,
    },
    {
      data: "2024-09-07",
      estrelaRolera: "Roleta de 1 estrela",
      estrela: 1,
    },
  ]);

  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          <HeaderAvatarMenu
            titulo={"Histórico da roleta da sorte"}
            imagem={roleta}
          />
          <div style={{ paddingBottom: 80 }}>
            {historicoRoleta.map((e, i) => (
              <div key={i}>
                <div className="d-flex justify-content-between">
                  <div className="dataHistoricoRoleta">{e.data}</div>
                  <div
                    className="estrelaHistoricoRoleta d-flex justify-content-end"
                    style={{ minWidth: 150 }}
                  >
                    <div style={{ marginBottom: 5 }}>
                      {e.estrela >= 1 ? (
                        <img width={20} src={estrelaCheia} alt="" />
                      ) : (
                        <img width={20} src={estrelaVazia} alt="" />
                      )}
                      {e.estrela >= 2 ? (
                        <img width={20} src={estrelaCheia} alt="" />
                      ) : (
                        <img width={20} src={estrelaVazia} alt="" />
                      )}
                      {e.estrela >= 3 ? (
                        <img width={20} src={estrelaCheia} alt="" />
                      ) : (
                        <img width={20} src={estrelaVazia} alt="" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="numeroHistoricoRoleta tresPontinhos me-3">
                  {e.estrelaRolera}
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
