import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import storage from "../../services/storage";
import { toast } from "react-toastify";

export default function CelularCadastro() {
  const { load, setLoad } = useLoad();
  const [celular, setCelular] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [trava, setTrava] = useState(false);
  async function fnEnviarSms() {
    setTrava(true);
    var obj = {
      userName: storage.get("USUARIO")?.userName,
      email: storage.get("USUARIO")?.email,
      celular: celular,
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        "duplo-fator/deslogado/enviar/sms",
        obj
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        const atualizado = Object.assign(storage.get("USUARIO"), {
          phoneNumber: data.data.phoneNumber,
        });

        storage.set("USUARIO", atualizado);

        // navigate("/celular-verificacao");
        navigate("/celular-verificado");
      } else {
        toast.error(data.message);
      }
      setTrava(false);
    } catch (err) {
      console.error("err", err);
      setTrava(false);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground">
        <div className="d-flex justify-content-center">
          <div
            className="imgBackgroundInterno px-3 "
            style={{ overflow: "auto" }}
          >
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <Link to={"/cpf-cadastro"}>
                  <img className="imgVoltar" src={voltar} alt="" />
                </Link>
                <div className="celularCadastroTexto1">Muito obrigado!</div>
                <div className="celularCadastroTexto2">
                  <div style={{ marginBottom: 10 }}>
                    Identificamos seu pré-cadastro.
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    Precisamos agora confirmar alguns dados, tudo bem?
                  </div>
                </div>
                <label>Celular</label>
                <form autoComplete="off">
                  <input
                    autoComplete="off"
                    className="inputPrimary"
                    placeholder="(00) 00000-0000"
                    maxLength={15}
                    inputMode="numeric"
                    type="text"
                    value={Mask.cel(celular)}
                    onChange={(e) => {
                      setCelular(Mask.unMask(e.target.value));
                    }}
                  />
                </form>
                {/* <div className="celularCadastroTexto3">
                  Vamos enviar por SMS um código de validação para seu número de
                  telefone
                </div> */}
              </div>
              <div>
                <button
                  onClick={fnEnviarSms}
                  disabled={celular.length < 11 || trava}
                  className="btn btn-primary w-100 "
                  style={{ marginTop: 20 }}
                >
                  Próximo passo
                </button>
                <div className="celularCadastroTexto4">
                  Se não reconhece esse número, entre em contato com seu gerente
                  para maiores informações.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
