import React, { useEffect } from "react";
import "./index.css";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import storage from "../../services/storage";

export default function Footer() {
  const vendedor =
    storage.get("USUARIO")?.profile?.role?.roleId ==
    "BD599436-282A-4B76-B419-4D767551DBD6";
  const locate = useLocation();
  const url = locate.pathname;

  return (
    <>
      {!url.includes("girar-roleta") && (
        <div className="d-flex justify-content-center">
          <div className="divFooterExterna px-3">
            <div className="divFooter vidroPreto">
              {/* CASINHA */}
              <Link to={"/home"} title="Home">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.739"
                  height="15.833"
                  viewBox="0 0 14.739 15.833"
                >
                  <g
                    id="Grupo_7932"
                    data-name="Grupo 7932"
                    transform="translate(0.75 0.825)"
                    opacity={url.includes("home") ? "1" : "0.3"}
                  >
                    <g
                      id="Grupo_7380"
                      data-name="Grupo 7380"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Caminho_7844"
                        data-name="Caminho 7844"
                        d="M7.037.169a.6.6,0,0,0-.833,0L.013,6.2a.259.259,0,0,1-.006.037A.2.2,0,0,0,0,6.276v7.113A.829.829,0,0,0,.257,14a.84.84,0,0,0,.611.258H4.883V10.783a1.737,1.737,0,1,1,3.473,0v3.474h4.015A.836.836,0,0,0,12.982,14a.823.823,0,0,0,.257-.61V6.276a.184.184,0,0,0-.012-.078Z"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="1.5"
                      />
                    </g>
                  </g>
                </svg>

                <div
                  className={
                    url.includes("home")
                      ? "menuSelecionado"
                      : "menuNaoSelecionado"
                  }
                ></div>
              </Link>
              {/* FOGUETE / HEDSET*/}
              {vendedor && (
                <Link to={"/quiz"} title="Quiz">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.748"
                    height="16.754"
                    viewBox="0 0 16.748 16.754"
                  >
                    <g
                      id="Grupo_7986"
                      data-name="Grupo 7986"
                      transform="translate(0.253 0.25)"
                      opacity={url.includes("quiz") ? "1" : "0.3"}
                    >
                      <g
                        id="Grupo_7978"
                        data-name="Grupo 7978"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Caminho_8155"
                          data-name="Caminho 8155"
                          d="M49.6,12.008c-.339.144-.654.372-.928-.058-.135.174-.259.335-.383.494a3.493,3.493,0,0,1-2.428,1.307,7.594,7.594,0,0,1-.857.06.338.338,0,0,1-.386-.35,4.131,4.131,0,0,1,.789-2.732,10.937,10.937,0,0,1,1.032-.993c-.4-.246-.158-.574-.026-.9A7.1,7.1,0,0,0,44.7,8.234a7.6,7.6,0,0,0-2.1-.133.343.343,0,0,1-.367-.161.354.354,0,0,1,.063-.427,6.057,6.057,0,0,1,3.655-2.177,5.987,5.987,0,0,1,2.407.032.184.184,0,0,0,.215-.086A24.614,24.614,0,0,1,51.1,2.313,7.741,7.741,0,0,1,55.014.149a6.6,6.6,0,0,1,2.848.012.476.476,0,0,1,.406.4,6.72,6.72,0,0,1,.11,2.222A7.681,7.681,0,0,1,56.01,7.435a24.828,24.828,0,0,1-2.845,2.417.222.222,0,0,0-.1.258,6.045,6.045,0,0,1-2,5.906c-.059.054-.123.1-.186.154a.333.333,0,0,1-.385.035.323.323,0,0,1-.167-.347,7.213,7.213,0,0,0-.4-3.077c-.1-.256-.209-.5-.321-.773m4.078-10.7a7.159,7.159,0,0,0-1.967,1.363,24.519,24.519,0,0,0-3.5,4.322,15.227,15.227,0,0,0-1.246,2.3q1.062,1.06,2.125,2.116a.176.176,0,0,0,.159.01c.495-.249,1-.486,1.476-.764a24.863,24.863,0,0,0,4.468-3.37,8.624,8.624,0,0,0,1.857-2.372A.2.2,0,0,0,57,4.635Q55.6,3.24,54.2,1.836c-.174-.174-.345-.351-.518-.527m.7-.307,3.028,3.028c-.007.01,0,.006,0,0A6,6,0,0,0,57.64.885c-.008-.04-.081-.087-.131-.1A5.609,5.609,0,0,0,55.457.76c-.364.059-.721.16-1.08.242m-1.934,9.4-2.227,1.343a7.978,7.978,0,0,1,.818,3.377,5.525,5.525,0,0,0,1.409-4.72M48.015,6.01A5.369,5.369,0,0,0,43.355,7.4a7.873,7.873,0,0,1,3.333.81l1.328-2.2m-1.048,4.271a3.09,3.09,0,0,0-1.646,2.835,3.107,3.107,0,0,0,2.818-1.663l-1.172-1.172"
                          transform="translate(-42.179 0)"
                          fill="#fff"
                          stroke="#fff"
                          strokeWidth="0.5"
                        />
                        <path
                          id="Caminho_8161"
                          data-name="Caminho 8161"
                          d="M303.777,111.538a1.724,1.724,0,1,1,1.711,1.737,1.713,1.713,0,0,1-1.711-1.737m2.764.015a1.042,1.042,0,1,0-1.051,1.037,1.031,1.031,0,0,0,1.051-1.037"
                          transform="translate(-294.757 -106.04)"
                          fill="#fff"
                          stroke="#fff"
                          strokeWidth="0.5"
                        />
                      </g>
                    </g>
                  </svg>

                  <div
                    className={
                      url.includes("quiz")
                        ? "menuSelecionado"
                        : "menuNaoSelecionado"
                    }
                  ></div>
                </Link>
              )}
              {!vendedor && (
                <Link to={"/fale-conosco"} title="Fale conosco">
                  <svg
                    fill="#ffffff"
                    width="25px"
                    height="25px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    opacity={url.includes("fale-conosco") ? "1" : "0.3"}
                  >
                    <path d="M10.1907 18.5V17.1736H8.13445C7.29051 17.1052 6.48827 16.7892 5.83501 16.2677C5.18175 15.7462 4.70862 15.0441 4.47891 14.2554L4.28471 13.9016H3.88489C3.71759 13.8803 3.56365 13.8019 3.45079 13.6806C3.34793 13.5546 3.29163 13.399 3.29086 13.2384C3.29126 13.1221 3.32274 13.0079 3.38225 12.9068C3.44157 12.8051 3.52847 12.721 3.63357 12.6636L3.97627 12.4757V11.2156C3.99031 11.1228 4.0252 11.0342 4.0785 10.9559C4.13179 10.8776 4.20218 10.8115 4.28471 10.7624C4.34794 10.702 4.42288 10.6543 4.50522 10.6221C4.58756 10.5898 4.67567 10.5737 4.7645 10.5745H11.6186C11.7095 10.5714 11.8001 10.5864 11.8847 10.6187C11.9693 10.6509 12.0461 10.6996 12.1104 10.7619C12.1747 10.8241 12.2251 10.8984 12.2584 10.9803C12.2917 11.0622 12.3072 11.1498 12.304 11.2377V14.5538H9.44815C9.38334 14.385 9.27287 14.2361 9.12829 14.1227C8.98921 14.0059 8.819 13.9293 8.63708 13.9016C8.45145 13.88 8.26316 13.9028 8.08875 13.968C7.91684 14.035 7.76685 14.1458 7.65466 14.2885C7.54539 14.4305 7.47824 14.5987 7.46046 14.7749C7.43553 14.9519 7.46327 15.1321 7.54042 15.2944C7.61569 15.4607 7.73858 15.6026 7.89455 15.7034C8.0455 15.8076 8.22356 15.8689 8.40861 15.8803H13.6292C13.9918 15.8774 14.3388 15.7367 14.5952 15.4886C14.8516 15.2404 14.997 14.9047 15 14.5538V13.2274C15.0002 13.0521 14.9639 12.8785 14.8933 12.7171C14.8226 12.5556 14.719 12.4096 14.5888 12.2878C14.464 12.1641 14.3147 12.066 14.1498 11.9995C13.9849 11.933 13.8078 11.8995 13.6292 11.9009V10.5745C13.5647 9.20775 12.9583 7.91766 11.9359 6.97253C10.9136 6.02741 9.55409 5.5 8.14016 5.5C6.72623 5.5 5.36672 6.02741 4.34439 6.97253C3.32206 7.91766 2.7156 9.20775 2.65114 10.5745V11.8678C2.26166 12.214 2.02791 12.6942 2 13.2053C2.00247 13.6047 2.13876 13.9925 2.3884 14.3106C2.62582 14.6192 2.95148 14.8536 3.32513 14.9849C3.68436 15.9613 4.33125 16.8145 5.18592 17.4392C6.04059 18.0638 7.06562 18.4326 8.13445 18.5H10.1907Z" />
                  </svg>

                  <div
                    className={
                      url.includes("fale-conosco")
                        ? "menuSelecionado"
                        : "menuNaoSelecionado"
                    }
                  ></div>
                </Link>
              )}
              {/* MAIS */}
              {vendedor && (
                <Link to={"/vendas"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37.423"
                    height="37.423"
                    viewBox="0 0 37.423 37.423"
                  >
                    <g
                      id="Grupo_7930"
                      data-name="Grupo 7930"
                      transform="translate(-0.476 -0.423)"
                    >
                      <path
                        id="Caminho_7988"
                        data-name="Caminho 7988"
                        d="M18.711,0A18.711,18.711,0,1,1,0,18.711,18.711,18.711,0,0,1,18.711,0Z"
                        transform="translate(0.476 0.423)"
                        fill="#00dd10"
                      />
                      <path
                        id="Caminho_7987"
                        data-name="Caminho 7987"
                        d="M9.891-23.71h5.477v2.361H9.891v6.206H7.381v-6.206H1.9V-23.71H7.381v-5.733H9.891Z"
                        transform="translate(10.071 41.366)"
                        fill="#f1f9ff"
                        stroke="#f1f9ff"
                        strokeWidth="1"
                      />
                    </g>
                  </svg>
                </Link>
              )}
              {/* INTERROGACAO */}
              <Link to={"/faq"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9.699"
                  height="16.017"
                  viewBox="0 0 9.699 16.017"
                  style={{ marginLeft: 2.5 }}
                >
                  <path
                    id="Caminho_8151"
                    data-name="Caminho 8151"
                    d="M-3.1-9.092a6.476,6.476,0,0,1,.366-2.4,4.929,4.929,0,0,1,1.34-1.762A6.852,6.852,0,0,0-.1-14.669a2.217,2.217,0,0,0,.323-1.148q0-1.822-1.679-1.822a1.7,1.7,0,0,0-1.276.491,1.929,1.929,0,0,0-.5,1.354H-6.351a4.152,4.152,0,0,1,1.329-3.222A5.176,5.176,0,0,1-1.453-20.18a5.2,5.2,0,0,1,3.541,1.1,3.929,3.929,0,0,1,1.259,3.119,3.8,3.8,0,0,1-.409,1.729,7.124,7.124,0,0,1-1.432,1.8l-.872.83A2.956,2.956,0,0,0-.3-9.75l-.043.657Zm-.312,3.3a1.567,1.567,0,0,1,.49-1.186,1.744,1.744,0,0,1,1.254-.467,1.744,1.744,0,0,1,1.254.467,1.567,1.567,0,0,1,.49,1.186A1.554,1.554,0,0,1-.4-4.624a1.747,1.747,0,0,1-1.265.461,1.747,1.747,0,0,1-1.265-.461A1.554,1.554,0,0,1-3.412-5.793Z"
                    transform="translate(6.351 20.18)"
                    fill="#fff"
                    opacity={url.includes("faq") ? "1" : "0.3"}
                  />
                </svg>

                <div
                  className={
                    url.includes("faq")
                      ? "menuSelecionado"
                      : "menuNaoSelecionado"
                  }
                ></div>
              </Link>
              {/* USUARIO */}
              <Link to={"/meu-cadastro"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.166"
                  height="16.673"
                  viewBox="0 0 15.166 16.673"
                >
                  <g
                    id="Grupo_7931"
                    data-name="Grupo 7931"
                    transform="translate(1.019 1)"
                    opacity={url.includes("meu-cadastro") ? "1" : "0.3"}
                  >
                    <g
                      id="Grupo_4125"
                      data-name="Grupo 4125"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Caminho_1955"
                        data-name="Caminho 1955"
                        d="M17.112,5.168A3.668,3.668,0,1,1,13.444,1.5,3.668,3.668,0,0,1,17.112,5.168Z"
                        transform="translate(-6.88 -1.5)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                      <path
                        id="Caminho_1956"
                        data-name="Caminho 1956"
                        d="M13.123,31.8A1.491,1.491,0,0,0,14.5,29.722a7.045,7.045,0,0,0-12.873,0A1.491,1.491,0,0,0,3,31.8Z"
                        transform="translate(-1.5 -17.128)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                      />
                    </g>
                  </g>
                </svg>

                <div
                  className={
                    url.includes("meu-cadastro")
                      ? "menuSelecionado"
                      : "menuNaoSelecionado"
                  }
                ></div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
