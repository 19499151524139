import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import ForcaSenha from "../../Components/ForcaSenha";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import moment from "moment";

export default function Senha() {
  const { load, setLoad } = useLoad();
  const [validacao, setValidacao] = useState(false);
  const [trava, setTrava] = useState(false);
  const [senha, setSenha] = useState({
    senha: storage.get("USUARIO").senha || "",
    confirmarSenha: storage.get("USUARIO").confirmarSenha || "",
    termo: storage.get("USUARIO").termo || false,
  });
  const navigate = useNavigate();

  async function fnEnviarCadastro() {
    setTrava(true);
    var obj = {
      userName: storage.get("USUARIO")?.userName,
      email: storage.get("USUARIO")?.email,
      nome: storage.get("USUARIO")?.nome,
      roleId: storage.get("USUARIO")?.roleId,
      dataNascimento: moment(storage.get("USUARIO")?.data, "DDMMYYYY").format(
        "YYYY-MM-DD"
      ),
      phoneNumber: storage.get("USUARIO")?.phoneNumber,
      senha: senha.senha,
      aceiteRegulamento: senha.termo,
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPost("pre-cadastro/concluir", obj);
      const data = await response.json();

      if (data.statusCode === 200) {
        toast.success(data.data);
        const atualizado = Object.assign(storage.get("USUARIO"), obj);
        storage.set("USUARIO", atualizado);
        navigate("/finalizado");
      } else {
        toast.error(data.message);
      }
      setTrava(false); 
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    const atualizado = Object.assign(storage.get("USUARIO"), senha);
    storage.set("USUARIO", atualizado);
  }, [senha]);
  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link to={"/sobre"}>
                <img className="imgVoltar" src={voltar} alt="" />
              </Link>
              <div className="senhaTexto1">E pra terminar :)</div>
              <div className="senhaTexto2"> Vamos cadastrar sua senha:</div>
              <label>Senha</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  className="inputPrimary"
                  type="password"
                  value={senha.senha}
                  onChange={(e) => {
                    setSenha((prev) => ({
                      ...prev,
                      senha: e.target.value,
                    }));
                  }}
                />
              </form>

              <label>Confirmar senha</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  className="inputPrimary"
                  type="password"
                  value={senha.confirmarSenha}
                  onChange={(e) => {
                    setSenha((prev) => ({
                      ...prev,
                      confirmarSenha: e.target.value,
                    }));
                  }}
                />
              </form>
              <br />
              <ForcaSenha
                senha={senha.senha}
                confirmarSenha={senha.confirmarSenha}
                setValidacao={setValidacao}
              />
              <div className="senhaTexto3">
                <span>
                  Aceito os 
                  <u>
                    <b
                      onClick={() => {
                        navigate("/termo");
                      }}
                      className="pointer"
                    >
                      Termos e Condições 
                    </b>
                  </u>
                </span>
                <div className="switch-container">
                  <label className="switch-label">
                    <input
                      type="checkbox"
                      checked={senha.termo}
                      onChange={(e) => {
                        setSenha((prev) => ({
                          ...prev,
                          termo: e.target.checked,
                        }));
                      }}
                      className="switch-input"
                    />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <button
              disabled={!validacao || !senha.termo || trava}
              onClick={fnEnviarCadastro}
              className="btn btn-primary w-100 btnSalvarSenha"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
