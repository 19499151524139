import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";

import bandeira from "../../assets/images/png/bandeira.png";
import ok from "../../assets/images/png/ok.png";
import atual from "../../assets/images/png/atual.png";
import bloqueado from "../../assets/images/png/bloqueado.png";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import ApiServices from "../../services/apiServices";
import BarraProgressoMissaoDinamica from "../../Components/BarraProgressoMissaoDinamica";
import moment from "moment";
import { toast } from "react-toastify";
export default function Missoes() {
  const { load, setLoad } = useLoad();
  const [missaoAtual, setMissaoAtual] = useState(1);
  const [missao, setMissao] = useState([]);
  const [mesSelecionado, setMesSelecionado] = useState(moment().format("M"));
  const [meses, setMeses] = useState([
    { mesId: 1, mesNome: "Janeiro" },
    { mesId: 2, mesNome: "Fevereiro" },
    { mesId: 3, mesNome: "Março" },
    { mesId: 4, mesNome: "Abril" },
    { mesId: 5, mesNome: "Maio" },
    { mesId: 6, mesNome: "Junho" },
    { mesId: 7, mesNome: "Julho" },
    { mesId: 8, mesNome: "Agosto" },
    { mesId: 9, mesNome: "Setembro" },
    { mesId: 10, mesNome: "Outubro" },
    { mesId: 11, mesNome: "Novembro" },
    { mesId: 12, mesNome: "Dezembro" },
  ]);
  async function fnMissoes() {
    const parametros = {
      ano: 2024,
      mes: mesSelecionado,
    };
    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      const response = await ApiServices.apiGet(
        "missao/meu-resumo-ano-mes?" + queryString
      );

      if (response.statusCode === 200) {
        setMissao(response.data.missoes);

        var atual = null;
        var encontrouSequencia = false;

        for (let i = response.data.missoes.length - 1; i >= 0; i--) {
          if (response.data.missoes[i].atual) {
            if (!encontrouSequencia) {
              // Inicia uma nova sequência de 'true'
              atual = response.data.missoes[i];
              encontrouSequencia = true;
            }
          } else {
            if (encontrouSequencia) {
              // Se encontrou uma sequência de 'true' seguida de 'false', pare
              break;
            }
          }
        }

        setMissaoAtual(
          atual.numero === 17 && atual.completou ? 18 : atual.numero
        );
      } else {
        toast.error(response.message);
        setMissao([]);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnMissoes();
  }, [mesSelecionado]);
  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          <HeaderAvatarMenu titulo={"Missões"} imagem={bandeira} />
          <div style={{ paddingBottom: 80 }}>
            <div className="d-flex flex-column mb-5">
              <label className="m-0">Mês</label>
              <select
                className="w-100"
                value={mesSelecionado}
                onChange={(e) => {
                  setMesSelecionado(e.target.value);
                }}
                style={{ width: 150 }}
              >
                <option value={""}>Selecione</option>
                {meses?.map((mes, i) => (
                  <option key={i} value={mes.mesId}>
                    {mes.mesNome}
                  </option>
                ))}
              </select>
            </div>

            {missao?.length ? (
              missao.map((e, i) => (
                <div key={i}>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div
                      className="d-flex"
                      style={{ opacity: missaoAtual >= i + 1 ? 1 : 0.3 }}
                    >
                      <div
                        className="nomeCardPerfil fw-light me-1"
                        style={{ fontSize: 18 }}
                      >
                        Missão 
                      </div>
                      <div className="nomeCardPerfil" style={{ fontSize: 18 }}>
                        {e.numero}
                      </div>
                    </div>
                    <div style={{ minHeight: 27 }}>
                      {missaoAtual == i + 1 && <img src={atual} alt="" />}
                      {missaoAtual < i + 1 && <img src={bloqueado} alt="" />}
                      {missaoAtual > i + 1 && <img src={ok} alt="" />}
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ opacity: missaoAtual >= i + 1 ? 1 : 0.3 }}
                  >
                    <div className=" pe-2">
                      {e.qtdCadastroMeta > 0 && (
                        <div className="filialCardPerfilMissoes my-2 fw-bolder">
                          Cadastro
                        </div>
                      )}
                      {e.qtdQuizMeta > 0 && (
                        <div className="filialCardPerfilMissoes my-2 fw-bolder">
                          Quiz
                        </div>
                      )}
                      {e.qtdEnqueteMeta > 0 && (
                        <div className="filialCardPerfilMissoes my-2 fw-bolder">
                          Enquete
                        </div>
                      )}
                      {e.qtdVendasMeta > 0 && (
                        <div className="filialCardPerfilMissoes my-2 fw-bolder">
                          Vendas
                        </div>
                      )}
                    </div>

                    <div className=" d-flex flex-column justify-content-around pe-2 w-100">
                      {e.qtdCadastroMeta > 0 && (
                        <div className="my-2">
                          <BarraProgressoMissaoDinamica
                            meta={e.qtdCadastroMeta}
                            progresso={e.qtdCadastroFeito}
                          />
                        </div>
                      )}
                      {e.qtdQuizMeta > 0 && (
                        <div className="my-2">
                          <BarraProgressoMissaoDinamica
                            meta={e.qtdQuizMeta}
                            progresso={e.qtdQuizFeito}
                          />
                        </div>
                      )}
                      {e.qtdEnqueteMeta > 0 && (
                        <div className="my-2">
                          <BarraProgressoMissaoDinamica
                            meta={e.qtdEnqueteMeta}
                            progresso={e.qtdEnqueteFeito}
                          />
                        </div>
                      )}

                      {e.qtdVendasMeta > 0 && (
                        <div className="my-2">
                          <BarraProgressoMissaoDinamica
                            meta={e.qtdVendasMeta}
                            progresso={e.qtdVendasFeito}
                          />
                        </div>
                      )}
                    </div>

                    <div className=" ps-2">
                      {e.qtdCadastroMeta > 0 && (
                        <div className="filialCardPerfilMissoes my-2 fw-bolder">
                          {e.qtdCadastroFeito}/{e.qtdCadastroMeta}
                        </div>
                      )}
                      {e.qtdQuizMeta > 0 && (
                        <div
                          className="filialCardPerfilMissoes my-2 fw-bolder"
                          style={{ margin: "10px 0" }}
                        >
                          {e.qtdQuizFeito}/{e.qtdQuizMeta}
                        </div>
                      )}
                      {e.qtdEnqueteMeta > 0 && (
                        <div
                          className="filialCardPerfilMissoes my-2 fw-bolder"
                          style={{ margin: "10px 0" }}
                        >
                          {e.qtdEnqueteFeito}/{e.qtdEnqueteMeta}
                        </div>
                      )}
                      {e.qtdVendasMeta > 0 && (
                        <div className="filialCardPerfilMissoes my-2 fw-bolder">
                          {e.qtdVendasFeito}/{e.qtdVendasMeta}
                        </div>
                      )}
                    </div>
                  </div>
                  {missao.length - 2 >= i && <hr />}
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center">
                <div style={{ maxWidth: 300, textAlign: "center" }}>
                  Sem histórico de missões
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
