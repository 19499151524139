import globals from "../globals";
import storage from "./storage";

const APIBASE = globals.REACT_APP_APIBASE;
const ENDERECO = globals.REACT_APP_APIENDERECO;
const ROLETA = globals.REACT_APP_ROLETA;
const NOTIFICACAO = globals.REACT_APP_NOTIFICACAO;

function getToken() {
  return storage.get("USUARIO")?.token;
}
function getTokenRoleta() {
  return storage.get("ROLETATOKEN");
}

const ApiServices = {
  apiGet: (parameter = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${APIBASE}${parameter}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          captcha: storage.get("captcha"),
        },
      }).then((res) => res.json());
    } else {
    }
  },
  apiPost: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
          captcha: storage.get("captcha"),
        },
        body: JSON.stringify(obj),
      });
    } else {
    }
  },
  apiPatch: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },
  apiPut: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      });
    } else {
    }
  },
  apiDel: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(obj),
      });
    } else {
    }
  },

  apiGetEndereco: (parameter = undefined) => {
    if (parameter) {
      //alert('sem captcha');
      return fetch(`${ENDERECO}${parameter}`, {
        method: "GET",
      }).then((res) => res.json());
    } else {
    }
  },
  apiDownloadXlsxGET: (parameter = undefined, nomeArquivo = "relatorio") => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "GET",
        headers: {
          "content-type":
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          // Retorna o corpo da resposta como um Blob (objeto binário)
          return res.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = nomeArquivo + ".xlsx";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } else {
    }
  },
  apiUpload: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${APIBASE}${parameter}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken()}`,
          Accept: "multipart/form-data",
        },
        body: obj,
      }).then((res) => res.json());
    } else {
    }
  },
  //////////////////////////////     ROLETA     //////////////////////////////
  //////////////////////////////     ROLETA     //////////////////////////////
  //////////////////////////////     ROLETA     //////////////////////////////
  apiGetRoleta: (parameter = undefined) => {
    if (parameter) {
      return fetch(`${parameter}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          token: `${getTokenRoleta()}`,
        },
      });
    }
  },
  apiPostRoleta: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${ROLETA}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${getTokenRoleta()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    } else {
    }
  },
  apiRoletaPostToken: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          token: `${getTokenRoleta()}`,
        },
        body: JSON.stringify(obj),
      }).then((res) => res.json());
    }
  },
  //////////////////////////////     NOTIFICACAO     //////////////////////////////
  //////////////////////////////     NOTIFICACAO     //////////////////////////////
  //////////////////////////////     NOTIFICACAO     //////////////////////////////
  apiGetNotificacao: (parameter = undefined) => {
    if (parameter) {
      return fetch(`${NOTIFICACAO}${parameter}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
        },
      }).then((res) => res.json());
    } else {
    }
  },
  apiPostNotificacao: (parameter = undefined, obj = undefined) => {
    if (parameter) {
      return fetch(`${NOTIFICACAO}${parameter}`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(obj),
      });
    } else {
    }
  },
};

export default ApiServices;
