import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import checkOk from "../../assets/images/png/checkOk.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";

export default function EmailRecadastroVerificado() {
  const [tempo, setTempo] = useState(5);
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      if (tempo > 0) {
        setTempo(tempo - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    if (tempo === 0) {
      navigate("/celular-cadastro");
    }

    return () => {
      clearInterval(interval);
    };
  }, [tempo]);

  return (
    <>
      <div
        className="imgBackground"
        onClick={() => {
          navigate("/celular-cadastro");
        }}
      >
        <div className="imgBackgroundInterno px-3 " style={{ overflow: "auto" }}>
          <div className="d-flex flex-column justify-content-between h-100">
            <Link onClick={() => window.history.back()} className="invisible ">
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <img className="imgCheckOk" src={checkOk} alt="" />
          </div>
          <div className="d-flex  justify-content-center tempoCheck ">
            <div className="text-center fs-1 mt-2 numeroContadorFinalizar">
              <b className="mt-1">{tempo}</b>
            </div>
          </div>
        </div>       
      </div>
    </>
  );
}
