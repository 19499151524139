import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import lupa from "../../assets/images/png/lupa.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import { toast } from "react-toastify";
import moment from "moment";
import storage from "../../services/storage";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import Logo from "../../assets/images/png/logo.png";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
export default function FaleConoscoDetalhe() {
  const { load, setLoad } = useLoad();
  const [trava, setTrava] = useState(false);
  const [historico, setHistorico] = useState([]);
  const [historicoLista, setHistoricoLista] = useState([]);
  const idUsuario = storage.get("USUARIO").profile.userId;
  const refScroll = useRef(null);
  const [enviado, setEnviado] = useState(false);
  const [mensagem, setMensagem] = useState("");
  


  const navigate = useNavigate();

  const handleToggle = (id) => {
    setHistoricoLista(prevState =>
      prevState.map(item =>
        item.faleConoscoId === id ? { ...item, expanded: !item.expanded } : { ...item, expanded: false }
      )
    );
  };

  async function fnEnviar(id) {
    setTrava(true);
    setLoad(true);
    try {

      var objEnvio = {
        mensagem: mensagem,
        anexo: null,
        respostaParticipante: true
      };

      let response = await ApiServices.apiPost(
        "faleconosco/interacao/" + id,
        objEnvio
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success("Enviado com sucesso");
        setEnviado(true);
        navigate("/fale-conosco");
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setTrava(false);
      setLoad(false);
    }
  }

  async function fnListarHistorico() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet(
        "faleconosco?participanteId=" +
          storage.get("USUARIO")?.profile?.userId +
          "&pageSize=9999"
      );
      const promises = response.data.items.map(async (e) => {
        return await fnListarHistoricoId(e.faleConoscoId);
      });
      const results = await Promise.all(promises);

      results.forEach(element => {
        element.expanded = false;
      });

      setHistoricoLista(results);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnListarHistoricoId(id) {
    
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("faleconosco/" + id);
      return response.data;
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarHistorico();
  }, []);

  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          <HeaderAvatarMenu titulo={"Fale conosco"} />

          <div className="faleConoscoDetalheTexto1">Histórico</div>

          <div>
            <div className="d-flex justify-content-center flex-column align-items-center">
              <div
                className="d-flex flex-column align-items-center w-100"
                style={{ maxWidth: 500, paddingBottom: 80 }}
              >

                {historicoLista.sort((a, b) => new Date(b.dataHora) - new Date(a.dataHora)).map((e, i) => (
                  <div
                    className={`divCardFaleConoscoDetalhe overflow-hidden ${e.expanded ? '' : 'recolhido'}`}
                    key={i}
                  >
                    <div>
                      <div className="d-flex">
                        <div className="w-100">
                          <div className="d-flex">
                            <div className="faleConoscoDetalheTexto2 w-100 ">
                              Data de criação:
                            </div>
                            <div className="faleConoscoDetalheTexto3 w-100 ">
                              {moment(e.dataHora).format("DD/MM/YYYY")}
                            </div>
                          </div>
                          <div className="d-flex">
                          <div className="faleConoscoDetalheTexto2 w-100 ">
                              Motivo do contato:
                            </div>
                            <div className="faleConoscoDetalheTexto3 w-100 ">
                              {e.assunto}
                            </div>
                          </div>
                        </div>
                        <button
                              className={`btn ${e.status === "AguardandoParticipante" && !e.expanded ? 'btn-success' : 'btn-primary'}`}
                              onClick={() => {
                                handleToggle(e.faleConoscoId);
                              }}
                            >
                              {e.expanded ? <ExpandLessIcon /> : ( e.status === "AguardandoParticipante" ? <SendIcon /> : <ExpandMoreIcon />)}
                        </button>
                      </div>
                      <div className="mt-5 ml-3 mr-3 descerScroll" ref={refScroll}>
                        {e.respostas.map((e, i) => (
                          <div key={i} className="my-3">
                            {e.participanteId !== idUsuario && (
                              <>
                                <div className="nomeE">
                                  <span className="me-3">{e.nome}</span>
                                  <span>
                                    {moment(e.dataCriacao)
                                      .locale("pt")
                                      .format("DD/MM/YYYY - HH:mm:ss")}
                                  </span>
                                </div>

                                <div className="d-flex align-items-start">
                                  <div className="text-left me-2 mt-3">
                                    <img src={Logo} width={30} className="img1" />
                                  </div>

                                  <div className="pr-2 pl-1">
                                    <p className="msgE">{e.mensagem}</p>
                                  </div>
                                </div>
                              </>
                            )}

                            {e.participanteId === idUsuario && (
                              <>
                                <div className="nomeD">
                                  <span>
                                    {moment(e.dataCriacao)
                                      .locale("pt")
                                      .format("DD/MM/YYYY - HH:mm:ss")}
                                  </span>
                                  <span className="ms-3">{e.nome}</span>
                                </div>

                                <div className="d-flex align-items-start text-right justify-content-end ">
                                  <div className="pr-2 d-flex align-items-end flex-column ">
                                    <p className="msgD">
                                      {e.mensagem}
                                    </p>
                                  </div>

                                  <AccountCircleRoundedIcon
                                    className="mt-2 ms-2"
                                    style={{ color: "#00cdc4", fontSize:40 }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                      
                      {e.status !== "Encerrado" && e.status !== "Pendente" &&
                      <div
                        className="d-flex"
                        style={{ overflow: "hidden" }}
                        >
                          <textarea
                            className="faleConoscoTextAreaResposta"
                            placeholder="Escreva sua mensagem"
                            value={mensagem}
                            rows={1}
                            onChange={(e) => {
                              setMensagem(e.target.value);
                            }}
                          ></textarea>
                          <button
                            className="btn btn-success my-4"
                            onClick={() => {fnEnviar(e.faleConoscoId);}}
                            disabled={
                              mensagem === "" || trava
                            }
                          >
                            <SendIcon />
                          </button>
                        </div>
                      }
                      
                    </div>
                  </div>
                ))}
                {historicoLista.length == 0 && (
                  <div
                    className="d-flex justify-content-center"
                    style={{ margin: " 100px 0" }}
                  >
                    <h3 style={{ maxWidth: 200, textAlign: "center" }}>
                      Você não tem mais histórico!
                    </h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
