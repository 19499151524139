import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Footer from "../Pages/Footer";
import storage from "../services/storage";
import Login from "../Pages/Login";
import EsqueceuSenha from "../Pages/EsqueceuSenha";
import EsqueceuSenhaConfirmacao from "../Pages/EsqueceuSenhaConfirmacao";
import CelularVerificado from "../Pages/CelularVerificado";
import CelularVerificacao from "../Pages/CelularVerificacao";
import CelularCadastro from "../Pages/CelularCadastro";
import EmailCadastro from "../Pages/EmailCadastro";
import EmailVerificacao from "../Pages/EmailVerificacao";
import EmailVerificado from "../Pages/EmailVerificado";
import Sobre from "../Pages/Sobre";
import Senha from "../Pages/Senha";
import Termo from "../Pages/Termo";
import Finalizado from "../Pages/Finalizado";
import MeuCadastro from "../Pages/MeuCadastro";
import Regulamento from "../Pages/Regulamento";
import HomeVendedor from "../Pages/Home/HomeVendedor";
import RecriarSenha from "../Pages/RecriarSenha";
import CpfCadastro from "../Pages/CpfCadastro";
import MeuCadastroAvatar from "../Pages/MeuCadastroAvatar";
import NoticiaDetalhe from "../Pages/NoticiaDetalhe";
import CampanhaDetalhe from "../Pages/CampanhaDetalhe";
import EmailRecadastroLogin from "../Pages/EmailRecadastroLogin";
import EmailRecadastroEmail from "../Pages/EmailRecadastroEmail";
import EmailRecadastroVerificacao from "../Pages/EmailRecadastroVerificacao";
import EmailRecadastroVerificado from "../Pages/EmailRecadastroVerificado";
import FaleConosco from "../Pages/FaleConosco";
import FaleConoscoDetalhe from "../Pages/FaleConoscoDetalhe";
import Campanhas from "../Pages/Campanhas";
import Noticias from "../Pages/Noticias";
import EsqueceuSenhaConfirmacaoSucesso from "../Pages/EsqueceuSenhaConfirmacaoSucesso";
import RankingGeral from "../Pages/RankingGeral";
import Quiz from "../Pages/Quiz";
import Onboarding from "../Pages/Onboarding";
import QuizFinalizado from "../Pages/Quiz/QuizFinalizado";
import Enquete from "../Pages/Enquete";
import EnqueteFinalizado from "../Pages/Enquete/EnqueteFinalizado";
import Vendas from "../Pages/Vendas";
import VendaFinalizada from "../Pages/Vendas/VendaFinalizado";
import Missoes from "../Pages/Missoes";
import HistoricoDeRecompensas from "../Pages/HistoricoDeRecompensas";
import HistoricoDeNumeroDaSorte from "../Pages/HistoricoDeNumeroDaSorte";
import HistoricoDaRoleta from "../Pages/HistoricoDaRoletaDaSorte";
import HistoricoVendas from "../Pages/HistoricoDeVendas";
import Faq from "../Pages/Faq";
import ApiServices from "../services/apiServices";
import HistoricoDoQuiz from "../Pages/Quiz/HistoricoDoQuiz";
import HistoricoDaEnquete from "../Pages/Enquete/HistoricoDaEnquete";
import HomeGerente from "../Pages/Home/HomeGerente";
import MetaRoleta from "../Pages/Roleta/MetaRoleta";
import GirarRoletaUmaEstrela from "../Pages/Roleta/GirarRoletaUmaEstrela";
import Parabens from "../Pages/Roleta/Parabens";
import ParabensSaibaMais from "../Pages/Roleta/ParabensSaibaMais";
import { Notifications } from "react-push-notification";
import addNotification from "react-push-notification";
import axios from "axios";
import GirarRoletaDuasEstrela from "../Pages/Roleta/GirarRoletaDuasEstrelas";
import GirarRoletaTresEstrela from "../Pages/Roleta/GirarRoletaTresEstrelas";
import Recompensas from "../Pages/Roleta/Recompensas";
import SuaEscolha from "../Pages/Roleta/SuaEscolha";
import ResgateVoucher from "../Pages/Roleta/ResgateVoucher";
import FaleConoscoDeslogado from "../Pages/FaleConoscoDeslogado";
async function handleServiceWorker() {
  navigator.serviceWorker
    .register("service-worker.js")
    .then(async function (serviceWorker) {
      serviceWorker.update();
      let subscription = await serviceWorker.pushManager.getSubscription();
      if (!subscription) {
        async function fnPublicKey() {
          try {
            const response = await ApiServices.apiGetNotificacao(
              "notification/push/public_key"
            );
            await serviceWorker.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: response.publicKey,
            });
            subscription = await serviceWorker.pushManager.getSubscription();
          } catch (err) {
            console.error("err", err);
          } finally {
          }
        }
        fnPublicKey();
      }
      async function fnRegister() {
        var objEnvio = { subscription: subscription };
        try {
          const response = await ApiServices.apiPostNotificacao(
            "notification/push/register",
            objEnvio
          );
        } catch (err) {
          console.error("err", err);
        } finally {
        }
      }
      fnRegister();
      async function fnSend() {
        var objEnvio = { subscription: subscription };
        try {
          const response = await ApiServices.apiPostNotificacao(
            "notification/push/send",
            objEnvio
          );
        } catch (err) {
          console.error("err", err);
        } finally {
        }
      }
      fnSend();
    })
    .catch(function (error) {
      console.error("Erro ao lidar com service worker:", error);
    });
}
export default function Rotas() {
  const navigate = useNavigate();
  
  const PrivateRoutes = ({ children, redirectTo, dataLimite }) => {
    var autenticado = storage.get("USUARIO")?.token != null;
    fnEu();

    if(dataLimite && (dataLimite > new Date("2024-08-08T00:00:00"))){
      console.log("Hello");
      return <Navigate to={"/home"} />
    }


    return autenticado ? (
      <>
        {" "}
        {children} <Footer />
      </>
    ) : (
      <Navigate to={redirectTo} />
    );
  };

  async function fnEu() {
    try {
      const response = await ApiServices.apiGet("eu");
      if (response?.statusCode !== 200) {
        navigate("/login");
      }
    } catch (err) {
      navigate("/login");
    } finally {
    }
  }
  function permissaoParaNotificacao() {
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      handleServiceWorker();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          handleServiceWorker();
        }
      });
    }
  }
  useEffect(() => {
    // AQUI DESATIVA A NOTIFICACAO
    // permissaoParaNotificacao();
  }, []);
  return (
    <Routes>
      <Route exact path="/" element={<Onboarding />} />
      <Route exact path="/login" element={<Login />} />
      <Route
        exact
        path="/fale-conosco-deslogado"
        element={<FaleConoscoDeslogado />}
      />
      <Route exact path="/esqueci-minha-senha" element={<EsqueceuSenha />} />
      <Route
        exact
        path="/esqueci-minha-senha/confirmacao"
        element={<EsqueceuSenhaConfirmacao />}
      />
      <Route
        exact
        path="/esqueci-minha-senha/confirmacao/sucesso"
        element={<EsqueceuSenhaConfirmacaoSucesso />}
      />
      <Route exact path="/cpf-cadastro" element={<CpfCadastro />} />
      <Route exact path="/celular-cadastro" element={<CelularCadastro />} />
      <Route
        exact
        path="/celular-verificacao"
        element={<CelularVerificacao />}
      />
      <Route exact path="/celular-verificado" element={<CelularVerificado />} />
      <Route exact path="/email-cadastro" element={<EmailCadastro />} />
      <Route exact path="/email-verificacao" element={<EmailVerificacao />} />
      <Route exact path="/email-verificado" element={<EmailVerificado />} />
      <Route
        exact
        path="/email-recadastro-login"
        element={<EmailRecadastroLogin />}
      />
      <Route
        exact
        path="/email-recadastro-email"
        element={<EmailRecadastroEmail />}
      />
      <Route
        exact
        path="/email-recadastro-verificacao"
        element={<EmailRecadastroVerificacao />}
      />
      <Route
        exact
        path="/email-recadastro-verificado"
        element={<EmailRecadastroVerificado />}
      />
      <Route exact path="/alterar-senha" element={<RecriarSenha />} />
      <Route exact path="/sobre" element={<Sobre />} />
      <Route exact path="/senha" element={<Senha />} />
      <Route exact path="/termo" element={<Termo />} />
      <Route exact path="/finalizado" element={<Finalizado />} />
      <Route exact path="/*" element={<Onboarding />} />
      {storage.get("USUARIO")?.profile?.role?.roleId ==
        "BD599436-282A-4B76-B419-4D767551DBD6" && (
        <Route
          exact
          path="/home"
          element={
            <PrivateRoutes redirectTo={"/login"}>
              <HomeVendedor />
            </PrivateRoutes>
          }
        />
      )}
      {storage.get("USUARIO")?.profile?.role?.roleId ==
        "89B97424-23F8-455C-AD38-A9A2D9B1784B" && (
        <Route
          exact
          path="/home"
          element={
            <PrivateRoutes redirectTo={"/login"}>
              <HomeGerente />
            </PrivateRoutes>
          }
        />
      )}
      <Route
        exact
        path="/quiz"
        element={
          <PrivateRoutes dataLimite={new Date()} redirectTo={"/login"}>
            <Quiz />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/historico-do-quiz"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <HistoricoDoQuiz />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/quiz/finalizado"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <QuizFinalizado />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/enquete"
        element={
          <PrivateRoutes dataLimite={new Date()} redirectTo={"/login"}>
            <Enquete />
          </PrivateRoutes>
        }
      />

      <Route
        exact
        path="/historico-da-enquete"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <HistoricoDaEnquete />
          </PrivateRoutes>
        }
      />

      <Route
        exact
        path="/enquete/finalizado"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <EnqueteFinalizado />
          </PrivateRoutes>
        }
      />

      <Route
        exact
        path="/vendas"
        element={
          <PrivateRoutes dataLimite={new Date()} redirectTo={"/login"}>
            <Vendas />
          </PrivateRoutes>
        }
      />

      <Route
        exact
        path="/vendas/finallizada"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <VendaFinalizada />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/meta-roleta"
        element={
          <PrivateRoutes dataLimite={new Date()} redirectTo={"/login"}>
            <MetaRoleta />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/girar-roleta-uma-estrela"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <GirarRoletaUmaEstrela />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/girar-roleta-duas-estrela"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <GirarRoletaDuasEstrela />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/girar-roleta-tres-estrela"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <GirarRoletaTresEstrela />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/parabens"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <Parabens />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/recompensas"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <Recompensas />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/recompensas/sua-escolha"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <SuaEscolha />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/recompensa/sua-escolha/resgate-confirmado"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <ResgateVoucher />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/parabens/saiba-mais"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <ParabensSaibaMais />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/missoes"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <Missoes />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/historico-de-recompensas"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <HistoricoDeRecompensas />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/historico-do-numero-da-sorte"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <HistoricoDeNumeroDaSorte />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/historico-da-roleta-da-sorte"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <HistoricoDaRoleta />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/historico-de-vendas"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <HistoricoVendas />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/meu-cadastro"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <MeuCadastro />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/meu-cadastro/avatar"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <MeuCadastroAvatar />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/campanhas"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <Campanhas />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/campanha-detalhe/:id"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <CampanhaDetalhe />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/regulamento"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <Regulamento />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/noticia-detalhe/:id"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <NoticiaDetalhe />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/noticia"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <Noticias />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/noticia-detalhe"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <NoticiaDetalhe />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/faq"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <Faq />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/fale-conosco"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <FaleConosco />
          </PrivateRoutes>
        }
      />

      <Route
        exact
        path="/fale-conosco-historico"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <FaleConoscoDetalhe />
          </PrivateRoutes>
        }
      />
      <Route
        exact
        path="/ranking-geral"
        element={
          <PrivateRoutes redirectTo={"/login"}>
            <RankingGeral />
          </PrivateRoutes>
        }
      />
    </Routes>
  );
}
