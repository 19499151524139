import React, { useEffect, useState } from "react";
import Avatar from "../../Components/Avatar";
import estrelaCheia from "../../assets/images/png/estrelaCheia.png";
import estrelaVazia from "../../assets/images/png/estrelaVazia.png";
import roleta from "../../assets/images/png/roleta.png";
import numeroSorte from "../../assets/images/png/numeroSorte.png";
import presenteHome from "../../assets/images/png/presenteHome.png";
import dedoEsquerda from "../../assets/images/svg/dedoEsquerda.svg";

import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import { Link } from "react-router-dom";

export default function HeaderHomeVendedor({ usuario }) {
  const { load, setLoad } = useLoad();
  const [missaoAtual, setMissaoAtual] = useState({});
  const [missoes, setMissoes] = useState({});

  async function fnMissoes() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("missao/meu-resumo");
      setMissoes(response.data);
      var atual = null;
      var encontrouSequencia = false;

      for (let i = response.data.missoes.length - 1; i >= 0; i--) {
        if (response.data.missoes[i].atual) {
          if (!encontrouSequencia) {
            // Inicia uma nova sequência de 'true'
            atual = response.data.missoes[i];
            encontrouSequencia = true;
          }
        } else {
          if (encontrouSequencia) {
            // Se encontrou uma sequência de 'true' seguida de 'false', pare
            break;
          }
        }
      }
  
      setMissaoAtual(atual);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    fnMissoes();
  }, []);
  return (
    <div className="p-3 " style={{ position: "absolute", zIndex: 100, top: 0 }}>
      <Link to={"/meu-cadastro"}>
        {/* NOME */}
        <div
          className="bgAzul d-flex"
          style={{ width: 160, height: 70, paddingLeft: 30 }}
        >
          <div className="divAvatarNome ">
            <Avatar nome={usuario.nome} font={25} />
          </div>
          <div style={{ width: "90%" }}>
            {missoes.qtdEstrelas >= 1 ? (
              <img width={15} src={estrelaCheia} alt="" />
            ) : (
              <img width={15} src={estrelaVazia} alt="" />
            )}
            {missoes.qtdEstrelas >= 2 ? (
              <img width={15} src={estrelaCheia} alt="" />
            ) : (
              <img width={15} src={estrelaVazia} alt="" />
            )}
            {missoes.qtdEstrelas >= 3 ? (
              <img width={15} src={estrelaCheia} alt="" />
            ) : (
              <img width={15} src={estrelaVazia} alt="" />
            )}
            <div className="nomeCardPerfil tresPontinhos">{usuario.nome}</div>
            <div className="filialCardPerfil tresPontinhos">Concessionária</div>
            {usuario?.dealer?.nomeFantasia && (
              <div className="filialCardPerfil tresPontinhos ">
                Filial {usuario.dealer.nomeFantasia}
              </div>
            )}
          </div>
        </div>
      </Link>
      {/* ROLETA */}

      <Link
        to="/meta-roleta"
        className={
          "bgAzul d-flex align-items-center " +
          (missaoAtual?.podeCompletar &&
          missoes.qtdRoletaGirada !== missoes.qtdRoletaTotal
            ? "alertaRoleta"
            : "")
        }
        style={{
          width: 125,
          height: 20,
          marginTop: 20,
          padding: "20px 0px 16px 30px",
        }}
      >
        <div className="divHomeRoleta ">
          <img width={40} src={roleta} alt="" />
        </div>
        <div style={{ width: "90%" }}>
          <div className="filialCardPerfil mb-1">Roleta da sorte</div>
          <div className="nomeCardPerfil tresPontinhos">
            {missoes.qtdRoletaGirada} / {missoes.qtdRoletaTotal}
          </div>
        </div>
      </Link>
      {missaoAtual?.podeCompletar &&
        missoes.qtdRoletaGirada !== missoes.qtdRoletaTotal && (
          <img width={35} src={dedoEsquerda} alt="" className="dedoEsqueda" />
        )}

      {/* NUMERO DA SORTE */}

      <Link
        to="/historico-do-numero-da-sorte"
        className="bgAzul d-flex align-items-center"
        style={{
          width: 125,
          height: 20,
          marginTop: 20,
          padding: "20px 0px 16px 30px",
        }}
      >
        <div className="divHomeNumeroSorte">
          <img width={50} src={numeroSorte} alt="" />
        </div>
        <div style={{ width: "90%" }}>
          <div className="filialCardPerfil mb-1">Número da sorte</div>
          <div className="nomeCardPerfil tresPontinhos">
            {missoes.qtdNumeroSorteDisponivel}
          </div>
        </div>
      </Link>
      {/* RECOMPENSAS */}

      <Link
        to="/historico-de-recompensas"
        className="bgAzul d-flex align-items-center"
        style={{
          width: 125,
          height: 20,
          marginTop: 20,
          padding: "20px 0px 16px 30px",
        }}
      >
        <div className="divHomeRecompensas">
          <img width={45} src={presenteHome} alt="" />
        </div>
        <div style={{ width: "90%" }}>
          <div className="filialCardPerfil mb-1">Recompensas</div>
          <div className="nomeCardPerfil tresPontinhos">
            {missoes.qtdRecompensa}
          </div>
        </div>
      </Link>
    </div>
  );
}
