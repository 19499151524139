import React from "react";
import "./index.css";

export default function BarraProgresso({ progresso = 0 }) {
  return (
    <div className="barExterno">
      {progresso > 0 && (
        <div
          className="barInterno "
          style={{ width: `${Math.max(progresso, 1)}%` }}
        >
          <p>
            {progresso.toFixed(2) === "Infinity" ? "" : progresso.toFixed(2)}%
          </p>
        </div>
      )}
    </div>
  );
}
