import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanha from "../../assets/images/png/campanha.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import moment from "moment";

export default function Noticias() {
  const { load, setLoad } = useLoad();
  const [noticia, setNoticia] = useState([]);
  const [recente, setRecente] = useState(false);

  const navigate = useNavigate();

  async function fnNoticia() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("noticia");

      const compararPorDataInicio = (a, b) => {
        const dataInicioA = new Date(a.dataInicio);
        const dataInicioB = new Date(b.dataInicio);

        return recente ? dataInicioB - dataInicioA : dataInicioA - dataInicioB;
      };

      // Ordenar a lista de objetos usando a função de comparação
      response.data.sort(compararPorDataInicio);

      setNoticia(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnNoticia();
  }, [recente]);
  return (
    <>
      <div className="imgBackground"> </div>

      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link to={"/home"}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">Institucional</div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ maxWidth: 500 }}
        >
          <div className="d-flex justify-content-around w-100 my-5">
            <button
              style={{ width: 200 }}
              className={
                "btn " + (!recente ? "btn-primary " : " btn-secondary  ")
              }
              onClick={() => {
                recente && setRecente(!recente);
              }}
            >
              Mais recente
            </button>
            <button
              style={{ width: 200 }}
              className={
                "btn " + (recente ? "btn-primary " : " btn-secondary  ")
              }
              onClick={() => {
                !recente && setRecente(!recente);
              }}
            >
              Mais antiga
            </button>
          </div>
          <div className="px-3">
            <div className="institucionalTexto1">Sodre nós</div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center px-3 ">
        <div className="d-flex flex-column w-100" style={{ maxWidth: 500 }}>
          {noticia.map((e, i) => (
            <div className="px-3" key={i}>
              <div className="institucionalTexto2">
                {moment(e.dataInicio).format("DD/MM/YYYY")}
              </div>
              <div className="institucionalTexto3">{e.titulo}</div>
              <div className="institucionalTexto4">{e.descricao}</div>
              <button
                className=" btn-intitucional"
                onClick={() => {
                  navigate("/noticia-detalhe/" + e.noticiaId);
                }}
              >
                Ver mais
              </button>
              {noticia.length != i + 1 && <hr />}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
