import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanha from "../../assets/images/png/campanha.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import moment from "moment";
import smileUser from "../../assets/images/svg/smileUser.svg";
import smileFeliz from "../../assets/images/svg/smileFeliz.svg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from "react-toastify";
import Avatar from "../../Components/Avatar";
import HeaderHomeVendedor from "../Home/HeaderHomeVendedor";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
export default function Quiz() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [quizSelecionado, setQuizSelecionado] = useState(false);
  const [quizMeta, setQuizMeta] = useState(0);
  const [quiz, setQuiz] = useState([]);

  async function fnQuiz() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("quiz/proxima-pergunta-quiz");
      var resp = response?.data?.pergunta?.respostas || [];
      for (let i = resp?.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [resp[i], resp[j]] = [resp[j], resp[i]];
      }
      setQuiz(response.data);
      fnQuizMeta();
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnQuizMeta() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("missao/meu-resumo");

      var atual = null;
      var encontrouSequencia = false;

      for (let i = response.data.missoes.length - 1; i >= 0; i--) {
        if (response.data.missoes[i].atual) {
          if (!encontrouSequencia) {
            // Inicia uma nova sequência de 'true'
            atual = response.data.missoes[i];
            encontrouSequencia = true;
          }
        } else {
          if (encontrouSequencia) {
            // Se encontrou uma sequência de 'true' seguida de 'false', pare
            break;
          }
        }
      }

      setQuizMeta(atual);

      if (atual.qtdQuizFeito >= atual.qtdQuizMeta) {
        setQuiz(null);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnQuizEnviar() {
    var listaSelecionado = [];
    quiz?.pergunta?.respostas?.map((e, i) => {
      e.selecionada == true &&
        listaSelecionado.push({
          quizRespostaId: e.quizRespostaId,
          selecionada: e.selecionada,
        });
    });

    var objEnvio = {
      quizPerguntaId: quiz?.pergunta?.quizPerguntaId,
      respostas: listaSelecionado,
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "quiz/responder-pergunta",
        objEnvio
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        data.data.status === "correta"
          ? toast.success("Sua resposta está correta!")
          : toast.error("Sua resposta está incorreta!");
      } else {
        toast.warn(data.message ? data.message : data);
      }
      fnQuiz();
      setQuizSelecionado(false);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  const handleRespostaClick = (respostaIndex) => {
    const updatedRespostas = quiz.pergunta.respostas.map((resposta, index) => {
      return {
        ...resposta,
        selecionada: index === respostaIndex,
      };
    });

    setQuiz((prevQuiz) => ({
      ...prevQuiz,
      pergunta: {
        ...prevQuiz?.pergunta,
        respostas: updatedRespostas,
      },
    }));
  };

  useEffect(() => {
    fnQuiz();
    fnQuizMeta();
  }, []);
  return (
    <div className="imgBackground">
      <div
        className="imgBackgroundInterno px-3 h-100"
        style={{ overflow: "auto" }}
      >
        <div className=" d-flex flex-column justify-content-between h-100 ">
          <HeaderAvatarMenu
            titulo={"Quiz"}
            subTitulo={"Testando seu conhecimento"}
          />

          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <div className="quizPaginacao">
                <span>
                  Pergunta {quizMeta.qtdQuizFeito} de {quizMeta.qtdQuizMeta}
                </span>
              </div>
              {quiz ? (
                <div>
                  <div className="quizPergunta mb-1">
                    {quiz?.pergunta?.texto}
                  </div>

                  <div className="mb-4">
                    {quiz?.pergunta?.respostas?.map((alternativa, posicao) => (
                      <button
                        key={posicao}
                        className={
                          alternativa.selecionada
                            ? "botaoQuizAtivo"
                            : "botaoQuizInativo"
                        }
                        onClick={() => {
                          handleRespostaClick(posicao);
                          setQuizSelecionado(true);
                        }}
                      >
                        {alternativa?.texto}
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ margin: " 100px 0" }}
                  >
                    <h3 style={{ maxWidth: 200, textAlign: "center" }}>
                      Você não tem mais perguntas!
                    </h3>
                  </div>
                </div>
              )}
            </div>

            <div style={{ paddingBottom: 80 }}>
              <button
                className={
                  quiz ? "btn btn-primary w-100" : "btn btn-secondary w-100"
                }
                onClick={() => {
                  quiz ? fnQuizEnviar() : navigate(-1);
                }}
                disabled={!quizSelecionado && quiz}
              >
                {quiz ? "Enviar" : "Voltar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
