import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanha from "../../assets/images/png/campanha.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import moment from "moment";
import smileUser from "../../assets/images/svg/smileUser.svg";
import smileFeliz from "../../assets/images/svg/smileFeliz.svg";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from "react-toastify";
import Avatar from "../../Components/Avatar";
import HeaderHomeVendedor from "../Home/HeaderHomeVendedor";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
export default function Enquete() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [enqueteSelecionado, setEnqueteSelecionado] = useState(false);
  const [enqueteMeta, setEnqueteMeta] = useState(0);
  const [enquete, setEnquete] = useState([]);

  async function fnProximoEnquete() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("quiz/proxima-pergunta-enquete");
      var resp = response?.data?.pergunta?.respostas || [];
      for (let i = resp?.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [resp[i], resp[j]] = [resp[j], resp[i]];
      }
      setEnquete(response.data);
      fnEnqueteMeta();
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnEnqueteMeta() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("missao/meu-resumo");

      var atual = null;
      var encontrouSequencia = false;

      for (let i = response.data.missoes.length - 1; i >= 0; i--) {
        if (response.data.missoes[i].atual) {
          if (!encontrouSequencia) {
            // Inicia uma nova sequência de 'true'
            atual = response.data.missoes[i];
            encontrouSequencia = true;
          }
        } else {
          if (encontrouSequencia) {
            // Se encontrou uma sequência de 'true' seguida de 'false', pare
            break;
          }
        }
      }

      setEnqueteMeta(atual);

      if (atual.qtdEnqueteFeito >= atual.qtdEnqueteMeta) {
        setEnquete(null);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnEnqueteEnviar() {
    var listaSelecionado = [];
    enquete?.pergunta?.respostas?.map((e, i) => {
      e.selecionada == true &&
        listaSelecionado.push({
          quizRespostaId: e.quizRespostaId,
          selecionada: e.selecionada,
        });
    });

    var objEnvio = {
      quizPerguntaId: enquete?.pergunta?.quizPerguntaId,
      respostas: listaSelecionado,
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "quiz/responder-pergunta",
        objEnvio
      );
      const data = await response.json();
      if (data.statusCode === 200) {
      } else {
        toast.warn(data.message ? data.message : data);
      }
      fnProximoEnquete();
      setEnqueteSelecionado(false);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  const handleRespostaClick = (respostaIndex) => {
    const updatedRespostas = enquete.pergunta.respostas.map(
      (resposta, index) => {
        if (index === respostaIndex) {
          return {
            ...resposta,
            selecionada: !resposta.selecionada, // Alternar entre selecionada e não selecionada
          };
        }
        return resposta;
      }
    );

    setEnquete((prev) => ({
      ...prev,
      pergunta: {
        ...prev?.pergunta,
        respostas: updatedRespostas,
      },
    }));
  };

  useEffect(() => {
    var temSelecionado = !!enquete?.pergunta?.respostas?.find((e, i) => {
      return e.selecionada === true;
    })?.selecionada;
    setEnqueteSelecionado(temSelecionado);
  }, [enquete]);

  useEffect(() => {
    fnProximoEnquete();
    fnEnqueteMeta();
  }, []);
  return (
    <div className="imgBackground">
      <div
        className="imgBackgroundInterno px-3 h-100"
        style={{ overflow: "auto" }}
      >
        <div className=" d-flex flex-column justify-content-between h-100 ">
          <HeaderAvatarMenu
            titulo={"Quiz"}
            subTitulo={"Testando seu conhecimento"}
          />

          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <div className="quizPaginacao">
                <span>
                  Pergunta {enqueteMeta.qtdEnqueteFeito} de{" "}
                  {enqueteMeta.qtdEnqueteMeta}
                </span>
              </div>
              {enquete ? (
                <div>
                  <div className="quizPergunta mb-1">
                    {enquete?.pergunta?.texto}
                  </div>

                  <div className="mb-4">
                    {enquete?.pergunta?.respostas?.map(
                      (alternativa, posicao) => (
                        <button
                          key={posicao}
                          className={
                            alternativa.selecionada
                              ? "botaoQuizAtivo"
                              : "botaoQuizInativo"
                          }
                          onClick={() => {
                            handleRespostaClick(posicao);
                          }}
                        >
                          {alternativa?.texto}
                        </button>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ margin: " 100px 0" }}
                  >
                    <h3 style={{ maxWidth: 200, textAlign: "center" }}>
                      Você não tem mais perguntas!
                    </h3>
                  </div>
                </div>
              )}
            </div>

            <div style={{ paddingBottom: 80 }}>
              <button
                className={
                  enquete ? "btn btn-primary w-100" : "btn btn-secondary w-100"
                }
                onClick={() => {
                  enquete ? fnEnqueteEnviar() : navigate(-1);
                }}
                disabled={!enqueteSelecionado && enquete}
              >
                {enquete ? "Enviar" : "Voltar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
