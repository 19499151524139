import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate, useParams } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import semImagem from "../../assets/images/png/360x360.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import moment from "moment";

export default function NoticiaDetalhe() {
  const { load, setLoad } = useLoad();
  const [noticia, setNoticia] = useState();
  const navigate = useNavigate();
  const params = useParams();

  async function fnNoticia() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("noticia/" + params.id);
      setNoticia(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnNoticia();
  }, []);

  return (
    <>
      <div className="imgBackground"> </div>

      <Menu />
      <div className="px-3">
        <div
          className="position-relative "
          style={{ marginTop: 5, fontSize: 22, marginBottom: 20 }}
        >
          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-start w-100"
              style={{ maxWidth: 500, padding: "0 0 0 15px" }}
            >
              <Link onClick={() => window.history.back()}>
                <img className="imgVoltarLogado" src={voltar} alt="" />
              </Link>
              <div className="tituloLogado">Notícias</div>
            </div>
          </div>
        </div>
        <br />
      </div>

      <div className="d-flex justify-content-center flex-column align-items-center  px-3 ">
        <div className="d-flex flex-column w-100" style={{ maxWidth: 500 }}>
          <div className="noticiaDetalheTexto1">{noticia?.titulo}</div>
          <div className="noticiaDetalheTexto2 px-3">
            Criado em {moment(noticia?.dataInicio).format("DD/MM/YYYY")}
          </div>
          <div className="d-flex justify-content-center mt-3 ">
            <img
              className="imgNoticiaDetalhe"
              src={
                noticia &&
                noticia.urlImagem &&
                noticia.urlImagem.includes("blob")
                  ? noticia.urlImagem
                  : semImagem
              }
              alt=""
            />
          </div>
          <div className="px-3 mt-3">
            <div className="noticiaDetalheTexto2 px-3">{noticia?.conteudo}</div>
          </div>
        </div>
      </div>
    </>
  );
}
