import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import camera from "../../assets/images/png/camera.png";
import sair from "../../assets/images/png/sair.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import ForcaSenha from "../../Components/ForcaSenha";
import { toast } from "react-toastify";
import moment from "moment";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import Menu from "../../Components/Menu";
import avatar1 from "../../assets/images/avatares/avatar (1).png";
import avatar2 from "../../assets/images/avatares/avatar (2).png";
import avatar3 from "../../assets/images/avatares/avatar (3).png";
import avatar4 from "../../assets/images/avatares/avatar (4).png";
import avatar5 from "../../assets/images/avatares/avatar (5).png";
import avatar6 from "../../assets/images/avatares/avatar (6).png";
import avatar7 from "../../assets/images/avatares/avatar (7).png";
import avatar8 from "../../assets/images/avatares/avatar (8).png";
import avatar9 from "../../assets/images/avatares/avatar (9).png";
import avatar10 from "../../assets/images/avatares/avatar (10).png";
import avatar11 from "../../assets/images/avatares/avatar (11).png";
import avatar12 from "../../assets/images/avatares/avatar (12).png";
import avatar13 from "../../assets/images/avatares/avatar (13).png";
import avatar14 from "../../assets/images/avatares/avatar (14).png";
import avatar15 from "../../assets/images/avatares/avatar (15).png";
import storage from "../../services/storage";

export default function MeuCadastroAvatar() {
  const { load, setLoad } = useLoad();
  const [avatarSelecionado, setAvatarSelecionado] = useState(0);
  const [trava, setTrava] = useState(false);
  const [verImagem, setVerImagem] = useState("");
  const [arquivoFile, setArquivoFile] = useState(null);
  const [eu, setEu] = useState([]);
  const navigate = useNavigate();
  async function fnCarregarImagem(e) {
    try {
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            setVerImagem(e.target.result);
            setLoad(false);

            const dataForm = new FormData();
            dataForm.append("file", file);
            setArquivoFile(dataForm);
          };
        };

        reader.readAsDataURL(file);
      } else {
      }
    } catch (err) {
      console.error("Erro ao fazer upload:", err);
    } finally {
      // Algumas ações que você queira executar, se necessário
    }
  }
  async function fnGetEu() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`eu`);
      setEu(response.data);
      !response.data.urlAvatar.includes("blob") &&
        setAvatarSelecionado(Number(response.data.urlAvatar));
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnEnviarImagemApi() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUpload(`upload/avatar`, arquivoFile);
      fnPutEu(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    if (arquivoFile) {
      fnEnviarImagemApi();
    }
  }, [arquivoFile]);
  async function fnPutEu(img) {
    var objEnvio = {
      ...eu,
      urlAvatar: String(img),
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPut(`eu/avatar`, objEnvio);

      navigate("/meu-cadastro");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnGetEu();
  }, []);

  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column  justify-content-between w-100 h-100">
            <div className="d-flex flex-column ">
              <HeaderAvatarMenu titulo={"Altera avatar"} />
              <div className="d-flex flex-column align-items-center">
                <div className="meuCadastroAvatarTexto1">
                  Escolha o seu avatar
                </div>
                <div className="meuCadastroAvatarTexto2">Tema 1</div>
                <div className="divImgMeuCadastroAvatar">
                  <div
                    onClick={() => {
                      setAvatarSelecionado(1);
                    }}
                    className={
                      avatarSelecionado == 1
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar1} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(2);
                    }}
                    className={
                      avatarSelecionado == 2
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar2} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(3);
                    }}
                    className={
                      avatarSelecionado == 3
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar3} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(4);
                    }}
                    className={
                      avatarSelecionado == 4
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar4} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(5);
                    }}
                    className={
                      avatarSelecionado == 5
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar5} alt="" />
                  </div>
                </div>
                <div className="meuCadastroAvatarTexto2">Tema 2</div>
                <div className="divImgMeuCadastroAvatar">
                  <div
                    onClick={() => {
                      setAvatarSelecionado(6);
                    }}
                    className={
                      avatarSelecionado == 6
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar6} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(7);
                    }}
                    className={
                      avatarSelecionado == 7
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar7} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(8);
                    }}
                    className={
                      avatarSelecionado == 8
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar8} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(9);
                    }}
                    className={
                      avatarSelecionado == 9
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar9} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(10);
                    }}
                    className={
                      avatarSelecionado == 10
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar10} alt="" />
                  </div>
                </div>
                <div className="meuCadastroAvatarTexto2">Tema 3</div>
                <div className="divImgMeuCadastroAvatar">
                  <div
                    onClick={() => {
                      setAvatarSelecionado(11);
                    }}
                    className={
                      avatarSelecionado == 11
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar11} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(12);
                    }}
                    className={
                      avatarSelecionado == 12
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar12} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(13);
                    }}
                    className={
                      avatarSelecionado == 13
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar13} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(14);
                    }}
                    className={
                      avatarSelecionado == 14
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar14} alt="" />
                  </div>
                  <div
                    onClick={() => {
                      setAvatarSelecionado(15);
                    }}
                    className={
                      avatarSelecionado == 15
                        ? "imgMeuCadastroAvatarAtivo"
                        : "imgMeuCadastroAvatar"
                    }
                  >
                    <img width={"100%"} src={avatar15} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100" style={{ paddingBottom: 80 }}>
              <button
                disabled={trava}
                onClick={() => {
                  document.querySelector(".inputUpload").click();
                }}
                className="btn btn-secondary w-100 my-3"
              >
                Anexar imagem
              </button>
              <input
                className="d-none inputUpload"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                onChange={fnCarregarImagem}
              />
              <button
                disabled={trava}
                onClick={() => {
                  arquivoFile
                    ? fnEnviarImagemApi()
                    : fnPutEu(avatarSelecionado);
                }}
                className="btn btn-primary w-100 "
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
