import React, { createContext, useContext, useState } from "react";

export const UserContextDefault = {
  user: null,
  setUser: undefined,
};

export const UserContext = createContext(UserContextDefault);

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  const { user, setUser } = context;
  return { user, setUser };
};
