import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import PDF from "../../Components/Pdf";
import regulamento from "../../assets/pdf/regulamento-yamaha.pdf";
export default function Termo() {
  const { load, setLoad } = useLoad();

  const navigate = useNavigate();

  async function fnCarregarBannerRotativo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner?ativo=true");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <Link to={"/senha"}>
              <img className="imgVoltar" src={voltar} alt="" />
            </Link>
            <div className="termoTexto1">Termos e condições</div>

            <div
              className="border overflow-hidden"
              style={{ height: "100vh", marginBottom: 16 }}
            >
              <PDF pdf={regulamento} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
