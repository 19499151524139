import React, { useEffect, useState } from "react";
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { useLocation, useNavigate, useParams } from "react-router";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { pdfjs } from "react-pdf";
import regulamento from "../../assets/pdf/regulamento-yamaha.pdf";
import { WidthFull } from "@mui/icons-material";
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function PDF() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return regulamento ? (
    <Viewer fileUrl={regulamento} plugins={[defaultLayoutPluginInstance]} defaultScale={SpecialZoomLevel.PageFit}/>
  ) : (
    <div>Carregando...</div>
  );
}
