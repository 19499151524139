import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import storage from "../../services/storage";
import { validaEmail } from "../../services/validaEmail";

export default function EmailRecadastroEmail() {
  const { load, setLoad } = useLoad();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [trava, setTrava] = useState(false);
  async function fnEnviarEmail() {
    setTrava(true);
    var obj = {
      codigoLogin: storage.get("USUARIO")?.login,
      email: email,
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        "duplo-fator/deslogado/codigo/login/enviar/email",
        obj
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        const atualizado = Object.assign(storage?.get("USUARIO") || {}, {
          email: email,
        });
        storage.set("USUARIO", atualizado);
        navigate("/email-recadastro-verificacao");
      } else {
        toast.error(data.message);
      }
      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  function ocultarEmail(email) {
    const parts = email?.split("@");
    const prefix = parts[0];
    const obscuredPrefix =
      prefix?.substring(0, 2) + "*".repeat(prefix.length - 2);
    return obscuredPrefix + "@" + parts[1];
  }

  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link to={"/email-recadastro-login"}>
                <img className="imgVoltar" src={voltar} alt="" />
              </Link>
              <div className="emailRecadastroEmailTexto1">Legal!</div>
              <div className="emailRecadastroEmailTexto2">
                <div style={{ marginBottom: 10 }}>
                  Precisamos agora que cadastre o seu melhor e-mail.
                </div>
              </div>
              <label>Informe seu melhor e-mail</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  className="inputPrimary"
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <div
                  className="labelLoginCpf m-0"
                  style={{ color: "#FF3E0E", fontWeight: 600 }}
                >
                  {validaEmail(email)
                    ? " "
                    : email.length > 5 && "E-mail invalido"}
                </div>
              </form>
              <div className="emailRecadastroEmailTexto3">
                Vamos enviar por e-mail um código de validação
              </div>
            </div>

            <button
              onClick={fnEnviarEmail}
              className="btn btn-primary w-100 "
              disabled={!validaEmail(email) || trava}
              style={{ margin: "20px 0" }}
            >
              Enviar código
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
