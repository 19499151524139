import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";

import presenteHome from "../../assets/images/png/presenteHome.png";
import ok from "../../assets/images/png/ok.png";
import atual from "../../assets/images/png/atual.png";
import bloqueado from "../../assets/images/png/bloqueado.png";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import voltar from "../../assets/images/png/voltar.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiServices from "../../services/apiServices";
import moment from "moment";
import estrelaCheia from "../../assets/images/png/estrelaCheia.png";
import estrelaVazia from "../../assets/images/png/estrelaVazia.png";
export default function HistoricoRecompensas() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const [historicoRecompensas, setHistoricoRecompensas] = useState([]);

  async function fnNumeroDaSorte() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("roleta/minhas-recompensas");
      setHistoricoRecompensas(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnNumeroDaSorte();
  }, []);
  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          <HeaderAvatarMenu
            titulo={"Histórico de recompensas"}
            imagem={presenteHome}
          />
          <div style={{ paddingBottom: 80 }}>
            {historicoRecompensas.map((e, i) => (
              <div key={i}>
                <div className="d-flex justify-content-between">
                  <div className="dataHistoricoRecompensas">
                    {moment(e.dataEnviouRoleta).format("DD/MM/YYYY - HH:mm:ss")}
                  </div>
                  {/* <div
                    className="detalheHistoricoRecompensas d-flex justify-content-end"
                    style={{ minWidth: 120 }}
                  >
                    {e.premioApelido}
                  </div> */}

                  <div
                    className="estrelaHistoricoNumeroDaSorte d-flex justify-content-end "
                    style={{ minWidth: 80 }}
                  >
                    <div style={{ marginBottom: 5 }}>
                      {e.qtdEstrelas >= 1 ? (
                        <img width={20} src={estrelaCheia} alt="" />
                      ) : (
                        <img width={20} src={estrelaVazia} alt="" />
                      )}
                      {e.qtdEstrelas >= 2 ? (
                        <img width={20} src={estrelaCheia} alt="" />
                      ) : (
                        <img width={20} src={estrelaVazia} alt="" />
                      )}
                      {e.qtdEstrelas >= 3 ? (
                        <img width={20} src={estrelaCheia} alt="" />
                      ) : (
                        <img width={20} src={estrelaVazia} alt="" />
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="nomeHistoricoRecompensas me-3 w-100">
                    {e.premioNome}
                  </div>
                  {e.premioQtdVibes > 0 ? (
                    <button
                      disabled={e.premioQtdVibes > 0 && e.resgatou === 1}
                      className="btn btn-success btn-sm d-flex align-items-center justify-content-center"
                      onClick={() => {
                        navigate("/recompensas", {
                          state: {
                            key: e.codigo,
                            historico: true,
                            idHistorico: e.roletaJogadaId,
                          },
                        });
                      }}
                      style={{
                        minHeight: 0,
                        height: 30,
                        fontSize: 12,
                        paddingTop: 5,
                        minWidth: 90,
                      }}
                    >
                      {e.premioQtdVibes > 0 && e.resgatou === 1
                        ? "Resgatado"
                        : "Resgatar"}
                    </button>
                  ) : null}
                </div>
                {e.premioQtdVibes > 0 ? (
                  <div style={{ width: "400px", wordBreak: "break-word" }}>
                    <div style={{ fontSize: 14 }}>Seu resgate</div>
                    <div style={{ color: "#fab328" }}>
                      {e.cupom ? (
                        e.cupom.includes("http") ? (
                          <a
                            className="linkDestaqueAmarelo"
                            href={e.cupom}
                            target="_blank"
                          >
                            {e.cupom}
                          </a>
                        ) : (
                          e.cupom
                        )
                      ) : (
                        "Ainda não resgatado"
                      )}
                    </div>
                  </div>
                ) : null}

                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
