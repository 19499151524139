import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import vibes from "../../assets/images/png/vibes.png";
import premio from "../../assets/images/png/premio.png";
export default function Parabens() {
  const { load, setLoad } = useLoad();

  const navigate = useNavigate();

  async function fnCarregarBannerRotativo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner?ativo=true");
    } catch (err) {
      console.error("err", err);
    } finally { 
      setLoad(false); 
    }
  }

  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          {" "}
          <div className=" d-flex flex-column justify-content-between h-100 ">
            <HeaderAvatarMenu mb={25} />
            <div className=" d-flex flex-column align-items-center justify-content-between h-100 ">
              <div className="d-flex flex-column align-items-center">
                <h3>Parabéns!</h3>
                <div
                  style={{ maxWidth: 210, opacity: 0.8, textAlign: "center" }}
                >
                  Você foi contemplado na Roleta da sorte e ganhou!
                </div>

                <img className="my-3" width={"80px"} src={vibes} alt="" />
                <b className="fs-1">10 Vibes</b>
                <h5 className="mt-4">Sugestão de prêmios</h5>
                <img className="my-3" width={"80px"} src={premio} alt="" />
                <b>R$ 10 OFF em pedidos acima de R$30</b>
                <div>Aproveite R$10 OFF em pedidos acima de R$30 no site</div>
              </div>
              <div className="w-100 mt-3" style={{ paddingBottom: 80 }}>
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    navigate("/parabens/saiba-mais");
                  }}
                >
                  Saiba mais
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
