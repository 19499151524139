import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import checkOk from "../../assets/images/png/checkOk.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";

export default function EmailVerificado() {
  const { load, setLoad } = useLoad();
  const [cpf, setCpf] = useState("");
  const [tempo, setTempo] = useState(5);
  const navigate = useNavigate();

  async function fnCarregarBannerRotativo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner?ativo=true");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (tempo > 0) {
        setTempo(tempo - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    if (tempo === 0) {
      navigate("/celular-cadastro");
    }

    return () => {
      clearInterval(interval);
    };
  }, [tempo]);

  return (
    <>
      <div
        className="imgBackground"
        onClick={() => {
          navigate("/celular-cadastro");
        }}
      >
        <div className="d-flex justify-content-center">
          <div className="imgBackgroundInterno px-3 " style={{ overflow: "auto" }}>
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="px-5">
                <Link
                  onClick={() => window.history.back()}
                  className="invisible "
                >
                  <img className="imgVoltar" src={voltar} alt="" />
                </Link>
                {/* <div className="celularVerificadoTexto1">
            SMS validado com sucesse!
          </div> */}

                <img className="imgCheckOk" src={checkOk} alt="" />

                {/* <button
            onClick={() => {
              navigate("/login");
            }}
            className="btn btn-secondary w-100"
            style={{ marginTop: 627 }}
          >
            Voltar para a tela inicial
          </button> */}
              </div>
              <div className="d-flex  justify-content-center tempoCheck ">
                <div className="text-center fs-1 mt-2 numeroContadorFinalizar">
                  <b className="mt-1">{tempo}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
