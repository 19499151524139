import React, { useEffect, useState } from "react";
import "./index.css";
import voltar from "../../assets/images/png/voltar.png";
import { Link, useNavigate } from "react-router-dom";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ApiServices from "../../services/apiServices";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function Finalizado() {
  const { load, setLoad } = useLoad();
  const [tempo, setTempo] = useState(5);
  const navigate = useNavigate();

  async function fnLogin() {
    try {
      setLoad(true);
      var obj = {
        userName: storage.get("USUARIO")?.userName,
        password: storage.get("USUARIO")?.senha,
      };
      await ApiServices.apiPost("login/portal", obj)
        .then((res) => res.json())
        .then((data) => {
          if (data.statusCode === 200) {
            storage.set("USUARIO", data.data);
            navigate("/home");
          } else {
            toast.error(data.message);
          }
        });
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    if (!storage.get("USUARIO")?.senha) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (tempo > 0) {
        setTempo(tempo - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
    if (tempo === 0) {
      fnLogin();
    }

    return () => {
      clearInterval(interval);
    };
  }, [tempo]);
  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  } 
  return (
    <>
    <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div className="imgBackgroundInterno px-3 " style={{ overflow: "auto" }}>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link className="invisible ">
                <img className="imgVoltar" src={voltar} alt="" />
              </Link>
              <div className="finalizadoTexto1">
                Parabéns! A sua conta está criada.
              </div>
              <div className="finalizadoTexto2">
                <div>
                  Você poderá alterar seus dados a qualquer momento, em 
                  <b>Meu Cadastro.</b>
                </div>
              </div>

              <button onClick={fnLogin} className="btn btn-primary w-100 ">
                Acessar portal
              </button>
              <div className="text-center mt-5 finalizadoTexto2 mb-0">
                <b>Você entrara no portal em:</b>
              </div>
              <div className="d-flex  justify-content-center ">
                <div className="text-center fs-1 mt-2 numeroContadorFinalizar">
                  <b className="mt-1">{tempo}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
