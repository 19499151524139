import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";

import numeroSorte from "../../assets/images/png/numeroSorte.png";
import ok from "../../assets/images/png/ok.png";
import atual from "../../assets/images/png/atual.png";
import bloqueado from "../../assets/images/png/bloqueado.png";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import estrelaCheia from "../../assets/images/png/estrelaCheia.png";
import estrelaVazia from "../../assets/images/png/estrelaVazia.png";
import ApiServices from "../../services/apiServices";
import moment from "moment";
export default function HistoricoDeNumeroDaSorte() {
  const { load, setLoad } = useLoad();
  const [historicoNumeroDaSorte, setHistoricoNumeroDaSorte] = useState([]);

  async function fnNumeroDaSorte() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("numero-sorteio/meu");

      setHistoricoNumeroDaSorte(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    fnNumeroDaSorte();
  }, []);
  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          <HeaderAvatarMenu
            titulo={"Histórico do número da sorte"}
            imagem={numeroSorte}
          />
          <div style={{ paddingBottom: 80 }}>
            {historicoNumeroDaSorte.map((e, i) => (
              <div key={i}>
                <div className="d-flex justify-content-between">
                  <div className="dataHistoricoNumeroDaSorte">
                    {moment(e.data).format("DD/MM/YYYY")}
                  </div>
                  <div
                    className="estrelaHistoricoNumeroDaSorte d-flex justify-content-end"
                    style={{ minWidth: 150 }}
                  >
                    <div style={{ marginBottom: 5 }}>
                      {e.qtdEstrelas >= 1 ? (
                        <img width={20} src={estrelaCheia} alt="" />
                      ) : (
                        <img width={20} src={estrelaVazia} alt="" />
                      )}
                      {e.qtdEstrelas >= 2 ? (
                        <img width={20} src={estrelaCheia} alt="" />
                      ) : (
                        <img width={20} src={estrelaVazia} alt="" />
                      )}
                      {e.qtdEstrelas >= 3 ? (
                        <img width={20} src={estrelaCheia} alt="" />
                      ) : (
                        <img width={20} src={estrelaVazia} alt="" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="numeroHistoricoNumeroDaSorte tresPontinhos me-3">
                  {e.numero}
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
