import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import ok from "../../assets/images/png/ok.png";
import errado from "../../assets/images/png/errado.png";
import atual from "../../assets/images/png/atual.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { toast } from "react-toastify";
import Avatar from "../../Components/Avatar";
import HeaderHomeVendedor from "../Home/HeaderHomeVendedor";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
export default function HistoricoDaEnquete() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [enquete, setEnquete] = useState([
    {
      enqueteId: "3fa85f64-5717-4562-b3fc-2c963f66afa7",
      pergunta: "Qual é o seu destino de viagem dos sonhos?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "ok",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb0",
          texto: "Paris, França",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb1",
          texto: "Toscana, Itália",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb1",
          texto: "Pequin, China",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb1",
          texto: "Orlando, Estados Unidos",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb1",
          texto: "Buenos Aires, Argentina",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb1",
          texto: "San José, Costa Rica",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb2",
          texto: "Kyoto, Japão",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afb3",
          texto: "Rio de Janeiro, Brasil",
        },
      ],
    },

    {
      enqueteId: "3fa85f64-5717-4562-b3fc-2c963f66afa8",
      pergunta: "Qual é o seu hobby preferido?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "ok",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afc4",
          texto: "Cozinhar",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afc5",
          texto: "Praticar esportes",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afc6",
          texto: "Ler",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afc7",
          texto: "Assistir filmes",
        },
      ],
    },
    {
      enqueteId: "3fa85f64-5717-4562-b3fc-2c963f66afa9",
      pergunta: "Qual é o seu estilo de música favorito para relaxar?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "errado",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afd8",
          texto: "Jazz",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afd9",
          texto: "Clássica",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afda",
          texto: "Chill-out",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afdb",
          texto: "Ambiental",
        },
      ],
    },

    {
      enqueteId: "3fa85f64-5717-4562-b3fc-2c963f66afaa",
      pergunta: "Qual é o seu prato favorito em um jantar romântico?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "errado",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afdc",
          texto: "Salmão ao molho de maracujá",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afdd",
          texto: "Risoto de cogumelos",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afde",
          texto: "Filet mignon ao molho de vinho tinto",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afdf",
          texto: "Massa fresca com camarões",
        },
      ],
    },

    {
      enqueteId: "3fa85f64-5717-4562-b3fc-2c963f66afac",
      pergunta: "Qual é o seu animal de estimação preferido?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "atual",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afe2",
          texto: "Cachorro",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afe3",
          texto: "Gato",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afe4",
          texto: "Pássaro",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afe5",
          texto: "Peixe",
        },
      ],
    },
    {
      enqueteId: "3fa85f64-5717-4562-b3fc-2c963f66afad",
      pergunta: "Qual é o seu tipo de filme preferido?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "bloqueado",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afe6",
          texto: "Ação",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afe7",
          texto: "Comédia",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afe8",
          texto: "Drama",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afe9",
          texto: "Ficção Científica",
        },
      ],
    },
    {
      enqueteId: "3fa85f64-5717-4562-b3fc-2c963f66afae",
      pergunta: "Qual é a sua estação do ano favorita?",
      dateCreated: "2024-03-15T14:16:02.490Z",
      dataInicio: "2024-03-15T14:16:02.490Z",
      dataFim: "2024-03-15T14:16:02.490Z",
      status: "bloqueado",
      pontos: 0,
      ativo: true,
      alternativas: [
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afea",
          texto: "Primavera",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afeb",
          texto: "Verão",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afec",
          texto: "Outono",
        },
        {
          id: "3fa33664-5717-4582-b3fc-2c963f63afed",
          texto: "Inverno",
        },
      ],
    },
  ]);

  return (
    <div className="imgBackground">
      <div
        className="imgBackgroundInterno px-3 h-100"
        style={{ overflow: "auto" }}
      >
        <div className=" d-flex flex-column h-100 ">
          <HeaderAvatarMenu
            titulo={"Histórico do enquete"}
            // subTitulo={"Testando seu conhecimento"}
          />

          <div style={{ paddingBottom: 80 }}>
            {enquete.map((e, i) => (
              <div
                key={i}
                className={e.status !== "bloqueado" ? "pointer" : ""}
                onClick={() => {
                  // e.status !== "bloqueado" && setQuizSelecionado([e]);
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className="dataHistoricoRecompensas">
                    Pergunta {i + 1}
                  </div>

                  {e.status == "ok" && (
                    <div>
                      <img width={20} src={ok} alt="" />
                    </div>
                  )}
                  {e.status == "errado" && (
                    <div>
                      <img width={20} src={errado} alt="" />
                    </div>
                  )}
                  {e.status == "atual" && (
                    <div>
                      <img width={20} src={atual} alt="" />
                    </div>
                  )}
                </div>
                <div
                  className="nomeHistoricoRecompensas me-4"
                  style={{
                    filter: e.status == "bloqueado" ? "blur(5px)" : "blur(0px)",
                  }}
                >
                  {e.status == "bloqueado" &&
                    "****** ******* *** ********** ******** ********** ***** ************** **** ********* **** ******* *** *******"}
                  {e.status !== "bloqueado" && e.pergunta}
                </div>
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
