import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import lupa from "../../assets/images/png/lupa.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import { toast } from "react-toastify";
import storage from "../../services/storage";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import Mask from "../../services/mask";
export default function FaleConoscoDeslogado() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [agradecimento, setAgradecimento] = useState(false);
  const [listaAssunto, setListaAssunto] = useState([]);
  const [fale, setFale] = useState({
    nome: "",
    email: "",
    cpf: "",
    telefone: "",
    descricao: "",
    faleConoscoAssuntoId: "",
  });
  async function fnListarAssunto() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("faleconosco/assunto/deslogado");
      setListaAssunto(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnFaleConosco() {
    setLoad(true);
  
    var objEnvio = {
      faleConoscoAssuntoId: fale.faleConoscoAssuntoId,
      mensagem: fale.descricao,
      anexo: "",
      nome: fale.nome,
      email: fale.email,
      cpf: fale.cpf,
      telefone: fale.telefone,
    };

    try {
      let response = await ApiServices.apiPost(
        "faleconosco/criar-deslogado",
        objEnvio
      );

      setAgradecimento(true);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  
  useEffect(() => {
    fnListarAssunto();
  }, []);
  
  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link to={"/login"}>
                <img className="imgVoltar" src={voltar} alt="" />
              </Link>
              <div className="cadastroTexto1">Vamos lá</div>
              <div className="cadastroTexto2">
                Comece informando seus dados.
              </div>
              <h5>Mande sua dúvida pra gente!</h5>
              {!agradecimento ? (
                <div>
                  <label>Nome</label>
                  <form autoComplete="off">
                    <input
                      autoComplete="off"
                      className="inputPrimary"
                      type="text"
                      value={fale.nome || ""}
                      onChange={(e) => {
                        setFale((prev) => ({
                          ...prev,
                          nome: e.target.value,
                        }));
                      }}
                    />
                  </form>
                  <label>E-mail</label>
                  <form autoComplete="off">
                    <input
                      autoComplete="off"
                      className="inputPrimary"
                      type="text"
                      value={fale.email || ""}
                      onChange={(e) => {
                        setFale((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </form>
                  <label>CPF</label>
                  <form autoComplete="off">
                    <input
                      autoComplete="off"
                      className="inputPrimary"
                      type="text"
                      value={Mask.cpf(fale.cpf) || ""}
                      onChange={(e) => {
                        setFale((prev) => ({
                          ...prev,
                          cpf: Mask.unMask(e.target.value),
                        }));
                      }}
                    />
                  </form>
                  <label>Telefone</label>
                  <form autoComplete="off">
                    <input
                      autoComplete="off"
                      className="inputPrimary"
                      type="text"
                      value={Mask.cel(fale.telefone) || ""}
                      onChange={(e) => {
                        setFale((prev) => ({
                          ...prev,
                          telefone: Mask.unMask(e.target.value),
                        }));
                      }}
                    />
                  </form>

                  <label>Assunto</label>
                  <select
                    value={fale.faleConoscoAssuntoId}
                    onChange={(e) => {
                      setFale((prev) => ({
                        ...prev,
                        faleConoscoAssuntoId: e.target.value,
                      }));
                    }}
                    className="inputPrimary"
                  >
                    <option value="">Selecione</option>
                    {listaAssunto?.map((e, i) => (
                      <option key={i} value={e.faleConoscoAssuntoId}>
                        {e.descricao}
                      </option>
                    ))}
                  </select>

                  <label>Descrição</label>
                  <div
                    className="faleConoscoTextArea"
                    style={{ overflow: "hidden" }}
                  >
                    <textarea
                      className="faleConoscoTextArea"
                      placeholder="Escreva sua mensagem"
                      value={fale.descricao}
                      onChange={(e) => {
                        setFale((prev) => ({
                          ...prev,
                          descricao: e.target.value,
                        }));
                      }}
                    ></textarea>
                  </div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-between h-100">
                  <div>
                    <br />
                    <h2 className="text-center mt-5">Muito obrigado!</h2>
                    <div className="d-flex justify-content-center">
                      <div className="text-center" style={{ maxWidth: 300 }}>
                        Em breve, um de nossos atendentes entrará em contato.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <button
                className="btn btn-primary w-100 my-4"
                disabled={
                  (!fale.nome ||
                    fale.cpf.length < 11 ||
                    !fale.email ||
                    !fale.faleConoscoAssuntoId ||
                    fale.telefone?.length < 11 ||
                    !fale.descricao) &&
                  !agradecimento
                }
                onClick={() => {
                  !agradecimento ? fnFaleConosco() : navigate(-1);
                }}
              >
                {!agradecimento ? " Enviar" : "Voltar"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
