import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import Mask from "../../services/mask";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import storage from "../../services/storage";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import ReactCodeInput from "react-code-input";

export default function EmailVerificacao() {
  const { load, setLoad } = useLoad();
  const [numero, setNumero] = useState("");
  const [tempo, setTempo] = useState(120);

  const navigate = useNavigate();

  async function fnReenviarCodigo() {
    var obj = {
      userName: storage.get("USUARIO")?.userName,
      email: storage.get("USUARIO")?.email,
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost(
        "duplo-fator/deslogado/enviar/email",
        obj
      );
      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success(data.data);
        setTempo(120);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCodigo(id) {
    var obj = {
      userName: storage.get("USUARIO")?.userName,
      email: storage.get("USUARIO")?.email,
      codigoDuploFator: id,
    };
    try {
      setLoad(true);
      let response = await ApiServices.apiPost("usuario/email/confirmar", obj);
      const data = await response.json();
      if (data.statusCode === 200) {
        toast.success(data.data);
        const atualizado = Object.assign(storage.get("USUARIO"), data.data);
        storage.set("USUARIO", atualizado);
        navigate("/email-verificado");
      } else {
        // navigate("/cpf-cadastro");
        toast.error(data.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (tempo > 0) {
        setTempo(tempo - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [tempo]);

  const props = {
    inputStyle: {
      width: "45px",
      height: "70px",
      fontSize: "50px",
      MozAppearance: "textfield",
      margin: "4px",
      className: "aaaaaaaaaa",
      MozAppearance: "textfield",
      paddingLeft: "6px",
      fontFamily: "monospace",
      borderRadius: "10px",
      border: "solid #464e52 3px",
      backgroundColor: "#252233",
      color: "#ffffff",
    },
  };

  useEffect(() => {
    if (numero?.length >= 6) {
      fnCodigo(numero);
    }
  }, [numero]);
  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div className="d-flex justify-content-center">
          <div
            className="imgBackgroundInterno px-3 "
            style={{ overflow: "auto" }}
          >
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <Link to={"/cpf-cadastro"}>
                  <img className="imgVoltar" src={voltar} alt="" />
                </Link>
                <div className="emailVerificacaoTexto1">Verificação</div>
                <div className="emailVerificacaoTexto2">
                  Digite o código que enviamos por e-mail
                </div>

                <div className="d-flex justify-content-center emailRecadastroVerificacaoInputCardTodos ">
                  <ReactCodeInput
                    type="number"
                    fields={6}
                    autoFocus
                    {...props}
                    value={numero}
                    onChange={(e) => {
                      setNumero(e);
                    }}
                  />
                </div>

                <div className="emailVerificacaoTexto3">
                  Aguarde <b>{tempo} segundos </b>
                  para reenviar o código
                </div>
                <br />
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-secondary "
                    disabled={tempo !== 0}
                    onClick={() => {
                      fnReenviarCodigo();
                    }}
                  >
                    Reenviar o código
                  </button>
                </div>
              </div>
              <div className="emailVerificacaoTexto4">
                Nunca pedimos este dado por e-mail, SMS ou telefone. Não
                compartilhe este código com ninguém.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
