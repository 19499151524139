import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Loading from "./Components/Loading";
import Mensagem from "./Components/Mensagem";
import { LoadProvider } from "./context/Load";
import { UserProvider } from "./context/User";
import "./index.css";
import Rotas from "./Routes";
import ScrollToTop from "./Components/PaginaIniciaNoTopo";
import localization from "moment/locale/pt-br";
import moment from "moment";
import {
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import globals from "./globals";
moment.updateLocale("pt-br", localization);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <GoogleReCaptchaProvider reCaptchaKey={globals.REACT_APP_RECAPTCHA}>
      <LoadProvider>
        <UserProvider>
          <BrowserRouter>
            <ScrollToTop>
              <Loading />
              <Mensagem />
              <Rotas />
            </ScrollToTop>
          </BrowserRouter>
        </UserProvider>
      </LoadProvider>
    </GoogleReCaptchaProvider>
  </>
);
