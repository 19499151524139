import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import sair from "../../assets/images/png/sair.png";
import campanha from "../../assets/images/png/campanha.png";
import { Link } from "react-router-dom";
import Menu from "../../Components/Menu";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import regulamento from "../../assets/pdf/regulamento-yamaha.pdf";
import PDF from "../../Components/Pdf";
export default function Regulamento() {
  const { load, setLoad } = useLoad();

  const navigate = useNavigate();

  async function fnCarregarBannerRotativo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("banner?ativo=true");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <HeaderAvatarMenu titulo={"Termos e condições"} />
              <div className="regulamentoTexto1">Regulamento</div>
            </div>

            <div
              className="border overflow-hidden"
              style={{ marginBottom: 80 }}
            >
              <PDF pdf={regulamento} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
