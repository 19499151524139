import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate } from "react-router";
import ApiServices from "../../services/apiServices";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import storage from "../../services/storage";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function EmailCadastro() {
  const { load, setLoad } = useLoad();

  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [trava, setTrava] = useState(false);
  async function fnEnviarEmail() {
    setTrava(true);
    var obj = {
      userName: storage.get("USUARIO")?.userName,
      email: storage.get("USUARIO")?.email,
    };
    try {
      setLoad(true);
      const response = await ApiServices.apiPost(
        "duplo-fator/deslogado/enviar/email",
        obj
      );
      const data = await response.json();

      if (data.statusCode === 200) {
        navigate("/email-verificacao");
      } else {
        toast.error(data.message);
      }
      setTrava(false);
    } catch (err) {
      setTrava(false);
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  function ocultarEmail(email) {
    const parts = email?.split("@");
    const prefix = parts[0];
    const obscuredPrefix =
      prefix?.substring(0, 2) + "*".repeat(prefix.length - 2);
    return obscuredPrefix + "@" + parts[1];
  }
  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Link to={"/cpf-cadastro"}>
                <img className="imgVoltar" src={voltar} alt="" />
              </Link>
              <div className="emailVerificacaoTexto1">Legal!</div>

              <div className="emailVerificacaoTexto2">
                <div style={{ marginBottom: 10 }}>
                  Informe o e-mail que deseja receber o código de validação.
                  <br />
                  <br />
                  Caso não receba o e-mail na caixa de entrada, verifique no
                  SPAM ou Lixo Eletrônico.
                </div>
              </div>
              <label>Informe seu e-mail</label>
              <form autoComplete="off">
                <input
                  autoComplete="off"
                  className="inputPrimary"
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value.toLowerCase());
                    const atualizado = Object.assign(storage.get("USUARIO"), {
                      email: e.target.value.toLowerCase(),
                    });
                    storage.set("USUARIO", atualizado);
                  }}
                />
                <div
                  className="labelLoginCpf m-0"
                  style={{ color: "#FF3E0E", fontWeight: 600 }}
                >
                  {/* {storage.get("USUARIO")?.email === email
                    ? " "
                    : email?.length > 5
                    ? "E-mail invalido"
                    : " "} */}
                </div>
              </form>
              <div className="emailVerificacaoTexto3">
                Vamos enviar por e-mail um código de validação
              </div>
            </div>

            <div>
              {/* <button
                onClick={() => {
                  navigate("/email-recadastro-login");
                }}
                className="btn btn-secondary w-100"
                disabled={storage.get("USUARIO")?.email == email || trava}
                style={{ marginTop: 20 }}
              >
                Não reconheço este e-mail
              </button> */}
              <button
                onClick={fnEnviarEmail}
                className="btn btn-primary w-100 "
                disabled={email.length <= 5 || trava}
                style={{ margin: "20px 0 " }}
              >
                Enviar código E-mail
              </button>
              {/* <div className="emailVerificacaoTexto4">
          Se não reconhece esse e-mail, entre em contato com seu gerente para
          maiores informações.
        </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
