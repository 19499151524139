import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";
import { useLocation, useNavigate, useParams } from "react-router";
import ApiServices from "../../services/apiServices";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import triste from "../../assets/images/svg/triste.svg";
import presenteHome from "../../assets/images/png/presenteHome.png";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import storage from "../../services/storage";
export default function Recompensas() {
  const { load, setLoad } = useLoad();
  const [nomePremio, setNomePremio] = useState("");
  const [maisOpcoes, setMaisOpcoes] = useState([]);
  const [maisOpcoesRoleta, setMaisOpcoesRoleta] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const recompensa = location.state.key;
  const idHistorico = location.state.idHistorico;
  const ehVibe =
    location.state.key === 1000 ||
    location.state.key === 2000 ||
    location.state.key === 3000 ||
    location.state.key === 4000 ||
    location.state.key === 5000 ||
    location.state.key === 10000 ||
    location.state.key === 11000 ||
    location.state.key === 12000 ||
    location.state.key === 30000 ||
    location.state.key === 31000 ||
    location.state.key === 32000;
  function fnNomePremio() {
    if (recompensa === 0) {
      setNomePremio("Não foi dessa vez");
    }
    if (recompensa === 1000) {
      setNomePremio("Cinemark: 30% de desconto");
    }
    if (recompensa === 2000) {
      setNomePremio("Riachuelo: R$ 12,00 para usar no site");
    }
    if (recompensa === 3000) {
      setNomePremio(
        "CONECTCAR: plano por R$6,50/mês + R$30,00 reais de crédito"
      );
    }
    // if (recompensa === 4000) {
    //   setNomePremio("POPEYES = CUPOM R$10 OFF em pedidos acima de R$30,00");
    // }
    if (recompensa === 5000) {
      setNomePremio(
        "RAPPI: CUPOM PARA 99% OFF no Plano Rappi Prime Basic + 10 reais para usar na loja Turbo"
      );
    }
    if (recompensa === 6000) {
      setNomePremio("Echo Dot 5ª Geração Smart Speaker com Alexa");
    }
    if (recompensa === 7000) {
      setNomePremio("Headphone Bluetooth JBL Tune 520 com Microfone - Preto");
    }
    if (recompensa === 8000) {
      setNomePremio("Vale Combustível Shellbox R$ 100,00");
    }
    if (recompensa === 9000) {
      setNomePremio("Vale Combustível Shellbox R$ 150,00");
    }
    if (recompensa === 10000) {
      setNomePremio("Cinemark: 30% de desconto");
    }
    if (recompensa === 11000) {
      setNomePremio("CONECTCAR: plano por R$6,50/mês + 30 reais de crédito");
    }
    if (recompensa === 12000) {
      setNomePremio(
        "RAPPI: CUPOM PARA 99% OFF no Plano Rappi Prime Basic + 10 reais para usar na loja Turbo"
      );
    }
    if (recompensa === 13000) {
      setNomePremio("Apple iPhone 15 Pro 128GB Titânio Preto 6,1 48MP iOS 5G");
    }
    if (recompensa === 14000) {
      setNomePremio(
        "Apple iPad 9ª Geração A13 Bionic 10,2” Wi-Fi 64GB - Cinza Espacial"
      );
    }
    if (recompensa === 15000) {
      setNomePremio(
        "Notebook Macbook Air 13,3” Apple M1 8GB - 256GB SSD Cinza-espacial"
      );
    }

    if (recompensa === 30000) {
      setNomePremio(
        "CONECTCAR: plano por R$6,50/mês + R$30,00 reais de crédito"
      );
    }

    if (recompensa === 31000) {
      setNomePremio(
        "RAPPI: CUPOM PARA 99% OFF no Plano Rappi Prime Basic + 10 reais para usar na loja Turbo"
      );
    }

    if (recompensa === 32000) {
      setNomePremio("Cinemark: 30% de desconto");
    }
  }
  async function fnMaisOpcoes() {
    if (ehVibe) {
      try {
        setLoad(true);
        const response = await ApiServices.apiGet(
          "roleta/mais-opcoes?codigo=" + recompensa
        );
        setMaisOpcoes(response.data);
      } catch (err) {
        console.error("err", err);
      } finally {
        setLoad(false);
      }
    }
  }
  async function fnPremioRoletaMaisOpcoes() {
    if (ehVibe) {
      try {
        setLoad(true);
        const response = await ApiServices.apiGet("roleta/recompensas");
        var premio = response.data.find((e) => e.codigo == recompensa);
        setMaisOpcoesRoleta(premio);
      } catch (err) {
        console.error("err", err);
      } finally {
        setLoad(false);
      }
    }
  }
  useEffect(() => {
    fnNomePremio();
    fnMaisOpcoes();
    fnPremioRoletaMaisOpcoes();
  }, []);
  function fnSalvaChave(valor) {
    storage.set("captcha", valor);
  }
  return (
    <>
      <GoogleReCaptcha onVerify={fnSalvaChave} />
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 "
          style={{ overflow: "auto" }}
        >
          <div className=" d-flex flex-column justify-content-between h-100 ">
            <HeaderAvatarMenu mb={25} />
            <div className=" d-flex flex-column align-items-center justify-content-between h-100 ">
              {ehVibe ? (
                <div className="d-flex flex-column align-items-center w-100">
                  <img
                    className="my-5"
                    width={"140px"}
                    src={presenteHome}
                    alt=""
                  />
                  <div className="cardRecompensas">
                    <img
                      className="imgRecompensa"
                      src={maisOpcoesRoleta.urlPremio}
                      alt=""
                    />
                    <div className="d-flex flex-column">
                      <div className="tituloRecompensas">
                        <b>{maisOpcoesRoleta.nome}</b>
                      </div>
                      <div className="tituloRecompensas">
                        <button
                          className="btn btn-primary btn-sm"
                          style={{ minHeight: 0 }}
                          onClick={() => {
                            navigate("/recompensas/sua-escolha", {
                              state: {
                                ...maisOpcoesRoleta,
                                recompensa: recompensa,
                                idHistorico: idHistorico,
                              },
                            });
                          }}
                        >
                          Resgatar
                        </button>
                      </div>
                    </div>
                  </div>
                  {maisOpcoes ? (
                    <b
                      className="my-4"
                      style={{ maxWidth: 300, textAlign: "center" }}
                    >
                      SUGESTÃO DE PRÊMIOS
                    </b>
                  ) : null}

                  {maisOpcoes?.map((e, i) => (
                    <div className="cardRecompensas" key={i}>
                      <img className="imgRecompensa" src={e.urlPremio} alt="" />
                      <div className="d-flex flex-column">
                        <div className="tituloRecompensas">
                          <b>{e.nome}</b>
                        </div>
                        <div className="tituloRecompensas">
                          <button
                            className="btn btn-primary btn-sm"
                            style={{ minHeight: 0 }}
                            onClick={() => {
                              navigate("/recompensas/sua-escolha", {
                                state: {
                                  ...e,
                                  recompensa: recompensa,
                                  idHistorico: idHistorico,
                                },
                              });
                            }}
                          >
                            Resgatar
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="d-flex flex-column align-items-center">
                  <h3> {recompensa === 0 ? "" : "Parabéns!"}</h3>
                  <div
                    style={{ maxWidth: 210, opacity: 0.8, textAlign: "center" }}
                  >
                    {recompensa === 0
                      ? "Você não foi contemplado na Roleta da sorte"
                      : "Você foi contemplado na Roleta da sorte e ganhou!"}
                  </div>

                  {recompensa === 0 ? (
                    <img className="my-5" width={"140px"} src={triste} alt="" />
                  ) : (
                    <img
                      className="my-5"
                      width={"140px"}
                      src={presenteHome}
                      alt=""
                    />
                  )}
                  <b
                    className="mb-4"
                    style={{ maxWidth: 300, textAlign: "center" }}
                  >
                    {nomePremio}
                  </b>
                  <div
                    style={{ maxWidth: 250, opacity: 0.8, textAlign: "center" }}
                  >
                    Continue participando e concorrendo a prêmios incríveis.
                  </div>
                </div>
              )}{" "}
              <div className="w-100 mt-3" style={{ paddingBottom: 80 }}>
                <button
                  className="btn btn-secondary w-100"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  Voltar para a tela inicial
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
