import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 10000
};

function getAnimationSettings(originXA, originXB) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 120,
    zIndex: 0,
    particleCount: 400,
    colors:['#4000ff','#6a00ff','#9900ff','#855cff','#ffffff','#00a6ff','#00f7ff','#3f86b9','#006195','#00eeff',],
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2
    }
  };
}

export default function Fireworks() {
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0, 1));
      // refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }
  }, []);

 
    useEffect(() => {
      
      if (!intervalId) {
        setIntervalId(setInterval(nextTickAnimation, 2000));
        setIntervalId(setInterval(nextTickAnimation, 3500));
        setIntervalId(setInterval(nextTickAnimation, 8800));
      }
     
    return () => {
      clearInterval(intervalId);
    };

  }, [intervalId]);

  return (
    <>
   
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </>
  );
}