import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router";
import bg from "../../assets/images/png/bg.png";
import onboarding1 from "../../assets/images/png/onboarding1.png";
import onboarding2 from "../../assets/images/png/onboarding2.png";
import onboarding3 from "../../assets/images/png/onboarding3.png";
import onboarding4 from "../../assets/images/png/onboarding4.png";
import onboarding5 from "../../assets/images/png/onboarding5.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
export default function Onboarding() {
  const navigate = useNavigate();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleNext = () => {
    if (currentSlideIndex < 4) {
      setCurrentSlideIndex(currentSlideIndex + 1);
    } else {
      navigate("/login"); // Redireciona para a rota desejada
    }
  };

  const handlePrev = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };
  return (
    <div className="imgBackground ">
      <div className="imgBackgroundInterno" style={{ overflow: "hidden" }}>
        <Swiper
          style={{ height: "100dvh" }}
          pagination={{
            clickable: true,
          }}
          navigation={{
            prevEl: ".arrow-right-card",
            nextEl: ".arrow-left-card",
          }}
          modules={[Pagination, Navigation]}
          className="slideOnboarding"
          onSlideChange={(swiper) => {
            setCurrentSlideIndex(swiper.realIndex);
          }}
        >
          <SwiperSlide>
            <div
              className="imagemMapa"
              style={{ backgroundImage: `url(${onboarding2})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="imagemMapa"
              style={{ backgroundImage: `url(${onboarding3})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="imagemMapa"
              style={{ backgroundImage: `url(${onboarding4})` }}
            ></div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="imagemMapa"
              style={{ backgroundImage: `url(${onboarding5})` }}
            ></div>
          </SwiperSlide>
          <div
            className="px-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "absolute",
              zIndex: 1000,
              bottom: 50,
              width: "100%",
              maxWidth: "500px",
            }}
          >
            <button
              className={
                "btn btn-secondary arrow-right-card " +
                (currentSlideIndex == 0 ? "invisible" : "visible")
              }
              style={{ width: "45%" }}
              onClick={handlePrev}
            >
              Voltar
            </button>

            <div className=" arrow-left-card" style={{ width: "45%" }}>
              <button
                className="btn btn-primary "
                style={{ width: "100%" }}
                onClick={handleNext}
              >
                {currentSlideIndex <= 3 ? "Avançar" : "Concluir"}
              </button>
            </div>
          </div>
        </Swiper>
      </div>
    </div>
  );
}
