import React, { useEffect, useState } from "react";
import Avatar from "../../Components/Avatar";
import estrelaNome from "../../assets/images/svg/estrelaNome.svg";
import roleta from "../../assets/images/png/roleta.png";
import numeroSorte from "../../assets/images/png/numeroSorte.png";
import presenteHome from "../../assets/images/png/presenteHome.png";
import bandeira from "../../assets/images/png/bandeira.png";
import ok from "../../assets/images/png/ok.png";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import { Link } from "react-router-dom";

import BarraProgressoMissaoDinamica from "../../Components/BarraProgressoMissaoDinamica";
import { toast } from "react-toastify";
export default function FooterHomeVendedor({ missao, fnProximaMissao }) {
  const [completouMissaoAtual, setCompletouMissaoAtual] = useState({});
  const [infoHeader, setInfoHeader] = useState();
  const [missoes, setMissoes] = useState({});
  const [travaProxima, setTravaProxima] = useState(false);

  const { setLoad } = useLoad();

  async function fnMissoes() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("missao/meu-resumo");
      setMissoes(response.data);
      var atual = null;
      var encontrouSequencia = false;

      for (let i = response.data.missoes.length - 1; i >= 0; i--) {
        if (response.data.missoes[i].atual) {
          if (!encontrouSequencia) {
            // Inicia uma nova sequência de 'true'
            atual = response.data.missoes[i];
            encontrouSequencia = true;
          }
        } else {
          if (encontrouSequencia) {
            // Se encontrou uma sequência de 'true' seguida de 'false', pare
            break;
          }
        }
      }
      setInfoHeader(atual);
      setCompletouMissaoAtual(atual?.podeCompletar);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnMissoes();
  }, []);
  return (
    <div
      className=" px-3"
      style={{
        position: "absolute",
        zIndex: 100,
        bottom: 80,
        width: "100%",
        maxWidth: 500,
      }}
    >
      <div className="d-flex justify-content-between">
        {/* MISSAO */}
        <Link
          to={"/missoes"}
          className="bgAzulMissao d-flex align-items-center"
        >
          <div className="divHomeMissao">
            <img className="bandeiraMissao" width={70} src={bandeira} alt="" />
          </div>
          <div style={{ width: "100%" }}>
            <div className="d-flex">
              <div className="nomeCardPerfil fw-light" style={{ fontSize: 18 }}>
                Missão 
              </div>
              <div className="nomeCardPerfil" style={{ fontSize: 18 }}>
                {missao.numero}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className=" pe-2">
                {missao.qtdCadastroMeta > 0 && (
                  <div
                    className="filialCardPerfil fw-bolder"
                    style={{ margin: "2px 0", color: "#fff" }}
                  >
                    Cadastro
                  </div>
                )}
                {missao.qtdQuizMeta > 0 && (
                  <div
                    className="filialCardPerfil fw-bolder"
                    style={{ margin: "2px 0", color: "#fff" }}
                  >
                    Quiz
                  </div>
                )}
                {missao.qtdEnqueteMeta > 0 && (
                  <div
                    className="filialCardPerfil fw-bolder"
                    style={{ margin: "2px 0", color: "#fff" }}
                  >
                    Enquete
                  </div>
                )}
                <div
                  className="filialCardPerfil fw-bolder"
                  style={{ margin: "2px 0", color: "#fff" }}
                >
                  Vendas
                </div>
              </div>
              <div className=" d-flex flex-column justify-content-around pe-2 w-100">
                {missao.qtdCadastroMeta > 0 && (
                  <BarraProgressoMissaoDinamica
                    meta={missao.qtdCadastroMeta}
                    progresso={missao.qtdCadastroFeito}
                  />
                )}
                {missao.qtdQuizMeta > 0 && (
                  <BarraProgressoMissaoDinamica
                    meta={missao.qtdQuizMeta}
                    progresso={missao.qtdQuizFeito}
                  />
                )}

                {missao.qtdEnqueteMeta > 0 && (
                  <BarraProgressoMissaoDinamica
                    meta={missao.qtdEnqueteMeta}
                    progresso={missao.qtdEnqueteFeito}
                  />
                )}
                {missao.qtdVendasMeta > 0 && (
                  <BarraProgressoMissaoDinamica
                    meta={missao.qtdVendasMeta}
                    progresso={missao.qtdVendasFeito}
                  />
                )}
              </div>

              <div className=" ps-2">
                {missao.qtdCadastroMeta > 0 && (
                  <div
                    className="filialCardPerfil fw-bolder"
                    style={{ margin: "2px 0", color: "#fff" }}
                  >
                    {missao.qtdCadastroFeito}/{missao.qtdCadastroMeta}
                  </div>
                )}
                <div
                  className="filialCardPerfil fw-bolder"
                  style={{ margin: "2px 0", color: "#fff" }}
                >
                  {missao.qtdQuizFeito}/{missao.qtdQuizMeta}
                </div>
                {missao.qtdEnqueteMeta > 0 && (
                  <div
                    className="filialCardPerfil fw-bolder"
                    style={{ margin: "2px 0", color: "#fff" }}
                  >
                    {missao.qtdEnqueteFeito}/{missao.qtdEnqueteMeta}
                  </div>
                )}
                <div
                  className="filialCardPerfil fw-bolder"
                  style={{ margin: "2px 0", color: "#fff" }}
                >
                  {missao.qtdVendasFeito}/{missao.qtdVendasMeta}
                </div>
              </div>
            </div>
          </div>
        </Link>
        {/* CONCLUIDO */}

        {missao?.podeCompletar ? (
          // IR PARA PROXIMA MISSAO
          <div
            className="ms-1 pointer"
            style={{
              width: "23%",
            }}
            onClick={() => {
              if (
                completouMissaoAtual &&
                missoes?.qtdRoletaTotal === missoes?.qtdRoletaGirada &&
                travaProxima === false
              ) {
                setTravaProxima(true);
                fnProximaMissao();
              } else {
                travaProxima === false &&
                  toast.warn("Você precisa girar a roleta");
              }
            }}
          >
            <div className="position-relative h-100 m-0">
              <div className="bgAzulMissaoConcluido d-flex align-items-center w-100 h-100 ">
                {travaProxima ? (
                  <div className="w-100">
                    <div className="text-center" style={{ fontSize: 12 }}>
                       
                    </div>
                    <div className="text-center" style={{ fontSize: 12 }}>
                      • • •
                    </div>
                    <div className="text-center" style={{ fontSize: 12 }}>
                       
                    </div>
                  </div>
                ) : (
                  <div className="w-100">
                    <div className="text-center" style={{ fontSize: 12 }}>
                      IR PARA
                    </div>
                    <div className="text-center" style={{ fontSize: 12 }}>
                      PRÓXIMA
                    </div>
                    <div className="text-center" style={{ fontSize: 12 }}>
                      MISSÃO
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          //
          <div
            className="d-flex flex-column justify-content-between"
            style={{
              width: "20%",
            }}
          >
            {/* CADASTRO */}
            {missao.qtdCadastroMeta > 0 && (
              <div className="position-relative">
                <Link
                  to="/meu-cadastro"
                  className={
                    "bgAzulMissaoConcluido d-flex justify-content-center align-items-center " +
                    (missao.qtdCadastroFeito >= missao.qtdCadastroMeta &&
                      "okOpacidade")
                  }
                >
                  <div
                    className="filialCardPerfil"
                    style={{ margin: "2px 0", color: "#fff", padding: 3 }}
                  >
                    Cadastro
                  </div>
                </Link>
                {missao.qtdCadastroFeito >= missao.qtdCadastroMeta &&
                  missao.qtdCadastroMeta > 0 && (
                    <img className="okCompletos" src={ok} alt="" />
                  )}
              </div>
            )}
            {/* QUIZ */}
            {missao.qtdQuizMeta > 0 && (
              <div className="position-relative">
                <Link
                  to="/quiz"
                  className={
                    "bgAzulMissaoConcluido d-flex justify-content-center align-items-center " +
                    (missao.qtdQuizFeito >= missao.qtdQuizMeta && "okOpacidade")
                  }
                >
                  <div
                    className="filialCardPerfil "
                    style={{ margin: "2px 0", color: "#fff", padding: 3 }}
                  >
                    Quiz
                  </div>
                </Link>
                {missao.qtdQuizFeito >= missao.qtdQuizMeta &&
                  missao.qtdQuizMeta > 0 && (
                    <img className="okCompletos" src={ok} alt="" />
                  )}
              </div>
            )}
            {/* ENQUETE */}
            {missao.qtdEnqueteMeta > 0 && (
              <div className="position-relative">
                <Link
                  to="/enquete"
                  className={
                    "bgAzulMissaoConcluido d-flex justify-content-center align-items-center " +
                    (missao.qtdEnqueteFeito >= missao.qtdEnqueteMeta &&
                      "okOpacidade")
                  }
                >
                  <div
                    className="filialCardPerfil "
                    style={{ margin: "2px 0", color: "#fff", padding: 3 }}
                  >
                    Enquete
                  </div>
                </Link>
                {missao.qtdEnqueteFeito >= missao.qtdEnqueteMeta &&
                  missao.qtdEnqueteMeta > 0 && (
                    <img className="okCompletos" src={ok} alt="" />
                  )}
              </div>
            )}
            {/* VENDAS */}
            {missao.qtdVendasMeta > 0 && (
              <div className="position-relative">
                <Link
                  to="/vendas"
                  className={
                    "bgAzulMissaoConcluido d-flex justify-content-center align-items-center " +
                    (missao.qtdVendasFeito >= missao.qtdVendasMeta &&
                      "okOpacidade")
                  }
                >
                  <div
                    className="filialCardPerfil "
                    style={{ margin: "2px 0", color: "#fff", padding: 3 }}
                  >
                    Venda
                  </div>
                </Link>
                {missao.qtdVendasFeito >= missao.qtdVendasMeta &&
                  missao.qtdVendasMeta > 0 && (
                    <img className="okCompletos" src={ok} alt="" />
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
