import React from "react";
import "./index.css";
import seta from "../../assets/images/png/setaMetaRoleta.png";
import { useEffect } from "react";
export default function BarraProgressoMissaoDinamica({
  meta = 0,
  progresso = 0,
}) {
  const array = new Array(meta).fill(0);

  return (
    <div
      className="d-flex justify-content-between align-items-center mx-2 w-100"
      style={{ minHeight: 15 }}
    >
      {array.map((e, i) => (
        <div
          key={i}
          style={{ margin: "0 2px " }}
          className={progresso > i ? "barCheioDinamico" : "barVazioDinamico"}
        ></div>
      ))}
    </div>
  );
}
