import React, { useEffect, useState } from "react";
import "./index.css";
import { useLoad } from "../../context/Load";

import vendas from "../../assets/images/png/vendas.png";
import ok from "../../assets/images/png/ok.png";
import atual from "../../assets/images/png/atual.png";
import bloqueado from "../../assets/images/png/bloqueado.png";
import HeaderAvatarMenu from "../../Components/HeaderAvatarMenu";
import voltar from "../../assets/images/png/voltar.png";
import { Link } from "react-router-dom";
import ApiServices from "../../services/apiServices";
import moment from "moment";
import storage from "../../services/storage";
export default function HistoricoVendas() {
  const { load, setLoad } = useLoad();
  const [listaChassi, setListaChassi] = useState([]);
  const [mesSelecionado, setMesSelecionado] = useState(moment().format("M"));
  const [meses, setMeses] = useState([
    { mesId: 1, mesNome: "Janeiro" },
    { mesId: 2, mesNome: "Fevereiro" },
    { mesId: 3, mesNome: "Março" },
    { mesId: 4, mesNome: "Abril" },
    { mesId: 5, mesNome: "Maio" },
    { mesId: 6, mesNome: "Junho" },
    { mesId: 7, mesNome: "Julho" },
    { mesId: 8, mesNome: "Agosto" },
    { mesId: 9, mesNome: "Setembro" },
    { mesId: 10, mesNome: "Outubro" },
    { mesId: 11, mesNome: "Novembro" },
    { mesId: 12, mesNome: "Dezembro" },
  ]);

  const vendedor =
    storage.get("USUARIO")?.profile?.role?.roleId ==
    "BD599436-282A-4B76-B419-4D767551DBD6";

  async function fnListaChassiVendedor() {
    const parametros = {
      ano: 2024,
      mes: mesSelecionado,
    };
    try {
      const queryString = new URLSearchParams(parametros).toString();
      setLoad(true);
      const response = await ApiServices.apiGet("vendas?" + queryString);
      setListaChassi(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListaChassiGerente() {
    try {
      setLoad(true);
      const response = await ApiServices.apiGet("vendas/gerente");
      setListaChassi(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    vendedor ? fnListaChassiVendedor() : fnListaChassiGerente();
  }, [mesSelecionado]);

  return (
    <>
      <div className="imgBackground">
        <div
          className="imgBackgroundInterno px-3 h-100"
          style={{ overflow: "auto" }}
        >
          <HeaderAvatarMenu titulo={"Histórico de Vendas"} imagem={vendas} />
          <div className="d-flex flex-column mb-5">
            <label className="m-0">Mês</label>
            <select
              className="w-100"
              value={mesSelecionado}
              onChange={(e) => {
                setMesSelecionado(e.target.value);
              }}
              style={{ width: 150 }}
            >
              <option value={""}>Selecione</option>
              {meses?.map((mes, i) => (
                <option key={i} value={mes.mesId}>
                  {mes.mesNome}
                </option>
              ))}
            </select>
          </div>
          <div style={{ paddingBottom: 80 }}>
            {listaChassi?.length > 0 ? (
              listaChassi?.map((e, i) => (
                <div key={i}>
                  <div className="dataHistoricoVendas">
                    {moment(e.dataVenda).format("DD/MM/YYYY")}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="nomeHistoricoVendas tresPontinhos me-3">
                      Chassi: {e.chassi}
                    </div>
                  </div>
                  {!vendedor && (
                    <>
                      <div className="  tresPontinhos me-3">{e?.ccy}</div>
                      <div className="  tresPontinhos me-3">{e?.cliente}</div>
                    </>
                  )}
                  <hr />
                </div>
              ))
            ) : (
              <div className="d-flex justify-content-center">
                <div style={{ maxWidth: 300, textAlign: "center" }}>
                  Sem histórico de vendas
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
